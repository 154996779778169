// framework imports - 1st party
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// lib imports - 3rd party
import moment from "moment";

// app imports
import * as api from "../api";
import "../stylesheets/Footer.css";

const Footer = () => {
  const [version, setVersion] = useState("");
  const [commitTime, setCommitTime] = useState("");
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState("");
  const [width, setWidth] = useState("500px");
  let location = useLocation();

  useEffect(() => {
    api.getBuild().then((x) => {
      setVersion(x.build.version);
      setCommitTime(x.git.commit.time);
      setPrivacyPolicyUrl(x.allpaidPrivacyPolicyUrl);
    });
  }, []);

  useEffect(() => {
    if (
      location.pathname.includes("login") ||
      location.pathname.includes("changepasswordpage")
    ) {
      setWidth("500px");
    } else {
      setWidth("1200px");
    }
  }, [location]);

  function changePasswordPage() {
    return window.location.pathname === "/changepasswordpage";
  }

  // Only load if logged in
  // also, make width smaller if not logged in i.e. on login page
  return (
    <footer className="footer" style={{ width: width }}>
      <div className="float-right">
        Email AllPaid:
        <br />
        <a href="mailto:servicerequests@allpaid.com">
          servicerequests@allpaid.com
        </a>
        <br />
        Proview2 Version: {version}
        <br />
        Last Commit: {moment(commitTime).format("M/DD/YYYY H:MM A")}
      </div>
      <div className="float-left">
        Customer Service:
        <br />
        866-564-0169
        <br />
        24/7 through the call center
        <br />
        <a href={privacyPolicyUrl}>Privacy Policy</a>
      </div>
      <div className="center-footer">
        © 2007 - {moment().year()} Al AllPaid, Inc. All Rights Reserved. (NMLS
        ID #1434636; Licensed by the Georgia Department of Banking and Finance
        #61205)
      </div>
    </footer>
  );
};

export default Footer;
