import withRouter from "../HelperComponents/WithRouter";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "../../stylesheets/Row.css";
import "../../stylesheets/GenerateReport.css";
import * as Auth from "../../authService";
import { ReactComponent as CancelRP } from "../../icons/approve-off.svg";
import { ReactComponent as UserInfo } from "../../icons/note-off.svg";
import { ReactComponent as ListTransaction } from "../../icons/send-off.svg";
import UserProfile from "../HelperComponents/UserProfile";
import Confirm from "../HelperComponents/Confirm";
import * as api from "../../api";
import * as notify from "../../notify";

const RecurringRow = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalCancelConfirm, setShowModalCancelConfirm] = useState(false);
  const [submit, onSubmit] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleOpenModalCancelConfirm = () => {
    if (props.transaction.status === "ACTIVE") {
      setShowModalCancelConfirm(true);
    }
  };

  const showTransactionList = () => {
    if (!props.loading) {
      props.navigate(
        "/recurring/" + props.transaction.recurringPaymentId + "/transaction",
        {
          state: {
            transactionId: props.transaction.transactionId,
            prevScreen: "/recurring",
          },
        }
      );
    }
  };

  const loadTransaction = () => {
    if (!props.loading) {
      props.navigate("/recurring/" + props.transaction.recurringPaymentId, {
        state: {
          recurringPaymentId: props.transaction.recurringPaymentId,
          transactionId: props.transaction.transactionId,
          prevScreen: "/recurring",
        },
      });
    }
  };

  useEffect(() => {
    if (submit) {
      api
        .cancelRecurringPayment(props.transaction.recurringPaymentId)
        .then((x) => {
          setShowModalCancelConfirm(false);
          if (x.status === 200 || x.status === 201) {
            notify.success(
              "Cancelled RP " + props.transaction.recurringPaymentId
            );
            props.retrieveInfo();
          } else {
            notify.error(
              "Failed to cancel RP " + props.transaction.recurringPaymentId
            );
          }
        });
    }
  }, [submit]);

  const calculateNextPaymentDate = (status) => {
    if (!props.transaction.lastPaymentDate) {
      return null;
    }

    if (status !== "CANCELLED") {
      let nextPaymentDate = new Date(props.transaction.lastPaymentDate);
      nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1);
      return moment(nextPaymentDate).format("MM/DD/YYYY");
    }
    return "-";
  };

  const lowercase = (s) => {
    if (typeof s !== "string") return "";
    return s
      .split(" ")
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase() + " ");
  };

  const renderIntervalDate = (intervalDate) => {
    const arrDate = ["11", "12", "13"];
    if (arrDate.includes(intervalDate)) {
      return intervalDate + "th";
    }

    let lastCharacter = intervalDate.slice(-1);
    switch (lastCharacter) {
      case "1":
        return intervalDate + "st";
      case "2":
        return intervalDate + "nd";
      case "3":
        return intervalDate + "rd";
      default:
        return intervalDate + "th";
    }
  };

  return (
    <tr className="row" name={props.referenceId}>
      <td className="transaction-id-row" onClick={loadTransaction}>
        {props.transaction.recurringPaymentId}
      </td>
      <td className="text-right" onClick={loadTransaction}>
        {props.transaction.plcId}
      </td>
      <td className="transaction-id-row" onClick={loadTransaction}>
        <button className="transaction-id-button">
          {props.transaction.transactionId}
        </button>
      </td>
      <td className="text-right" onClick={loadTransaction}>
        {props.transaction.setupDate
          ? moment(props.transaction.setupDate).format("M/DD/YYYY hh:mm A")
          : null}
      </td>
      <td className="text-right" onClick={loadTransaction}>
        {renderIntervalDate(props.transaction.intervalDate)}
      </td>
      <td className="payment-row text-right" onClick={loadTransaction}>
        {props.transaction.payment ? props.transaction.payment : "$0.00"}
      </td>
      <td onClick={loadTransaction}>{lowercase(props.transaction.status)}</td>
      <td className="text-right" onClick={loadTransaction}>
        {props.transaction.lastPaymentDate
          ? moment(props.transaction.lastPaymentDate).format(
              "M/DD/YYYY hh:mm A"
            )
          : null}
      </td>
      <td
        className={"text-right " + props.transaction.status}
        onClick={loadTransaction}
      >
        {calculateNextPaymentDate(props.transaction.status)}
      </td>
      <td className="hide-mobile">
        {Auth.canFinanceOverride() ? (
          <div className="row-button">
            <CancelRP
              className="row-icon"
              onClick={handleOpenModalCancelConfirm}
            />
            <div className="row-description">Cancel RP</div>
          </div>
        ) : null}

        <div className="row-button" onClick={handleOpenModal}>
          <UserInfo className="row-icon" />
          <div className="row-description">User Info</div>
        </div>

        <div className="row-button">
          <ListTransaction className="row-icon" onClick={showTransactionList} />
          <div className="row-description">Transactions</div>
        </div>
      </td>
      <UserProfile
        showModal={showModal}
        userId={props.transaction.userId}
        setShowModal={(e) => setShowModal(e)}
      />
      <Confirm
        showModal={showModalCancelConfirm}
        setShowModal={(e) => setShowModalCancelConfirm(e)}
        onSubmit={(e) => onSubmit(e)}
      />
    </tr>
  );
};

export default withRouter(RecurringRow);
