// framework imports - 1st party
import React, { useState, useEffect } from "react";

// lib imports - 3rd party
import "airbnb-js-shims"; // needed for IE11 support
import { ToastContainer, Slide } from "react-toastify";

// app imports
import SummaryTable from "./Summary/SummaryTable";
import Spinner from "./HelperComponents/Spinner";
import * as Auth from "../authService";
import * as notify from "../notify";
import * as api from "../api";

import mainLogo from "../icons/summary.png";
import "../stylesheets/spinner.css";
import "../stylesheets/PageBody.css";
import withRouter from "./HelperComponents/WithRouter";

//The Transaction Summary page
const TransactionSummary = (props) => {
  //Fields to make the call to update Notify table
  let transId = "";
  let type = "";
  let destination = "";
  let attention = "";
  let transactionIdsList = "";

  const [loading, setLoading] = useState(false);
  const [rowsChecked, setRowsChecked] = useState([]);
  const [canApprove, setCanApprove] = useState(false);
  const [topChecked, setTopChecked] = useState(false); // whether top checkmark is checked
  const [approved, setApproved] = useState(false);
  // check if state
  // has parameters
  // that require notification to show up top
  useEffect(() => {
    notify.check(props.location.state);
    props.navigate(".", { state: {} });
  }, []);

  //Get NotifyInfo for all the transactions that were successfully approved
  const getTransactionsNotifyInfo = () => {
    api.getNotifyInfoListTransactions(transactionIdsList).then((x) => {
      // successful
      if (x.status === 200 || x.status === 201) {
        //Loop thru each transactio Id to get Notify Type Info
        for (let i = 0; i < x.data.length; i++) {
          resetSendNotificationValues();
          //Loop thru every Notify Type Set
          for (let j = 0; j < x.data[i].notifyType.length; j++) {
            //Set fields to log wire transaction in notify table
            if (
              x.data[i].notifyType.length === 3 &&
              x.data[i].notifyType[j].value === "wir"
            ) {
              transId = x.data[i].transactionId;
              type = x.data[i].notifyType[j].value;
              destination = x.data[i].notifyType[j].notifyDest;
              attention = x.data[i].notifyType[j].notifyAttn;
            }
            //Make the call to Log transaction in Notify table
            if (type !== null && type === "wir") {
              handleWireNotify();
            }
          }
        }
      } else {
        notify.error("Error in Retreiving Notify Information Service");
      }
    });
  };
  //Log approved wire transactions into Notify table
  const handleWireNotify = () => {
    api.sendNotification(transId, type, destination, attention).then((x) => {
      if (x.status === 200 || x.status === 201) {
        resetSendNotificationValues();
      } else {
        notify.error("Sending Notification Failed for reference #" + transId);
        resetSendNotificationValues();
      }
    });
  };
  //Resetting values for next Notification Call to Notify table
  const resetSendNotificationValues = () => {
    console.log("Resettng values");
    transId = "";
    type = "";
    destination = "";
    attention = "";
  };
  //Approve all checked rows
  const handleApprove = () => {
    api.approveTransactions(rowsChecked).then((x) => {
      // successful
      if (x.status === 200 || x.status === 201) {
        setApproved(true);
        // remove top row check
        setTopChecked(false);
        // customize display message based on count of rows checked
        rowsChecked.length > 1
          ? notify.success("Approved " + rowsChecked.length + " transactions")
          : notify.success("Approved transaction " + rowsChecked[0]);
        console.log("Getting Notify Info and Loggin wire transactions");
        //Setting Transaction list to get list of NotifyInfo
        transactionIdsList = rowsChecked;
        //Call to get NotifyInfo of all approved transactions.
        getTransactionsNotifyInfo();
        // remove checked rows
        setRowsChecked([]);
        // disable approve button
        setCanApprove(false);
        // force ui render
        setTimeout(() => {
          setApproved(false);
        }, 3000);
      } else {
        notify.error("Failed to approve transaction(s)");
      }
    });
  };

  // if a row is checked, this function gets called
  const handleRowsChecked = (e) => {
    // Approve button enabled if at least one row checked
    setCanApprove(e.length > 0);
    // set correct rows to be checked
    setRowsChecked(e);
  };

  return (
    <main className="main-background">
      <div className="main-component">
        <div id="table-header">
          <div id="details-label">
            <img alt="Govpay Logo" src={mainLogo} id="trans-sum-icon" />
            <div id="details-label-name">Transaction Summary</div>
          </div>
          {Auth.canAdmin() ||
          Auth.canFinanceOverride() ||
          Auth.canAmountModify() ||
          Auth.canModify() ? (
            <button
              className="proview-button right margins hide-mobile"
              disabled={!canApprove}
              onClick={handleApprove}
            >
              Approve
            </button>
          ) : null}
          {/* Spinner shown only when table is loading */}
          <Spinner customClass={"sk-fading-circle summary"} loading={loading} />
        </div>
        {/* All table information in Table component */}
        <SummaryTable
          setSpinner={(e) => setLoading(e)}
          loading={loading}
          handleRowsChecked={(e) => handleRowsChecked(e)}
          rowsChecked={rowsChecked}
          setTopChecked={(e) => setTopChecked(e)}
          topChecked={topChecked}
          approved={approved}
        />
      </div>
      <ToastContainer transition={Slide} />
    </main>
  );
};

export default withRouter(TransactionSummary);
