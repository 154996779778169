import { useState, useEffect, useCallback, useRef, Fragment } from "react";
import moment from "moment";
import * as Auth from "../authService";

const SessionTimeout = () => {
  const [events] = useState(["click", "load", "scroll"]);

  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();
  let isAuthenticated = Auth.loggedIn();

  // start inactive check
  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
      warningInactive(storedTimeStamp);
    }, 10000);
  };

  // warning timer
  let warningInactive = (timeString) => {
    let notifyLogout = false;

    clearTimeout(startTimerInterval.current);
    warningInactiveInterval.current = setInterval(() => {
      const maxTime = parseInt(Auth.getIdleTime()); // Maximum ideal time given before logout
      const popTime = 1; // remaining time (notification) left to logout.

      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes();

      if (minPast === popTime && !notifyLogout) {
        console.warn("One minute before logout!");
        notifyLogout = true;
      }

      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        sessionStorage.removeItem("lastTimeStamp");
        Auth.logout().then(window.location.reload());
      }
    }, 1000);
  };

  // reset interval timer
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (isAuthenticated) {
      timeStamp = moment().toISOString();
      sessionStorage.setItem("lastTimeStamp", timeStamp);
      timeChecker();
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem("lastTimeStamp");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });
    timeChecker();
    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, events, timeChecker]);

  return Fragment;
};

export default SessionTimeout;
