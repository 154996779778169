// framework imports - 1st party
import React, { useEffect, useState, useRef } from "react";

// lib imports - 3rd party
import cloneDeep from "lodash/cloneDeep";
import "react-toastify/dist/ReactToastify.css";

// app imports
import QbSyncInformation from "./QbSyncInformation";
import NotificationInformation from "./NotificationInformation";
import EditIcon from "./EditIcon";
import * as notify from "../../notify";
import * as api from "../../api";
import * as Auth from "../../authService";

//Boolean to be set if transaction is AllPaid token payment
let isWallet = false;

// to autogenerate rows, convert their key to the label, i.e. "zip_code" -> "Zip Code"
const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1) + " ");
};

// dynamically generate the billing information rows.
// includes logic to edit, disable, and style inputs
const createRows = (object, setObject, isDisabled) => {
  const keys = [
    "first",
    "middle",
    "last",
    "address1",
    "address2",
    "city",
    "state",
    "zip_code",
    "phone",
  ];

  return keys.map((row, i) => (
    <tr key={i}>
      <td>
        <label htmlFor={"billInfo" + row}>{capitalize(row)}</label>
      </td>
      <td>
        {!isDisabled &&
        (row === "first" ||
          row === "middle" ||
          row === "last" ||
          row === "address1" ||
          row === "address2" ||
          row === "city" ||
          row === "state" ||
          row === "zip_code" ||
          row === "phone") ? (
          <input
            id={"billInfo" + row}
            className={"change-information"}
            value={object[row]}
            onChange={(e) => setObject({ ...object, [row]: e.target.value })}
            disabled={isDisabled}
            name={"billInfo" + row}
            style={{
              width: object[row] ? object[row].length * 10 + "px" : "10px",
            }} // grow input for more text
            type="text"
          />
        ) : (
          <strong name="disabledBilling">{object[row]}</strong>
        )}
      </td>
    </tr>
  ));
};

// Generate auth details tables (may be more than 1)
const getAuthDetails = (e) => {
  return e.map((auth_table, i) => (
    <table className="inline" key={i}>
      <tbody>
        {auth_table.textDetails.split(", ").map((auth_rows, j) => (
          <tr key={j}>
            <td>{auth_rows.trim()}</td>
          </tr>
        ))}
        {auth_table.paymentCapture != null &&
          auth_table.paymentCapture.length > 0 && (
            <tr>
              <td>
                <div className="captureMessage">
                  <div className="title">Capture Messages: </div>
                  <div className="messages">
                    {auth_table.paymentCapture.map((capture, j) => (
                      <div key={j}>
                        {j + 1}. {capture.paymentCaptureMessage}
                      </div>
                    ))}
                  </div>
                </div>
              </td>
            </tr>
          )}
      </tbody>
    </table>
  ));
};

const getAuthDetails1 = (e) => {
  e = e.filter((table) => table.paymentChargeBackInfo !== "");
  if (e.length === 0) {
    return null;
  } else {
    return e.map((auth_table, i) => (
      <table className="inline" key={i}>
        <tbody>
          {auth_table.paymentChargeBackInfo.split(", ").map((auth_rows, j) => (
            <tr key={j}>
              <td>{auth_rows.trim()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ));
  }
};
const getCreditDetails = (e) => {
  e = e.filter((table) => table.creditInfo !== "");
  if (e.length === 0) {
    return null;
  } else {
    return e.map((auth_table, i) => (
      <table className="inline" key={i}>
        <tbody>
          {auth_table.creditInfo.split(", ").map((auth_rows, j) => (
            <tr key={j}>
              <td>{auth_rows.trim()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ));
  }
};

// given payload, determine format of JSON
const getBillingType = (e) => {
  if (e) {
    switch (e.paymentType) {
      case "CREDIT_CARD":
        return "creditCardInfo";
      case "TOKEN":
        return "creditCardInfo";
      case "PAYNEARME":
        return "payNearMeInfo";
      case "GREENDOT":
        return "greenDotInfo";
      case "ECHECK":
        return "echeckInfo";
      case "EMV_CREDIT":
        return "creditCardInfo";
      case "EMV_DEBIT":
        return "creditCardInfo";
      default:
        return "ERROR";
    }
  }
};

//given payload, determine format of JSON
const getWalletPaymentType = (e) => {
  if (e) {
    switch (e.paymentEntryType) {
      case "CREDIT_TOKEN":
        return true;
      case "DEBIT_TOKEN":
        return true;
      default:
        return false;
    }
  }
};

const BillingInformation = (props) => {
  const node = useRef();
  const [billingAll, setBillingAll] = useState();
  const [billingDisplay, setBillingDisplay] = useState({
    first: "",
    middle: "",
    last: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip_code: "",
    phone: "",
  });
  const [paymentInfo, setPaymentInfo] = useState({ label: "", info: "" });
  const [billingDisabled, setBillingDisabled] = useState(true);
  const [authorization, setAuthorization] = useState(false);
  const [chargeBack, setChargeBack] = useState(false);
  const [authorizationInfo, setAuthorizationInfo] = useState();
  const [chargeBackInfo, setChargeBackInfo] = useState();
  const [credit, setCredit] = useState(false);
  const [creditInfo, setCreditInfo] = useState([]);
  const [showModalQbSyncInformation, setShowModalQbSyncInformation] =
    useState(false);
  const [
    showModalNotificationInformation,
    setShowModalNotificationInformation,
  ] = useState(false);

  const handleOpenModalQbSyncInformation = () => {
    setShowModalQbSyncInformation(true);
  };

  const handleOpenModalNotificationInformation = () => {
    setShowModalNotificationInformation(true);
  };

  // initialize values
  const determineDisplay = (e) => {
    const type = getBillingType(e);
    isWallet = getWalletPaymentType(e);
    setBillingDisplay({
      first: e[type].firstName ? e[type].firstName : "",
      middle: e[type].middleName ? e[type].middleName : "",
      last: e[type].lastName ? e[type].lastName : "",
      address1: e[type].address.address1 ? e[type].address.address1 : "",
      address2: e[type].address.address2 ? e[type].address.address2 : "",
      city: e[type].address.city ? e[type].address.city : "",
      state: e[type].address.state ? e[type].address.state : "",
      zip_code: e[type].address.zip ? e[type].address.zip : "",
      phone: e[type].phone
        ? e[type].phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
        : "",
    });

    // depending on type, show last row differently
    if (isWallet === true && type === "creditCardInfo") {
      setPaymentInfo({
        label: "Wallet Payment #",
        info: "xxxx-xxxx-xxxx-" + e.creditCardInfo.lastFour,
      });
    } else if (type === "creditCardInfo") {
      setPaymentInfo({
        label: "Credit Card #",
        info: "xxxx-xxxx-xxxx-" + e.creditCardInfo.lastFour,
      });
    } else if (type === "payNearMeInfo" || type === "greenDotInfo") {
      setPaymentInfo({
        label: "Cash Payment",
        info: "",
      });
    } else if (type === "echeckInfo") {
      setPaymentInfo({
        label: "Echeck Acc #",
        info: "xxxxx" + e.echeckInfo.echeckAccountLastFour,
      });
    }
  };

  // on submit, create a new payload
  // with current inputs added to original payload
  const handleSubmit = () => {
    if (billingAll) {
      // must do deep copy to actually change values
      const billingUpdate = cloneDeep(billingAll);
      const type = getBillingType(billingUpdate);
      billingUpdate[type].firstName = billingDisplay.first;
      billingUpdate[type].firstName = billingDisplay.first;
      billingUpdate[type].middleName = billingDisplay.middle;
      billingUpdate[type].lastName = billingDisplay.last;
      billingUpdate[type].address.address1 = billingDisplay.address1;
      billingUpdate[type].address.address2 = billingDisplay.address2;
      billingUpdate[type].address.city = billingDisplay.city;
      billingUpdate[type].address.state = billingDisplay.state;
      billingUpdate[type].address.zip = billingDisplay.zip_code;
      billingUpdate[type].phone = billingDisplay.phone;

      api
        .transactionUpdate(
          props.referenceId,
          "paymentAuthInfos",
          billingUpdate,
          billingUpdate.paymentAuthId
        )
        .then((x) => {
          // on success, notify update
          if (x.status === 200) {
            setBillingAll(x.data);
            determineDisplay(x.data);
            notify.success("Billing Information updated");
          } else {
            notify.error("Billing information failed to update");
            retrieveInfo();
          }
        });
    } else {
      notify.error("Billing information failed to update");
    }
  };

  // Stores payload in state and determines display and auth display
  const retrieveInfo = () => {
    api.getTransaction(props.referenceId, "paymentAuthInfos").then((x) => {
      if (x && x.length !== 0) {
        setAuthorizationInfo(getAuthDetails(x));
        setChargeBackInfo(getAuthDetails1(x));
        // currently, set Billing Info
        // based on values in FIRST Auth Details
        const firstPaymentAuth = x[0];
        setBillingAll(firstPaymentAuth);
        determineDisplay(firstPaymentAuth);
      }
    });
  };

  const retrieveCreditInfo = () => {
    api.getTransaction(props.referenceId, "paymentCreditInfos").then((x) => {
      if (x && x.length !== 0) {
        setCreditInfo(getCreditDetails(x));
      }
    });
  };

  // handles closing out auth if you click outside of it
  const handleAuth = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setAuthorization(false);
    setChargeBack(false);
    setCredit(false);
  };

  // if on small screen, don't allow editing
  const handleResize = (e) => {
    if (window.innerWidth < 600) {
      setBillingDisabled(true);
    }
  };

  // listens for resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // listens for mousedown
  // for Authorization Details click
  useEffect(() => {
    document.addEventListener("mousedown", handleAuth);
    retrieveInfo();
    retrieveCreditInfo();
    return () => {
      document.removeEventListener("mousedown", handleAuth);
    };
  }, []);

  // toggles edit icon
  const billingEdit = (e) => {
    e.preventDefault();
    if (!billingDisabled) {
      handleSubmit();
    }
    setBillingDisabled(!billingDisabled);
  };

  // on cancel, notify cancel and reload billing info
  const handleCancel = (e) => {
    notify.cancel("Billing Information update cancelled");
    retrieveInfo();
    setBillingDisabled(true);
  };

  return (
    <table className="description-table">
      <caption>
        <div className="table-title">BILLING INFORMATION</div>{" "}
        {isWallet === true && <div>(WALLET)</div>}
        {props.notEditable ? null : (
          <EditIcon
            name={"billingEdit"}
            canEdit={
              Auth.canFinanceOverride() ||
              Auth.canAdmin() ||
              Auth.canAmountModify()
            }
            disabled={billingDisabled}
            handleEdit={billingEdit}
            handleCancel={handleCancel}
          />
        )}
      </caption>
      {(Auth.hasQuickbooksPlc() === "true" ||
        Auth.hasQuickbooksPlc() === "null") &&
      (props.syncStatus === "QBSYNCSUCCESS" ||
        props.syncStatus === "QBSYNCFAIL") ? (
        <QbSyncInformation
          showModal={showModalQbSyncInformation}
          setShowModal={(e) => setShowModalQbSyncInformation(e)}
          transactionId={props.referenceId}
        />
      ) : null}
      {Auth.canProviewNotificationDetails() || Auth.canFinanceUser() ? (
        <NotificationInformation
          showModal={showModalNotificationInformation}
          setShowModal={(e) => setShowModalNotificationInformation(e)}
          transactionId={props.referenceId}
        />
      ) : null}
      <tbody>
        {createRows(billingDisplay, setBillingDisplay, billingDisabled)}
        <tr>
          <td>{paymentInfo.label}</td>
          <td>
            <strong>{paymentInfo.info}</strong>
            <div className="authorization hide-mobile" ref={node}>
              <div
                className="link"
                onClick={() => setAuthorization(!authorization)}
              >
                (Authorization Details)
              </div>
              <span
                className={
                  authorization ? "details-tables" : "details-tables hide"
                }
              >
                {authorizationInfo}
              </span>
              <div>
                {creditInfo.length > 0 && Auth.canFinanceUser() ? (
                  <div className="link" onClick={() => setCredit(!credit)}>
                    (Credit Details)
                  </div>
                ) : null}
                <span
                  className={credit ? "details-tables" : "details-tables hide"}
                >
                  {creditInfo}
                </span>
              </div>
              <div>
                {chargeBackInfo != null && (
                  <div
                    className="link"
                    onClick={() => setChargeBack(!chargeBack)}
                  >
                    (Charge Back Details)
                  </div>
                )}
                <span
                  className={
                    chargeBack ? "details-tables" : "details-tables hide"
                  }
                >
                  {chargeBackInfo}
                </span>
              </div>
              <div>
                {(Auth.hasQuickbooksPlc() === "true" ||
                  Auth.hasQuickbooksPlc() === "null") &&
                (props.syncStatus === "QBSYNCSUCCESS" ||
                  props.syncStatus === "QBSYNCFAIL") ? (
                  <div
                    onClick={handleOpenModalQbSyncInformation}
                    className="link"
                  >
                    (QuickBooks Sync Details)
                  </div>
                ) : null}
              </div>
              <div>
                {Auth.canProviewNotificationDetails() ||
                Auth.canFinanceUser() ? (
                  <div
                    onClick={handleOpenModalNotificationInformation}
                    className="link"
                  >
                    (Notification Details)
                  </div>
                ) : null}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default BillingInformation;
