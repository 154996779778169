// framework imports - 1st party
import React from "react";

// lib imports - 3rd party

// app imports
import * as authService from "../../authService";
import edit from "../../icons/edit.svg";
import cancel from "../../icons/delete.svg";
import update from "../../icons/update@3x.png";

const EditIcon = (props) => {
  // if can't modify, don't show icon
  if (!authService.canModify) {
    return null;
    // if not editing, don't show cancel
  } else if (props.disabled && props.canEdit) {
    return (
      <div name={props.name}>
        <button
          id={props.name + "Start"}
          className="edit-button hoverable hide-mobile"
          type="button"
          onClick={props.handleEdit}
        >
          <img className="edit-icon" alt="Edit Icon" src={edit} />
          <span className="edit-text">{"edit"}</span>
        </button>
      </div>
    );
    // if editing, show cancel
  } else if (props.canEdit) {
    return (
      <div name={props.name}>
        <button
          id={props.name + "Submit"}
          className="edit-button hoverable hide-mobile"
          type="button"
          onClick={props.handleEdit}
        >
          <img className="update-icon" alt="Update Icon" src={update} />
          <span className="edit-text">{"update"}</span>
        </button>
        <button
          id={props.name + "Cancel"}
          className="edit-button hoverable hide-mobile"
          type="button"
          onClick={props.handleCancel}
        >
          <img className="cancel-icon" alt="Cancel Icon" src={cancel} />
          <span className="cancel-text">{"cancel"}</span>
        </button>
      </div>
    );
  } else {
    return null;
  }
};

export default EditIcon;
