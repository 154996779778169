// framework imports - 1st party
import React from "react";
import PlcSelect from "../HelperComponents/PlcSelect";
import "../../stylesheets/SummarySearch.css";
import "../../stylesheets/Header.css";

const Search = (props) => {
  return (
    <tr className="search-row">
      <td id="plc" className="plc-cell">
        <PlcSelect
          class="plc-select"
          setPlc={(e) => props.setPlc(e)}
          name="search-for-plc"
          isMulti={true}
          numResults={500}
          stayOpen={false}
          plc={props.plc}
          id="summary-plc-select"
        />
      </td>
    </tr>
  );
};

export default Search;
