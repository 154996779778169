// framework imports - 1st party
import React from "react";

// app imports
import HistorySort from "./AuthSort";
import Page from "../HelperComponents/Page";
import Row from "./AuthRow";

const GenerateTable = (props) => {
  // dynamically generate rows
  const getRows = () => {
    //Calculate the amount of records that can be seen
    var offset = props.pageNum * props.pageSize.value;
    //Display Limit page if offset >=5000
    if (offset >= 5000) {
      return (
        <tr>
          <td>
            <div id="no-results-found">
              Limit Search Results is 5000 Records
            </div>
          </td>
        </tr>
      );
    }
    // if rows found
    else if (props.rows.length > 0) {
      return props.rows
        .slice(
          props.pageSize.value * props.pageNum,
          (props.pageNum + 1) * props.pageSize.value
        )
        .map((row, i) => <Row key={i} row={row} />);
    } else {
      return (
        <tr>
          <td>
            <div id="no-results-found">No results found</div>
          </td>
        </tr>
      );
    }
  };

  return (
    <div className="content authorization-table">
      <table>
        <tbody>
          <HistorySort />
          {getRows()}
        </tbody>
      </table>

      <Page
        links={props.links}
        pageSize={props.pageSize}
        onPageSize={(e) => {
          props.setPageSize(e);
          props.setNumElements(e.value);
          const offSet = Number.isInteger(props.totalElements / e.value);
          const pageNum = Math.floor(props.totalElements / e.value) - +offSet;
          if (props.pageNum > pageNum) {
            props.setPageNum(pageNum);
          }
        }}
        pageNum={props.pageNum}
        onPageChange={(e) => props.setPageNum(e)}
        numElements={props.numElements}
        totalElements={props.totalElements}
        loading={props.loading}
        table="payment-auth"
      />
    </div>
  );
};

export default GenerateTable;
