import decode from "jwt-decode";
import axios from "axios";
async function login(username, password) {
  // Clear filters from sessionStorage
  removeFiltersFromSessionStorage();
  // Get a token
  var postData = {
    username: username,
    password: password,
  };
  var config = {
    "Content-Type": "application/json",
  };
  return await axios
    .post("api/tokens/access", postData, config)
    .then((res) => {
      setToken(res.data.token);
      setItem(res.data.hasQuickbooksPlc, res.data.isForceChangePassword);
      setIdleTime(res.data.maxIdleTime);
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

async function signup(username, password) {
  // Get a token
  var postData = {
    username: username,
    password: password,
  };
  var config = {
    "Content-Type": "application/json",
  };
  return await axios
    .post("api/signup", postData, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

async function change(curpassword, newpassword, confirmpassword) {
  // Get a token
  var postData = {
    curpassword: curpassword,
    newpassword: newpassword,
    confirmpassword: confirmpassword,
  };
  var config = {
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post("/api/change", postData, config)
    .then((res) => {
      setToken(res.data.token);
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

function loggedIn() {
  // Checks if there is a saved token and it's still valid
  const token = getToken();
  return !!token && !isTokenExpired(token); // handwaiving here
}

function isTokenExpired(token) {
  try {
    const decoded = decode(token);
    return decoded.exp < Date.now() / 1000;
  } catch (err) {
    return false;
  }
}

function setToken(idToken) {
  // Saves user token to localStorage
  sessionStorage.setItem("id_token", idToken);
}

function getToken() {
  // Retrieves the user token from localStorage
  return sessionStorage.getItem("id_token");
}

function setItem(hasQuickbooksPlc, isForceChangePassword) {
  sessionStorage.setItem("has_quickbooks_plc", hasQuickbooksPlc);
  sessionStorage.setItem("is_force_change_password", isForceChangePassword);
}

function hasQuickbooksPlc() {
  return sessionStorage.getItem("has_quickbooks_plc");
}

function setIdleTime(maxIdleTime) {
  sessionStorage.setItem("max_idle_time", maxIdleTime);
}

function getIdleTime() {
  return sessionStorage.getItem("max_idle_time");
}

function isForceChangePassword() {
  return sessionStorage.getItem("is_force_change_password");
}

function logout() {
  // Clear user token and profile data from localStorage
  var config = {
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/json",
    },
  };
  sessionStorage.removeItem("id_token");
  sessionStorage.removeItem("max_idle_time");
  sessionStorage.removeItem("has_quickbooks_plc");
  sessionStorage.removeItem("is_force_change_password");
  removeFiltersFromSessionStorage();
  return axios
    .post("/api/token/revoke", null, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

function getProfile() {
  let token = getToken();
  return token ? decode(token) : null;
}

// check if user has transaction modify privilege
function canModify() {
  let token = getToken();
  return token
    ? decode(token).authorities.includes("ROLE_TRANSACTION_MODIFY")
    : null;
}

// check if user has transaction amount modify privilege
function canAmountModify() {
  let token = getToken();
  return token
    ? decode(token).authorities.includes("ROLE_TRANSACTION_AMOUNT_MODIFY")
    : null;
}

// check if user has finance privilege
function canFinance() {
  let token = getToken();
  return token ? decode(token).authorities.includes("ROLE_FINANCE") : null;
}

// check if user has transaction manifest privilege
function canTransactionManifest() {
  let token = getToken();
  return token
    ? decode(token).authorities.includes("ROLE_TRANSACTION_MANIFEST")
    : null;
}

// check if user has finance override privilege
function canFinanceOverride() {
  let token = getToken();
  return token
    ? decode(token).authorities.includes("ROLE_FINANCE_OVERRIDE")
    : null;
}

// check if user has finance override privilege
function canAdmin() {
  let token = getToken();
  return token ? decode(token).authorities.includes("GROUP_GPS_ADMIN") : null;
}

//check if user has transaction deny privilege
function canTransactionDeny() {
  let token = getToken();
  return token
    ? decode(token).authorities.includes("ROLE_TRANSACTION_DENY")
    : null;
}

//check if user has agency edit group role
function canAgencyEdit() {
  let token = getToken();
  return token ? decode(token).authorities.includes("GROUP_AGENCY_EDIT") : null;
}

//check if user has agency admin group role
function canAgencyAdmin() {
  let token = getToken();
  return token
    ? decode(token).authorities.includes("GROUP_AGENCY_ADMIN_USER")
    : null;
}

//check if user has resend plc
function canPlcResend() {
  let token = getToken();
  return token ? decode(token).authorities.includes("ROLE_PLC_RESEND") : null;
}
//check if user has plc information change
function canPlcInformationChange() {
  let token = getToken();
  return token
    ? decode(token).authorities.includes("ROLE_PLC_INFORMATION_CHANGE")
    : null;
}
//check if user has service fee override
function canServiceFeeOverride() {
  let token = getToken();
  return token
    ? decode(token).authorities.includes("ROLE_SERVICE_FEE_OVERRIDE")
    : null;
}
//check if user has allow CSRDNE
function canSendCSRDNE() {
  let token = getToken();
  return token ? decode(token).authorities.includes("ROLE_ALLOW_CSRDNE") : null;
}
//check if user has finance group role
function canFinanceUser() {
  let token = getToken();
  return token
    ? decode(token).authorities.includes("GROUP_FINANCE_USER")
    : null;
}
//check if user has notification_details role
function canProviewNotificationDetails() {
  let token = getToken();
  return token
    ? decode(token).authorities.includes("ROLE_PROVIEW_NOTIFICATION_DETAILS")
    : null;
}

function removeFiltersFromSessionStorage() {
  sessionStorage.removeItem("transSummaryFilter");
  sessionStorage.removeItem("transManifestFilter");
  sessionStorage.removeItem("recurringFilter");
  sessionStorage.removeItem("migrationFilter");
  sessionStorage.removeItem("transPartOfRPFilter");
}

export {
  login,
  signup,
  change,
  loggedIn,
  isTokenExpired,
  setToken,
  logout,
  getToken,
  getIdleTime,
  getProfile,
  canModify,
  canAmountModify,
  canFinance,
  canTransactionManifest,
  canFinanceOverride,
  canAdmin,
  hasQuickbooksPlc,
  isForceChangePassword,
  canTransactionDeny,
  canAgencyEdit,
  canAgencyAdmin,
  canPlcResend,
  canPlcInformationChange,
  canServiceFeeOverride,
  canSendCSRDNE,
  canFinanceUser,
  canProviewNotificationDetails,
};
