// framework imports - 1st party
import React, { useState, useEffect } from "react";

// lib imports - 3rd party
import { ToastContainer, Slide } from "react-toastify";
import moment from "moment";

// app imports
import * as api from "../api";
import GenerateHistory from "./History/GenerateHistory";
import HistoryTable from "./History/HistoryTable";
import Spinner from "./HelperComponents/Spinner";
import reportsAndStatements from "../icons/ic-reports-statements@3x.png";
import "../stylesheets/PageBody.css";
import withRouter from "./HelperComponents/WithRouter";

const TransactionHistory = (props) => {
  const [plc, setPlc] = useState([]);
  const [dates, setDates] = useState({
    start: null,
    end: null,
  });
  const [groupTransaction, setGroupTransaction] = useState({
    value: "daily",
    label: "Daily",
  });
  const [dateRange, setDateRange] = useState("");
  const [transactionType, setTransactionType] = useState({
    value: "ALL",
    label: "All Transaction",
  });
  const [canGenerate, setCanGenerate] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [summaries, setSummaries] = useState([]);
  const [totals, setTotals] = useState({
    TRANSACTIONS: "",
    ADDITIONAL_FEE_1: "",
    ADDITIONAL_FEE_2: "",
    ADDITIONAL_FEE_3: "",
    AVERAGE_PAYMENT: "",
  });

  // if small screensize (i.e. on mobile)
  // redirect to summary
  // because this page is not written
  // to be mobile responsive
  const handleResize = (e) => {
    if (window.innerWidth < 600) {
      props.navigate("/summary");
    }
  };
  // listener for resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // determine if values are added
  // so submit button can be active
  useEffect(() => {
    setCanGenerate(!(dates.start && dates.end));
  }, [plc, dates]);

  const convertToLocalTimeZone = (date) => {
    const dateFormat = "YYYY-MM-DD";
    const dateUtc = moment.utc(date);
    const localDate = dateUtc.local();
    return localDate.format(dateFormat);
  };
  // on Submit
  const handleSubmit = (e) => {
    e.preventDefault();

    // set up payload
    const parameters = {
      startDate: convertToLocalTimeZone(dates.start),
      endDate: convertToLocalTimeZone(dates.end),
      filterPlcs: !plc.length ? null : plc.map((x) => x.Plc),
      modality: transactionType.value,
    };
    setLoading(true);

    // api call for Transaction Report
    api
      .reports("transactionHistories/" + groupTransaction.value, parameters)
      .then((x) => {
        // This variable allows the table
        // to change it's columns dynamically
        // depending on whether Daily, Weekly, or Monthly is selected
        const transactionType = groupTransaction.value + "Summaries";
        setSummaries(x.data[transactionType]); // set rows based on transaction type
        setDateRange(groupTransaction.value); // set date range based on transaction type

        setTotals(x.data.totals);
        setLoading(false);
        setSubmitted(true);
      });
  };

  return (
    <main className="main-background">
      <div className="main-component">
        <div id="table-header">
          <div id="details-label">
            <img
              alt="Govpay Logo"
              src={reportsAndStatements}
              id="reports-and-statements-icon"
            />
            <div id="details-label-name">Transaction History</div>
          </div>
          <Spinner customClass={"sk-fading-circle summary"} loading={loading} />
        </div>

        <div>
          <GenerateHistory
            dates={dates}
            setDates={(e) => setDates({ start: e.start, end: e.end })}
            setPlc={(e) => setPlc(e)}
            onGroupTransaction={(e) => setGroupTransaction(e)}
            groupTransaction={groupTransaction}
            onTransactionType={(e) => setTransactionType(e)}
            transactionType={transactionType}
            canGenerate={canGenerate}
            submitted={(e) => handleSubmit(e)}
            setSpinner={(e) => setLoading(e)}
          />
        </div>
        <HistoryTable
          totals={totals}
          summaries={summaries}
          submitted={submitted}
          dateRange={dateRange}
        />
      </div>
      <ToastContainer transition={Slide} />
    </main>
  );
};

export default withRouter(TransactionHistory);
