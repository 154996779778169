// framework imports - 1st party
import React from "react";
import withRouter from "../HelperComponents/WithRouter";

// lib imports - 3rd party
import moment from "moment";

// app imports
import "../../stylesheets/Row.css";

// a given row on the transactions summary page
const ManifestRow = (props) => {
  // on click, go to transaction details
  const loadTransaction = () => {
    if (!props.loading) {
      props.navigate("/transaction/" + props.transaction.transactionId, {
        state: {
          transactionId: props.transaction.transactionId,
          prevScreen: "/manifest",
          writeNote: false,
        },
      });
    }
  };

  // determines if transaction status is denied and changes wording to rejected as per business request
  const renderStatus = (status) => {
    if (props.transaction.confirmationStatus === "DENIED") {
      return "REJECTED";
    }
    if (props.transaction.confirmationStatus === "ECHECKPENDING") {
      return "ECHECK PENDING";
    }
    if (props.transaction.confirmationStatus === "CASHPENDING") {
      return "CASH PENDING";
    } else {
      return props.transaction.confirmationStatus;
    }
  };

  const getDetails = () => {
    return props.transaction.paymentDetails
      .split(/\n/gi)
      .map((x) => <div>{x}</div>);
  };

  return (
    <tr className="row" key={props.index} onClick={loadTransaction}>
      <td className="transaction-id-row text-right mobile-left">
        <button className="transaction-id-button">
          {props.transaction.cartId
            ? props.transaction.transactionId + "**"
            : props.transaction.transactionId}
        </button>
      </td>
      <td className="text-right hide-mobile">{props.transaction.plcId}</td>
      <td className="text-left">{props.transaction.plcType}</td>
      <td className="text-right">
        {props.transaction.displayTotalAmountPlusGpsFee
          ? props.transaction.displayTotalAmountPlusGpsFee
          : "$0.00"}
      </td>

      <td
        className={
          "text-right hide-mobile " + props.transaction.confirmationStatus
        }
      >
        {props.transaction.startTime
          ? moment(props.transaction.startTime).format("M/DD/YYYY h:mm A")
          : null}{" "}
      </td>

      <td className="hide-mobile">
        {renderStatus(props.transaction.confirmationStatus)}
      </td>
      <td className="hide-mobile">{getDetails()}</td>
    </tr>
  );
};

export default withRouter(ManifestRow);
