// framework imports - 1st party
import React, { useState } from "react";

// lib imports - 3rd party
import { Slide, ToastContainer } from "react-toastify";

// app imports
import * as Auth from "../authService";
import * as notify from "../notify";
import { ReactComponent as Logo } from "../icons/logo.svg";
import "../stylesheets/Login.css";
import questionLogo from "../icons/question.png";
import ReactTooltip from "react-tooltip";
import withRouter from "./HelperComponents/WithRouter";

// ChangePasswordPage page
const ChangePasswordPage = (props) => {
  const [curpassword, setCurPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [validPassword, setValidPassword] = useState(true);

  // password validator regex
  const isPasswordStandard = () => {
    let rePassword8 =
      /^(?=.*\d)(?=.*[@#$%!^*()<>?/\\.,~_\-+=`])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    let rePassword12 =
      /^(?=.*\d)(?=.*[@#$%!^*()<>?/\\.,~_\-+=`])(?=.*[a-zA-Z]).{12,}$/;

    return (
      (newpassword.length >= 8 &&
        newpassword.length < 12 &&
        rePassword8.test(String(newpassword))) ||
      (newpassword.length >= 12 && rePassword12.test(String(newpassword)))
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      curpassword &&
      newpassword &&
      confirmpassword &&
      newpassword === confirmpassword &&
      curpassword !== newpassword &&
      isPasswordStandard()
    ) {
      setValidPassword(true);
      Auth.change(curpassword, newpassword, confirmpassword)
        .then((res) => {
          if (res.status === 400) {
            // add input error message
            setValidPassword(false);
            notify.error("New Password is not valid");
          } else if (res.status === 401) {
            notify.error("Current Password Not Match");
          } else {
            props.navigate("/login");
            notify.success("Change Password Success");
          }
        })
        .catch((err) => {
          notify.error("Change Password Failed");
        });
    } else if (!curpassword && !newpassword && !confirmpassword) {
      notify.error(
        "Please enter a Current Password, New Password and Confirm New Password"
      );
    } else if (!curpassword) {
      notify.error("Please enter a Current Password");
    } else if (!newpassword) {
      notify.error("Please enter a New Password");
    } else if (!confirmpassword) {
      notify.error("Please enter a Confirm New Password");
    } else if (curpassword === newpassword) {
      notify.error("New Password must not be the same as Current Password");
    } else if (!isPasswordStandard()) {
      notify.error("New Password is not valid");
    }
  };

  return (
    <div className="center">
      <div className="card">
        <div>
          <Logo id="LoginLogo" />
        </div>
        <form onSubmit={handleSubmit}>
          <label htmlFor="cur-password">Current Password</label>
          <input
            id="cur-password"
            className={"proview-input login"}
            autoComplete="off"
            type="password"
            placeholder="Current Password"
            value={curpassword}
            onChange={(e) => setCurPassword(e.target.value)}
          />
          <div>
            <label htmlFor="new-password">New Password</label>
            <img
              data-tip
              data-for="password-tip"
              className={"img-question"}
              alt="Govpay Logo"
              src={questionLogo}
              id="change-password-question-icon"
            />
            <input
              className={
                validPassword ? "proview-input login" : "proview-input error"
              }
              id="new-password"
              type="password"
              autoComplete="off"
              placeholder="New Password"
              value={newpassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <label htmlFor="confirm-password">Confirm New Password</label>
          <input
            className={"proview-input login"}
            id="confirm-password"
            type="password"
            autoComplete="off"
            placeholder="Confirm New Password"
            value={confirmpassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div className="input-validate-password">
            {newpassword !== confirmpassword ? (
              <div className="input-error change">
                New Password and Confirm New Password Not Match.
              </div>
            ) : null}
          </div>

          <button
            className="proview-button login"
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            Change Password
          </button>
        </form>
        <ReactTooltip id="password-tip" place="right" effect="solid">
          The password must meet the following criterias:
          <br />
          <p className="tab">
            - length at least 8 characters <br />
            - must contains one digit from 0-9 <br />
            - must contains one lowercase character <br />
            - must contains one uppercase character <br />- must contains one
            special symbols in the list '@#$%'
          </p>
        </ReactTooltip>
      </div>
      <ToastContainer transition={Slide} />
    </div>
  );
};

export default withRouter(ChangePasswordPage);
