// framework imports - 1st party
import React, { useState, useEffect } from "react";

// lib imports - 3rd party
import Select from "react-select";
import ESAPI from "node-esapi";

// app imports
import * as api from "../../api";
import * as notify from "../../notify";
import * as Auth from "../../authService";
import "../../stylesheets/Note.css";
import "../../stylesheets/styles.css";
import "../../stylesheets/GenerateReport.css";

const Note = (props) => {
  const [transactionId, setTransactionId] = useState(props.referenceId);
  const [issueType, setIssueType] = useState({
    value: "Other",
    label: "Other",
  });
  const [priority, setPriority] = useState({
    value: "Normal",
    label: "Normal",
  });
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [validTransactionId, setValidTransactionId] = useState(true);
  const [details, setDetails] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const issueTypes = [
    { value: "Overpayment", label: "Overpayment" },
    { value: "Underpayment", label: "Underpayment" },
    { value: "Financial - Other", label: "Financial - Other" },
    { value: "Transaction Information", label: "Transaction Information" },
    { value: "Refaxed Document", label: "Refaxed Document" },
    { value: "Bail Approval", label: "Bail Approval" },
    { value: "CSRDNE", label: "CSRDNE" },
    { value: "Other", label: "Other" },
  ];
  const priorities = [
    { value: "Normal", label: "Normal" },
    { value: "High", label: "High" },
    { value: "Critical", label: "Critical" },
  ];

  if (!Auth.canAdmin() && !Auth.canFinance() && !Auth.canSendCSRDNE()) {
    for (var i = 0; i < issueTypes.length; i++) {
      if (issueTypes[i].value === "CSRDNE") {
        issueTypes.splice(i, 1);
        i--;
      }
    }
  }

  // if note is on Transaction Details
  // i.e. it has a Ref
  // autofill ref,
  // else, make it a required input
  const generateRefId = (ref) => {
    if (ref) {
      return ref;
    } else {
      return (
        <input
          className={
            validTransactionId ? "proview-input" : " proview-input error"
          }
          id="ref-id"
          placeholder="Id"
          maxLength="8"
          value={props.referenceId}
          onChange={(e) => setTransactionId(e.target.value)}
        />
      );
    }
  };

  // validate email input on note
  const validateEmail = (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // submit note
  // handle invalid email,
  // invlaid transaction,
  // forbidden transaction,
  // and general failure to add note
  const handleSubmit = (e) => {
    e.preventDefault();
    const note = {
      issueType: issueType.value,
      details: details,
      emailAddress: email ? email : null,
      priority: priority.value,
    };

    if (!validateEmail(email) && email) {
      setValidEmail(false);
      notify.error("Please enter a valid email address");
    } else {
      setValidEmail(true);
      api.addNote(transactionId, "comments", note).then((x) => {
        if (x.status !== 200 && x.status !== 201) {
          setValidTransactionId(false);
          if (x.status === 403) {
            notify.error(
              ESAPI.encoder().encodeForHTML(
                "You do not have permission on transaction " + transactionId
              )
            );
          } else if (x.status === 404) {
            notify.error(
              ESAPI.encoder().encodeForHTML(
                "Transaction " + transactionId + " not found"
              )
            );
          } else if (x.status === 400) {
            notify.error("Invalid Transaction Id");
          } else {
            notify.error("Failed to add note Transaction");
          }
        } else {
          setValidTransactionId(true);
          notify.success(
            "Thank you for submitting your request.  Most requests are completed within one hour.  If your request requires a response, we will respond when the task is completed.  For urgent and time sensitive matters, call 1-866-564-0169.",
            true
          );
          setEmail("");
          setDetails("");
          if (typeof props.handleCloseModal == "function") {
            props.handleCloseModal();
          } else {
            props.retrieveInfo(e);
          }
        }
      });
    }
  };

  // determine if you can submit note
  useEffect(() => {
    setCanSubmit(transactionId && details);
  }, [transactionId, details]);

  return (
    <div id="note" className="report-form">
      <form onSubmit={handleSubmit}>
        <b>New Note</b>
        <table>
          <tbody className="note-table">
            <tr className="hide-mobile">
              <td>
                <label htmlFor="ref-id">Transaction Id</label>
              </td>
              <td>
                <label htmlFor="issue-type">Issue Type</label>
              </td>
              <td>
                <label htmlFor="priority">Priority</label>
              </td>
              <td>
                <label htmlFor="email">Email (if response desired)</label>
              </td>
            </tr>
            <tr>
              <td>{generateRefId(props.referenceId)}</td>

              <td>
                <Select
                  id="issue-type"
                  className="proview-select"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={false}
                  value={issueType}
                  options={issueTypes}
                  onChange={(e) => setIssueType(e)}
                />
              </td>
              <td>
                <Select
                  id="priority"
                  className="proview-select"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={false}
                  value={priority}
                  options={priorities}
                  onChange={(e) => setPriority(e)}
                />
              </td>
              <td>
                <input
                  className={
                    validEmail ? "proview-input" : " proview-input error"
                  }
                  placeholder="Email"
                  id="email"
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div>Details</div>
        <textarea
          placeholder="Note goes here..."
          id="noteText"
          className="proview-input details"
          value={details}
          onChange={(e) => setDetails(e.target.value)}
        />
        <div className="notes-bottom">
          <button
            className="proview-button right mobile"
            onClick={handleSubmit}
            type="submit"
            id="addNote"
            disabled={!canSubmit}
          >
            Send Note
          </button>
          {props.plcId ? null : (
            <button
              onClick={(e) =>
                props.handleCloseModal ? props.handleCloseModal(e) : null
              }
              className="proview-button cancel right"
              type="button"
            >
              Cancel
            </button>
          )}
          <div>
            Most transaction notes reported by this form are handled within one
            hour.
            <br />
            The transaction status cannot be changed between 2:45pm –<br />
            3:15pm EST (M-F) which is the settlement and payout window.
            <br />
          </div>
          <br />
          <div>
            For urgent and time sensitive matters, call:
            <br />
            1-866-564-0169.
          </div>
        </div>
      </form>
    </div>
  );
};

export default Note;
