// lib imports - 3rd party
import axios from "axios";
import fileDownload from "js-file-download";

// app imports
import * as Auth from "./authService";
import "es6-promise/auto";
import moment from "moment";

async function getBuild() {
  return await axios
    .get("/api/meta_info")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    });
}

// Query for the searchable PLC dropdowns
async function getPLCs(query, fraud = false) {
  // if searching on the exclusions page
  // use a different URL
  if (fraud) {
    return await axios
      .get("api/fraud/plcs", {
        headers: { Authorization: "Bearer " + Auth.getToken() },
        params: {
          q: query,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });

    // for all other PLCs
  } else {
    return await axios
      .get("/api/plcs", {
        headers: { Authorization: "Bearer " + Auth.getToken() },
        params: {
          q: query,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  }
}

async function getNotifyQbSyncByTransactionId(
  transactionId,
  pageSize,
  pageNum,
  unpaged = false
) {
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
    },
    params: {
      size: pageSize.value,
      page: pageNum,
      unpaged: unpaged ? "true" : "",
    },
  };

  return await axios
    .get("/api/getNotifyQbSyncByTransactionId/" + transactionId, config)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return [];
    });
}

async function getNotifyInformationByTransactionId(
  transactionId,
  pageSize,
  pageNum,
  unpaged = false
) {
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
    },
    params: {
      size: pageSize.value,
      page: pageNum,
      unpaged: unpaged ? "true" : "",
    },
  };

  return await axios
    .get("/api/getNotifyInformationByTransactionId/" + transactionId, config)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return [];
    });
}

async function getPlcByTransactionId(transactionId) {
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
    },
  };

  return await axios
    .get("/api/getPlcByTransactionId/" + transactionId, config)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return [];
    });
}

//to manage the Recurring Summary query
async function recurringSummary(
  recurringPaymentId,
  ref,
  plc,
  setupStartDates,
  setupEndDates,
  intervalDate,
  status,
  pageSize,
  pageNum,
  payment,
  exportable = false,
  unpaged = false
) {
  var postData = {
    filterRecurringPaymentId: isNaN(recurringPaymentId)
      ? ""
      : recurringPaymentId.toString(),
    filterTransactionId: isNaN(ref) || ref.length !== 8 ? "" : ref.toString(),
    filterApdTransactionId:
      !ref.startsWith("TX_") || ref.length < 10 || isNaN(ref.split("TX_").pop())
        ? ""
        : ref.toString(),
    filterPlcs: !plc.length ? null : plc.map((x) => x.Plc),
    filterStartDate: setupStartDates
      ? new Date(setupStartDates.clone().utc().startOf("day")).toISOString()
      : null,
    filterEndDate: setupEndDates
      ? new Date(setupEndDates.clone().utc().endOf("day")).toISOString()
      : null,
    filterIntervalDate: intervalDate ? intervalDate : null,
    filterStatus: status ? status.value : null,
    filterPayment: payment ? payment : null,
  };

  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
      Accept: exportable ? "text/csv" : "*/*",
    },
    params: {
      size: pageSize.value,
      page: pageNum,
      unpaged: unpaged ? "true" : "",
    },
  };
  return await axios
    .post("/api/recurringPaymentSummaries", postData, config)
    .then((res) => {
      if (exportable) {
        fileDownload(
          res.data,
          "transactionSummary.csv" + moment().format("MMDDYYYYHHmm") + ".csv"
        );
        return null;
      }
      return res.data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return [];
    });
}

//to manage the PlcMigration Summary query
async function plcMigrationSummary(
  Id,
  plc,
  start,
  end,
  createdAt,
  updatedAt,
  lastModifiedBy,
  name,
  pageSize,
  pageNum,
  exportable = false,
  unpaged = false
) {
  var postData = {
    filterPlcs: !plc.length ? null : plc.map((x) => x.Plc),
  };

  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
      Accept: exportable ? "text/csv" : "*/*",
    },
    params: {
      size: pageSize.value,
      page: pageNum,
      unpaged: unpaged ? "true" : "",
    },
  };
  return await axios
    .post("/api/plcMigrationSummaries", postData, config)
    .then((res) => {
      if (exportable) {
        fileDownload(
          res.data,
          "transactionSummary.csv" + moment().format("MMDDYYYYHHmm") + ".csv"
        );
        return null;
      }
      return res.data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return [];
    });
}

async function getUserProfile(userId) {
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
    },
  };

  return await axios
    .get("/api/user-info/" + userId, config)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return [];
    });
}

async function cancelRecurringPayment(recurringPaymentId) {
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
    },
  };

  return await axios
    .post("/api/recurringPayment/" + recurringPaymentId + "/cancel", {}, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return [];
    });
}

// to manage the Transaction Summary query
async function transactionSummary(
  ref,
  plc,
  startDate,
  endDate,
  payoutStartDate,
  payoutEndDate,
  status,
  syncStatus,
  uuid,
  recurringPaymentId,
  pageSize,
  pageNum,
  sort,
  name,
  payment,
  exportable = false,
  unpaged = false
) {
  var postData = {
    filterTransactionId: isNaN(ref) || ref.length !== 8 ? "" : ref.toString(),
    filterApdTransactionId:
      !ref.startsWith("TX_") || ref.length < 10 || isNaN(ref.split("TX_").pop())
        ? ""
        : ref.toString(),
    filterCartId: !ref.startsWith("CART") ? "" : ref.toString(),
    filterPlcs: !plc.length ? null : plc.map((x) => x.Plc),
    filterStartDate: startDate
      ? new Date(startDate.clone().utc().startOf("day")).toISOString()
      : null,
    filterEndDate: endDate
      ? new Date(endDate.clone().utc().endOf("day")).toISOString()
      : null,
    filterStartPayoutDate: payoutStartDate
      ? new Date(payoutStartDate.clone().utc().startOf("day")).toISOString()
      : null,
    filterEndPayoutDate: payoutEndDate
      ? new Date(payoutEndDate.clone().utc().endOf("day")).toISOString()
      : null,
    filterConfirmationStatus: status ? status.value : null,
    filterSyncStatus: syncStatus ? syncStatus.value : null,
    filterLastName: name ? name : null,
    filterPayment: payment ? payment : null,
    filterUuid: uuid.length < 5 || !uuid.startsWith("TX_") ? "" : uuid,
    filterRecurringPaymentId: recurringPaymentId ? recurringPaymentId : null,
  };
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
      Accept: exportable ? "text/csv" : "*/*",
    },
    params: {
      // page: 1,
      size: pageSize.value,
      page: pageNum,
      sort: sort.sortId + (sort.asc ? "" : ",desc"),
      unpaged: unpaged ? "true" : "",
    },
  };

  let fileName = recurringPaymentId
    ? "transactionPartOfRecurringPayment.csv"
    : "transactionSummary.csv";

  return await axios
    .post("/api/transactionSummaries", postData, config)
    .then((res) => {
      if (exportable) {
        fileDownload(
          res.data,
          fileName + moment().format("MMDDYYYYHHmm") + ".csv"
        );
        return null;
      }
      return res.data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return [];
    });
}

// to manage the Transaction Manifest query
async function transactionManifest(
  ref,
  plc,
  bail,
  startDate,
  endDate,
  status,
  billingName,
  uuid,
  pageSize,
  pageNum,
  sort,
  payment,
  exportable = false,
  unpaged = false
) {
  var postData = {
    filterTransactionId: isNaN(ref) || ref.length !== 8 ? "" : ref.toString(),
    filterApdTransactionId:
      !ref.startsWith("TX_") || ref.length < 10 || isNaN(ref.split("TX_").pop())
        ? ""
        : ref.toString(),
    filterCartId: !ref.startsWith("CART") ? "" : ref.toString(),
    filterPlcs: !plc.length ? null : plc.map((x) => x.Plc),
    filterPlcType: bail ? bail.value : null,
    filterPayment: payment ? payment : null,
    filterStartDate: startDate
      ? new Date(startDate.clone().utc().startOf("day")).toISOString()
      : null,
    filterEndDate: endDate
      ? new Date(endDate.clone().utc().endOf("day")).toISOString()
      : null,
    filterBillingName: billingName ? billingName : null,
    filterUuid: uuid.length < 5 || !uuid.startsWith("TX_") ? "" : uuid,
    filterConfirmationStatus: status ? status.value : null,
    // conf status?
  };
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
      Accept: exportable ? "text/csv" : "*/*",
    },
    params: {
      size: pageSize.value,
      page: pageNum,
      sort: sort.sortId + (sort.asc ? "" : ",desc"),
      unpaged: unpaged ? "true" : "",
    },
  };
  return await axios
    .post("/api/transactionManifests", postData, config)
    .then((res) => {
      if (exportable) {
        fileDownload(
          res.data,
          "transactionManifest.csv" + moment().format("MMDDYYYYHHmm") + ".csv"
        );
        return null;
      }
      return res.data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return [];
    });
}

// for all the separate queries
// necessary for the Transaction Details page
async function getTransaction(id, customInfo = "") {
  return await axios
    .get("/api/transactions/" + id + "/" + customInfo, {
      headers: { Authorization: "Bearer " + Auth.getToken() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    });
}

// update a section of the Transaction Details page
async function transactionUpdate(
  id,
  customInfo,
  billingInfo,
  paymentAuthId = ""
) {
  var postData = billingInfo;
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
    },
  };
  return await axios
    .put(
      "/api/transactions/" + id + "/" + customInfo + "/" + paymentAuthId,
      postData,
      config
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return err.response;
    });
}

//update service fee before sending transaction
async function transactionServiceFee(
  id,
  newPlcId,
  customInfo,
  billingInfo,
  paymentAuthId = ""
) {
  var postData = billingInfo;
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post(
      "/api/transactions/" +
        id +
        "/" +
        newPlcId +
        "/" +
        customInfo +
        "/" +
        paymentAuthId,
      postData,
      config
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return err.response;
    });
}

// for changing PLC
async function plcTransaction(plc, type) {
  return await axios
    .get("/api/plcs/" + plc + "/" + type, {
      headers: { Authorization: "Bearer " + Auth.getToken() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    });
}

async function updatePlc(referenceId, payload) {
  var postData = payload;
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post("/api/transactions/" + referenceId + "/plcInfo", postData, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return err.response;
    });
}

// Add a note (either on bottom of Transaction Details)
// or in header
async function addNote(id, customInfo, billingInfo) {
  var postData = billingInfo;
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post("/api/transactions/" + id + "/" + customInfo + "/", postData, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      console.log(JSON.stringify(err));
      return err.response;
    });
}

async function getResendDropdown(transId) {
  return await axios
    .get("/api/transactions/" + transId + "/notifyInfo", {
      headers: { Authorization: "Bearer " + Auth.getToken() },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    });
}

// Send notification
async function sendNotification(transId, type, dest, attn) {
  var postData = { notifyType: type, notifyDest: dest, notifyAttn: attn };
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post("/api/transactions/" + transId + "/notifications", postData, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return err;
    });
}

// Approve transaction
// on either the Details page or on
// Transaction Summary
async function approveTransactions(transactions) {
  var postData = transactions;
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post("/api/transactions/approvals", postData, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return err;
    });
}
async function denyTransactions(transactionId, type, reason = "") {
  var postData = {
    reason: reason,
  };
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post("/api/transactions/" + transactionId + "/" + type, postData, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return err;
    });
}

async function toggleBail(transactionId, boolean) {
  var postData = boolean;
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post(
      "/api/transactions/" + transactionId + "/toggleRemoteBail",
      postData,
      config
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return err;
    });
}

// Generate report
// for Payout date
// OR Recon Payment
async function generateReport(plc, date, reportType) {
  var postData = '"' + moment(date).format("YYYY-MM-DD") + '"';
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post("/api/reports/plcs/" + plc + "/" + reportType, postData, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// Resend Report
async function resendReport(plc, date, type) {
  var postData = '"' + moment(date).format("YYYY-MM-DD") + '"';
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post("/api/reports/plcs/" + plc + "/" + type + "/resend", postData, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return err;
    });
}

// used for Transaction History tables
async function reports(name, parameters) {
  var postData = parameters;
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post("/api/reports/" + name, postData, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return err;
    });
}

// Get results from payment auth
async function paymentAuth(parameters) {
  var postData = parameters;
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
    },
    params: {
      size: 5000,
      page: 0,
      unpaged: "FALSE",
    },
  };
  return await axios
    .post("/api/paymentAuths", postData, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERRORL ", err);
      return err;
    });
}

// Get all entries for exclusions, based on a plc
async function fraudEntries(plc) {
  var postData = [plc];
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post("/api/fraud/entries/fieldId0", postData, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR ", err);
      return err;
    });
}

// Delete exclusions entry
async function deleteEntry(entry, reason) {
  return await axios
    .delete("/api/fraud/entries/" + entry, {
      data: {
        reason: reason,
      },
      headers: {
        Authorization: "Bearer " + Auth.getToken(),
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return err;
    });
}

// add an exclusion
async function addEntry(parameters) {
  var postData = parameters;
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post("/api/fraud/entries", postData, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return err;
    });
}
//Get NotifyInfo transactions
async function getNotifyInfoListTransactions(transactions) {
  var postData = transactions;
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post("/api/transactions/notifyInfoList", postData, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return err;
    });
}

//Get NotifyInfo transactions
async function getTransactionReceipt(transactionId) {
  var config = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post("/api/transactions/getReceipt/" + transactionId, null, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return err;
    });
}

async function getRecurringPayment(id) {
  return await axios
    .get("/api/recurringPayment/" + id, {
      headers: { Authorization: "Bearer " + Auth.getToken() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    });
}

export {
  getBuild,
  getPLCs,
  getTransaction,
  transactionSummary,
  transactionUpdate,
  transactionServiceFee,
  plcTransaction,
  updatePlc,
  approveTransactions,
  denyTransactions,
  transactionManifest,
  sendNotification,
  generateReport,
  resendReport,
  getResendDropdown,
  addNote,
  reports,
  paymentAuth,
  fraudEntries,
  deleteEntry,
  addEntry,
  toggleBail,
  getNotifyInfoListTransactions,
  getTransactionReceipt,
  recurringSummary,
  plcMigrationSummary,
  getUserProfile,
  cancelRecurringPayment,
  getRecurringPayment,
  getPlcByTransactionId,
  getNotifyQbSyncByTransactionId,
  getNotifyInformationByTransactionId,
};
