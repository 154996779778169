// framework imports - 1st party
import React, { useState, useEffect } from "react";

// lib imports - 3rd party

// app imports
import ExportCsv from "../HelperComponents/ExportCsv";
import Page from "../HelperComponents/Page";
import ManifestSearch from "./ManifestSearch";
import ManifestSort from "./ManifestSort";
import ManifestRow from "./ManifestRow";
import * as api from "../../api";
import useDebounce from "../../useDebounce";
import moment from "moment";
import "../../stylesheets/Table.css";
import withRouter from "../HelperComponents/WithRouter";

// Table component for Transaction Summaries
const ManifestTable = (props) => {
  // retrieve manifest filter from sessionStorage
  let transManifestFilterObj = JSON.parse(
    sessionStorage.getItem("transManifestFilter")
  );
  if (transManifestFilterObj == null) {
    transManifestFilterObj = {};
    transManifestFilterObj["criteria"] = {};
    transManifestFilterObj["pagination"] = {};
  }
  let criteria = transManifestFilterObj["criteria"];
  let pagination = transManifestFilterObj["pagination"];

  const [firstLoad, setFirstLoad] = useState(true); // first time for component loaded
  const [status, setStatus] = useState(criteria.status ? criteria.status : ""); // status field
  const [bail, setBail] = useState(criteria.bail ? criteria.bail : ""); // status field
  const [referenceId, setReferenceId] = useState(
    criteria.referenceId ? criteria.referenceId : ""
  ); // Ref field
  const [name, setName] = useState(""); // name field
  const [billingName, setBillingName] = useState(
    criteria.billingName ? criteria.billingName : ""
  ); // name field
  const [payment, setPayment] = useState(
    criteria.payment ? criteria.payment : ""
  ); // payment field
  const [plc, setPlc] = useState(criteria.plc ? criteria.plc : []); // plc dropdown values
  const [uuid, setUuid] = useState(criteria.uuid ? criteria.uuid : "");
  const [pageSize, setPageSize] = useState(
    pagination.pageSize
      ? pagination.pageSize
      : {
          //page size
          value: 10,
          label: "10 Results/Page",
        }
  );
  const [pageNum, setPageNum] = useState(
    pagination.pageNum ? pagination.pageNum : 0
  ); // page num
  const [numElements, setNumElements] = useState(0); // number of elements on page
  const [totalElements, setTotalElements] = useState(0); // total elements on query
  const [dates, setDates] = useState({
    //Date field
    start:
      criteria.referenceId && criteria.referenceId.startsWith("CART")
        ? ""
        : criteria.dates && criteria.dates.start
        ? moment(criteria.dates.start)
        : moment().subtract(1, "month"),
    end:
      criteria.referenceId && criteria.referenceId.startsWith("CART")
        ? ""
        : criteria.dates && criteria.dates.end
        ? moment(criteria.dates.end)
        : moment(),
  });
  const [payoutDates, setPayoutDates] = useState({
    //Payout Date field
    start: null,
    end: null,
  });
  const [transactions, setTransactions] = useState([]); // transaction info in rows
  const [sort, setSort] = useState(
    pagination.sort ? pagination.sort : { sortId: "created_at", asc: false }
  ); // current sort state
  const debounceReferenceId = useDebounce(referenceId, 500); // debounce referenceID
  const debouncePayment = useDebounce(payment, 500); // debounce payment
  const debounceUuid = useDebounce(uuid, 500); // debounce uuid
  const debounceBillingName = useDebounce(billingName, 500); // debounce billingName

  // Main query to retrieve transactions
  const getManifest = (customPage = pageNum) => {
    // save manifest filter to sessionStorage
    let transManifestFilter = {};
    transManifestFilter["criteria"] = {};

    if (referenceId.length === 8 && !isNaN(referenceId)) {
      transManifestFilter["criteria"].referenceId = referenceId;
    } else if (
      referenceId.length >= 10 &&
      referenceId.startsWith("TX_") &&
      !isNaN(referenceId.split("TX_").pop())
    ) {
      transManifestFilter["criteria"].referenceId = referenceId;
    } else if (referenceId.startsWith("CART")) {
      transManifestFilter["criteria"].referenceId = referenceId;
    } else {
      transManifestFilter["criteria"].referenceId = "";
    }

    transManifestFilter["criteria"].plc = plc;
    transManifestFilter["criteria"].bail = bail;
    transManifestFilter["criteria"].payment = payment;
    transManifestFilter["criteria"].dates = {
      start: dates.start ? dates.start.clone().toISOString() : null,
      end: dates.end ? dates.end.clone().toISOString() : null,
    };
    transManifestFilter["criteria"].status = status;
    transManifestFilter["criteria"].billingName = billingName;
    transManifestFilter["criteria"].uuid =
      uuid.length < 5 || !uuid.startsWith("TX_") ? "" : uuid;

    transManifestFilter["pagination"] = {};
    transManifestFilter["pagination"].pageSize = pageSize;
    transManifestFilter["pagination"].pageNum = pageNum;
    transManifestFilter["pagination"].sort = sort;

    sessionStorage.setItem(
      "transManifestFilter",
      JSON.stringify(transManifestFilter)
    );
    return api.transactionManifest(
      debounceReferenceId,
      plc,
      bail,
      dates.start,
      dates.end,
      status,
      debounceBillingName,
      debounceUuid,
      pageSize,
      customPage,
      sort,
      debouncePayment
    );
  };

  /* If there's a change in search parameters, do a new fetch on page one */
  useEffect(() => {
    if (!firstLoad && pageNum !== 0) {
      setPageNum(0);
    } else if (
      (uuid.length < 5 || !uuid.startsWith("TX_")) &&
      uuid.length !== 0 &&
      !uuid.startsWith("CART")
    ) {
      // don't run query
    } else if (
      referenceId.length < 10 &&
      referenceId.length !== 8 &&
      referenceId.length !== 0 &&
      !referenceId.startsWith("CART")
    ) {
      // don't run query if reference is not right length
    } else if (
      referenceId.length >= 10 &&
      !referenceId.startsWith("TX_") &&
      !referenceId.startsWith("CART")
    ) {
      // don't run query
    } else if (
      referenceId.length >= 10 &&
      referenceId.startsWith("TX_") &&
      isNaN(referenceId.split("TX_").pop()) &&
      !referenceId.startsWith("CART")
    ) {
      // don't run query
    } else if (
      referenceId.length === 8 &&
      isNaN(referenceId) &&
      !referenceId.startsWith("CART")
    ) {
      // don't run query
    } else {
      props.setSpinner(true);
      getManifest().then((x) => {
        setTransactions(x.content);
        setTotalElements(x.totalElements);
        setNumElements(x.numberOfElements);
        props.setSpinner(false);
      });
    }
  }, [
    debounceUuid,
    debounceBillingName,
    status,
    bail,
    debounceReferenceId,
    plc,
    dates,
    sort,
    debouncePayment,
  ]);

  /* No fetch on page change, NOT an input change */
  useEffect(() => {
    if (transactions.length !== 0) {
      props.setSpinner(true);
      getManifest().then((x) => {
        setTransactions(x.content);
        setTotalElements(x.totalElements);
        setNumElements(x.numberOfElements);
        props.setSpinner(false);
      });
    }
  }, [pageNum, pageSize]);

  useEffect(() => {
    // set it to false after component is loaded first time.
    setFirstLoad(false);
  }, []);

  // generate rows
  const getRows = () => {
    // if transaction undefined (error in retrieval)
    if (!transactions) {
      return (
        <tr>
          <td>
            <div id="no-results-found">Error in Search</div>
          </td>
        </tr>
      );
    }
    // if return some number of valid rows, display them normally
    else if (transactions.length > 0) {
      return transactions.map((transaction, i) => (
        <ManifestRow
          loading={props.loading}
          key={i}
          index={i}
          referenceId={transaction.transactionId}
          transaction={transaction}
        />
      ));
    }
    // if the page is on initial load, load FAKE data and blur
    else if (props.loading && transactions.length === 0) {
      return Array.from(Array(10), (_, i) => (
        <tr key={i}>
          <td>
            <div id="no-results-found">Loading...</div>
          </td>
        </tr>
      ));
    }
    // otherwise, no results were found
    else {
      return (
        <tr>
          <td>
            <div id="no-results-found">No results found</div>
          </td>
        </tr>
      );
    }
  };

  return (
    <div className="content transaction-manifest">
      <ExportCsv
        totalElements={totalElements}
        numElements={numElements}
        pageSize={pageSize}
        debounceReferenceId={debounceReferenceId}
        plc={plc}
        dates={dates}
        status={status}
        sort={sort}
        pageNum={pageNum}
        debouncePayment={debouncePayment}
        debounceBillingName={debounceBillingName}
        debounceUuid={debounceUuid}
        page="manifest"
      />
      <table>
        <tbody className="transaction-man">
          <ManifestSearch
            dates={dates}
            setDates={(e) => setDates({ start: e.start, end: e.end })}
            payoutDates={payoutDates}
            setPayoutDates={(e) =>
              setPayoutDates({ start: e.start, end: e.end })
            }
            setPlc={(e) => setPlc(e)}
            plc={plc}
            onReferenceId={(e) => setReferenceId(e)}
            referenceId={referenceId}
            onName={(e) => setName(e)}
            name={name}
            onPayment={(e) => setPayment(e)}
            payment={payment}
            onStatus={(e) => setStatus(e)}
            status={status}
            onBail={(e) => setBail(e)}
            bail={bail}
            onBillingName={(e) => setBillingName(e)}
            billingName={billingName}
            onUuid={(e) => setUuid(e)}
            uuid={uuid}
            pageSize={pageSize}
            totalElements={totalElements}
          />
          <ManifestSort sort={sort} setSort={(e) => setSort(e)} />
          {getRows()}
        </tbody>
      </table>
      <Page
        pageSize={pageSize}
        onPageSize={(e) => {
          let firstData = pageNum * pageSize.value + 1;
          let newPageNum = Math.floor(firstData / e.value);
          setPageNum(newPageNum);
          setPageSize(e);
        }}
        pageNum={pageNum}
        onPageChange={(e) => setPageNum(e)}
        numElements={numElements}
        totalElements={totalElements}
        loading={props.loading}
        table="manifest"
      />
      <p className="text-note">
        {props.loading
          ? ""
          : "(**) Transactions marked with ** are part of Shopping Cart"}
      </p>
    </div>
  );
};
export default withRouter(ManifestTable);
