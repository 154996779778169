// framework imports - 1st party
import React, { useState, useEffect } from "react";
// 3rd party imports
import Modal from "react-modal";
import moment from "moment";

// app imports
import "../../stylesheets/Login.css";
import "../../stylesheets/styles.css";
import "../../stylesheets/Header.css";
import "../../stylesheets/Modal.css";
import * as api from "../../api";
import Page from "../HelperComponents/Page";
// PasswordChange modal and logic
const QbSyncInformation = (props) => {
  const [pageSize, setPageSize] = useState({
    //page size
    value: 5,
    label: "5 Results/Page",
  });
  const [pageNum, setPageNum] = useState(0); // page num
  const [numElements, setNumElements] = useState(0); // number of elements on page
  const [totalElements, setTotalElements] = useState(0); // total elements on query
  const [showModal, setShowModal] = useState(false);
  const [notify, setNotify] = useState([]);

  if (showModal !== props.showModal) {
    setShowModal(props.showModal);
  }
  useEffect(() => {
    api
      .getNotifyQbSyncByTransactionId(props.transactionId, pageSize, pageNum)
      .then((x) => {
        if (x) {
          // set Notify Information
          setNotify(x.content);
          setTotalElements(x.totalElements);
          setNumElements(x.numberOfElements);
        }
      });
  }, [pageSize, pageNum]);

  return (
    <Modal
      isOpen={props.showModal}
      shouldCloseOnOverlayClick={true} // that's it!
      onRequestClose={() => {
        props.setShowModal(false);
      }}
      className="Modal modal-qb"
      overlayClassName="Overlay"
      ariaHideApp={false}
    >
      <table className="qb-info-table">
        <caption className="title-qb">
          <div className="table-title">Quickbooks Sync Notifications</div>
        </caption>
        <tbody>
          <tr className="row">
            <td className="text-right hide-mobile">
              <strong>Notify Id</strong>
            </td>
            <td className="text-right hide-mobile">
              <strong>Transaction</strong>
            </td>
            <td className="text-right hide-mobile">
              <strong>APD Transaction</strong>
            </td>
            <td className="text-right hide-mobile">
              <strong>Completion Date</strong>
            </td>
            <td className="text-right hide-mobile">
              <strong>Status</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="scrollbar-qb">
        <table className="qb-info-table">
          <tbody className="font-table">
            {notify.map((notify, i) => (
              <tr className="gr_row" key={i}>
                <td className="text-right hide-mobile">{notify.notifyId}</td>
                <td className="text-right hide-mobile">
                  {notify.transactionId || "-"}
                </td>
                <td className="text-right hide-mobile">
                  {notify.apdTransactionId || "-"}
                </td>
                <td className="text-right hide-mobile">
                  {notify.notifyCompletionDatetime
                    ? moment(notify.notifyCompletionDatetime).format(
                        "M/DD/YYYY hh:mm A"
                      )
                    : "-"}
                </td>
                <td className="text-right hide-mobile">
                  {notify.notifyStatus}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Page
        qbModal={true}
        pageSize={pageSize}
        onPageSize={(e) => {
          let firstData = pageNum * pageSize.value + 1;
          let newPageNum = Math.floor(firstData / e.value);
          setPageNum(newPageNum);
          setPageSize(e);
        }}
        pageNum={pageNum}
        onPageChange={(e) => setPageNum(e)}
        numElements={numElements}
        totalElements={totalElements}
        loading={props.loading}
      />
    </Modal>
  );
};

export default QbSyncInformation;
