// framework imports - 1st party
import React from "react";

// lib imports - 3rd party
import Modal from "react-modal";

const QbSyncResendConfirm = (props) => {
  return (
    <Modal
      isOpen={props.showModalConfirm}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        props.setShowModalConfirm(false);
      }}
      className="Modal confirm-qb-resend"
      overlayClassName="Overlay"
      ariaHideApp={false}
    >
      <h3>
        Payment was already synced. This could cause a duplicate payment, are
        you sure?
      </h3>
      <div>
        <button
          type={"button"}
          className="proview-button cancel left"
          onClick={() => props.setShowModalConfirm(false)}
        >
          No
        </button>
        <button className="proview-button right" onClick={props.submitResend}>
          Yes
        </button>
      </div>
    </Modal>
  );
};

export default QbSyncResendConfirm;
