// framework imports - 1st party
import React from "react";

// lib imports - 3rd party
import Select, { components } from "react-select";

// app imports
import ReactDates from "../HelperComponents/ReactDates";
import PlcSelect from "../HelperComponents/PlcSelect";
import { ReactComponent as Caret } from "../../icons/caret-down.svg";
import { ReactComponent as Delete } from "../../icons/delete.svg";
import "../../stylesheets/SummarySearch.css";
import "../../stylesheets/Header.css";

const ManifestSearch = (props) => {
  const options = [
    { value: "INCOMPLETE", label: "Incomplete" },
    { value: "ECHECKPENDING", label: "Echeck Pending" },
    { value: "CASHPENDING", label: "Cash Pending" },
    { value: "READY", label: "Ready" },
    { value: "APPROVED", label: "Approved" },
    { value: "DENIED", label: "Rejected" },
    { value: "PAID", label: "Paid" },
  ];

  const bailType = [
    { value: "BAIL", label: "Bail" },
    { value: "NON_BAIL", label: "Nonbail" },
  ];

  return (
    <tr className="search-row">
      <td>
        <input
          required="required"
          className="proview-input filter"
          maxLength="13"
          id="ref"
          type="text"
          placeholder="Ref #"
          value={props.referenceId}
          onChange={(e) => props.onReferenceId(e.target.value.trim())}
        />
      </td>
      <td id="plc" className="plc-cell">
        <PlcSelect
          class="plc-select manifest"
          setPlc={(e) => props.setPlc(e)}
          isMulti={true}
          numResults={500}
          stayOpen={false}
          plc={props.plc}
        />
      </td>
      <td>
        <Select
          id="plc-type"
          options={bailType}
          className="basic-filter-select"
          classNamePrefix="select"
          isClearable={true}
          isSearchable={false}
          onChange={(e) => props.onBail(e)}
          value={props.bail}
          components={{ DropdownIndicator }}
          clearRenderer={() => <Delete />}
        />
      </td>
      <td id="name">
        <input
          required="required"
          className="proview-input filter text-right"
          id="Payment"
          maxLength="8"
          type="text"
          placeholder="$0.00"
          value={props.payment}
          onChange={(e) =>
            e.target.value === "" || !isNaN(e.target.value)
              ? props.onPayment(e.target.value)
              : null
          }
        />
      </td>
      <td id="payout-date">
        <ReactDates
          dates={props.dates}
          setDates={(e) => props.setDates(e)}
          startId="start-filter-page"
          endId="end-filter-page"
        />
      </td>
      <td>
        <Select
          id="status"
          options={options}
          className="basic-filter-select"
          classNamePrefix="select"
          isClearable={true}
          isSearchable={false}
          onChange={(e) => props.onStatus(e)}
          value={props.status}
          components={{ DropdownIndicator }}
          clearRenderer={() => <Delete />}
        />
      </td>
      <td>
        <input
          required="required"
          className="proview-input filter"
          id="billingName"
          type="text"
          placeholder="Search Billing First/Last Name"
          value={props.billingName}
          onChange={(e) => props.onBillingName(e.target.value)}
        />
      </td>
    </tr>
  );
};

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Caret />
      </components.DropdownIndicator>
    )
  );
};
export default ManifestSearch;
