// framework imports - 1st party
import React, { useEffect } from "react";

// lib imports - 3rd party

// app imports
import "../stylesheets/PageBody.css";

const NotFound = (props) => {
  // if small screensize (i.e. on mobile)
  // redirect to summary
  // because this page is not written
  // to be mobile responsive
  const handleResize = (e) => {
    if (window.innerWidth < 600) {
      props.navigate("/summary");
    }
  };
  // listener for resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main className="main-background">
      <div className="main-component">
        <div id="table-header">
          <div id="details-label-not-found">
            <h1>404: Page Not Found</h1>
            <p>The requested page does not exist</p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default NotFound;
