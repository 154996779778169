// framework imports - 1st party
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// lib imports - 3rd party
import { ToastContainer, Slide } from "react-toastify";

// app imports
import * as Auth from "../authService";
import * as notify from "../notify";

import { ReactComponent as Logo } from "../icons/logo.svg";
import "../stylesheets/Login.css";

// Login page
const Login = (props) => {
  const navigate = useNavigate();
  // if already logged in, go to main page
  useEffect(() => {
    if (Auth.loggedIn()) navigate("/summary", { replace: true });
  }, []);

  const [userID, setUserID] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState();
  const [disableLoginBtn, setDisableLoginBtn] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    setErrorMsg(null);

    // if username and password are filled in
    if (userID && password) {
      Auth.login(userID, password)
        .then((res) => {
          if (res.status === 401) {
            // add input error message
            if (res.data.message === "Unauthorized") {
              setErrorMsg("Incorrect Username or Password.");
            } else {
              let data = JSON.parse(res.data.message);
              if (
                !data.numberOfLoginRemaining ||
                data.numberOfLoginRemaining === 0
              ) {
                setTimeRemaining(data.lockTimeRemaining);
                setDisableLoginBtn(true);
              } else {
                setErrorMsg("Incorrect Username or Password.");
              }
            }
          } else {
            if (Auth.isForceChangePassword() === "true") {
              navigate("/changepasswordpage", { replace: true });
            } else {
              navigate("/summary", { replace: true });
            }
          }
        })
        .catch((err) => {
          notify.error("Failed to login");
        });
    } else if (!userID && !password) {
      notify.error("Please enter a User ID and Password");
    } else if (!userID) {
      notify.error("Please enter a User ID");
    } else if (!password) {
      notify.error("Please enter a Password");
    }
  };

  // const calTimeRemaining = () => {
  //   let mins = Math.floor(timeRemaining / 60);
  //   let secs = timeRemaining - mins * 60;
  //
  //   if (mins.toString().length === 1) {
  //     mins = "0" + mins;
  //   }
  //
  //   if (secs.toString().length === 1) {
  //     secs = "0" + secs;
  //   }
  //
  //   return mins + ":" + secs + "s.";
  // };

  useEffect(() => {
    if (timeRemaining === 0 || timeRemaining === null) {
      setErrorMsg(null);
      setDisableLoginBtn(false);
      return;
    }

    setErrorMsg("Incorrect Username or Password.");

    const timer = setInterval(() => {
      setTimeRemaining(timeRemaining - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeRemaining]);

  return (
    <div className="center">
      <div className="card">
        <div>
          <Logo id="LoginLogo" />
        </div>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="userID">User ID</label>
          <input
            className={"proview-input login"}
            placeholder="User ID"
            id="userID"
            name="userID"
            type="text"
            autoCapitalize="off"
            autoComplete="off"
            spellCheck="false"
            autoCorrect="off"
            value={userID}
            onChange={(e) => setUserID(e.target.value)}
          />
          <label htmlFor="password">Password</label>
          <input
            className={"proview-input login"}
            placeholder="Password"
            id="password"
            name="password"
            type="password"
            autoCapitalize="off"
            autoComplete="off"
            spellCheck="false"
            autoCorrect="off"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errorMsg ? <div className="input-error">{errorMsg}</div> : null}

          <button
            className="proview-button login"
            type="submit"
            id="loginButton"
            disabled={disableLoginBtn}
          >
            Login
          </button>
        </form>
      </div>
      <ToastContainer transition={Slide} />
    </div>
  );
};

export default Login;
