import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import * as Auth from "../../authService";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    let isAuthenticated = Auth.loggedIn();
    return isAuthenticated ? (
      <Component {...props} {...{ location, navigate, params }} />
    ) : (
      <Navigate to={"/login"} replace={true} />
    );
  }
  return ComponentWithRouterProp;
}
export default withRouter;
