// framework imports - 1st party
import React from "react";

// app imports
import "../../stylesheets/Sort.css";

const HistorySort = (props) => {
  // change headers based on daily, weekly, or monthly
  const customDateRange = (e) => {
    switch (props.dateRange) {
      case "daily":
        return (
          <>
            <td className="text-left">Day</td>
            <td className="text-right">Date</td>
            <td className="text-right">Transactions</td>
          </>
        );
      case "weekly":
        return (
          <>
            <td className="text-left">Week</td>
            <td className="text-right">Transactions</td>
            <td className="text-right">Average Transactions</td>
          </>
        );
      case "monthly":
        return (
          <>
            <td className="text-left">Month</td>
            <td className="text-left">Year</td>
            <td className="text-left">Date</td>
            <td className="text-right">Transactions</td>
            <td className="text-right">Average Transactions</td>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <tr id="sort">
      {customDateRange()}
      <td className="text-right">Payment Amount</td>
      <td className="text-right">Fee 1</td>
      <td className="text-right">Fee 2</td>
      <td className="text-right">Fee 3</td>
      <td className="text-right">Average Payment</td>
    </tr>
  );
};

export default HistorySort;
