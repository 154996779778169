// framework imports - 1st party
import React, { useState } from "react";

// lib imports - 3rd party
import Modal from "react-modal";

const ChangePaidDateBeforeConfirm = (props) => {
  const [
    showChangePaidDateBeforeConfirmModal,
    setShowChangePaidDateBeforeConfirmModal,
  ] = useState(false);

  if (
    showChangePaidDateBeforeConfirmModal !==
    props.showChangePaidDateBeforeConfirmModal
  ) {
    setShowChangePaidDateBeforeConfirmModal(
      props.showChangePaidDateBeforeConfirmModal
    );
  }

  const handleChangePaidDateBeforeConfirmModal = () => {
    props.setShowChangePaidDateBeforeConfirmModal(false);
  };
  const handleCloseChangePaidDateBeforeConfirmModal = () => {
    props.setPayoutableDate("");
    props.setShowChangePaidDateBeforeConfirmModal(false);
  };

  return (
    <Modal
      isOpen={showChangePaidDateBeforeConfirmModal}
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => {
        props.setShowChangePaidDateBeforeConfirmModal(false);
      }}
      className="Modal cancel-confirm-approve"
      overlayClassName="Overlay"
      ariaHideApp={false}
    >
      <h3>
        Warning this will set the Paid date to before the transaction was
        created or approved. Do you wish to continue?
      </h3>
      <div>
        <button
          className="proview-button cancel left"
          onClick={handleCloseChangePaidDateBeforeConfirmModal}
        >
          No
        </button>
        <button
          className="proview-button right"
          onClick={handleChangePaidDateBeforeConfirmModal}
        >
          Yes
        </button>
      </div>
    </Modal>
  );
};

export default ChangePaidDateBeforeConfirm;
