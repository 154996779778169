import React, { useEffect, useState } from "react";
import Page from "../HelperComponents/Page";
import Search from "./RecurringSearch";
import RecurringSort from "./RecurringSort";
import * as api from "../../api";
import RecurringRow from "./RecurringRow";
import useDebounce from "../../useDebounce";
import moment from "moment";

const RecurringTable = (props) => {
  let recurringFilterObj = JSON.parse(
    sessionStorage.getItem("recurringFilter")
  );
  if (recurringFilterObj == null) {
    recurringFilterObj = {};
    recurringFilterObj["criteria"] = {};
    recurringFilterObj["pagination"] = {};
  }
  let criteria = recurringFilterObj["criteria"];
  let pagination = recurringFilterObj["pagination"];

  const [firstLoad, setFirstLoad] = useState(true); // first time for component loaded
  const [recurringPaymentId, setRecurringPaymentId] = useState(
    criteria.recurringPaymentId ? criteria.recurringPaymentId : ""
  ); // Ref field
  const [status, setStatus] = useState(criteria.status ? criteria.status : ""); // status field
  const [referenceId, setReferenceId] = useState(
    criteria.referenceId ? criteria.referenceId : ""
  ); // Ref field
  const [name] = useState(criteria.name ? criteria.name : ""); // name field
  const [payment, setPayment] = useState(
    criteria.payment ? criteria.payment : ""
  ); // payment field
  const [plc, setPlc] = useState(criteria.plc ? criteria.plc : []); // plc dropdown values
  const [pageSize, setPageSize] = useState(
    pagination.pageSize
      ? pagination.pageSize
      : {
          //page size
          value: 10,
          label: "10 Results/Page",
        }
  );
  const [pageNum, setPageNum] = useState(
    pagination.pageNum ? pagination.pageNum : 0
  ); // page num
  const [numElements, setNumElements] = useState(0); // number of elements on page
  const [totalElements, setTotalElements] = useState(0); // total elements on query
  const [setupDates, setSetupDates] = useState({
    start:
      criteria.setupDates && criteria.setupDates.start
        ? moment(criteria.setupDates.start)
        : moment().subtract(3, "month"),
    end:
      criteria.setupDates && criteria.setupDates.end
        ? moment(criteria.setupDates.end)
        : moment(),
  });
  const [intervalDate, setIntervalDate] = useState(
    criteria.intervalDate ? criteria.intervalDate : ""
  );
  const [transactions, setTransactions] = useState([]); // transaction info in rows
  const [sort, setSort] = useState(
    pagination.sort ? pagination.sort : { sortId: "created_at", asc: false }
  ); // current sort state
  const debounceName = useDebounce(name, 500); // debounce name input
  const debounceReferenceId = useDebounce(referenceId, 500); // debounce referenceID
  const debouncePayment = useDebounce(payment, 500); // debounce payment

  const getRecurring = (customPage = pageNum) => {
    let recurringFilter = {};
    recurringFilter["pagination"] = {};
    recurringFilter["pagination"].pageSize = pageSize;
    recurringFilter["pagination"].pageNum = pageNum;

    recurringFilter["criteria"] = {};
    recurringFilter["criteria"].recurringPaymentId = isNaN(recurringPaymentId)
      ? ""
      : recurringPaymentId;

    if (referenceId.length === 8 && !isNaN(referenceId)) {
      recurringFilter["criteria"].referenceId = referenceId;
    } else if (
      referenceId.length >= 10 &&
      referenceId.startsWith("TX_") &&
      !isNaN(referenceId.split("TX_").pop())
    ) {
      recurringFilter["criteria"].referenceId = referenceId;
    } else {
      recurringFilter["criteria"].referenceId = "";
    }

    recurringFilter["criteria"].plc = plc;
    recurringFilter["criteria"].setupDates = {
      start: setupDates.start ? setupDates.start.clone().toISOString() : null,
      end: setupDates.end ? setupDates.end.clone().toISOString() : null,
    };
    recurringFilter["criteria"].name = name;
    recurringFilter["criteria"].status = status;
    recurringFilter["criteria"].payment = payment;
    recurringFilter["criteria"].intervalDate = intervalDate;

    sessionStorage.setItem("recurringFilter", JSON.stringify(recurringFilter));
    return api.recurringSummary(
      recurringPaymentId,
      debounceReferenceId,
      plc,
      setupDates.start,
      setupDates.end,
      intervalDate,
      status,
      pageSize,
      customPage,
      debouncePayment
    );
  };

  // get and update the rows
  const retrieveInfo = () => {
    props.setSpinner(true);
    getRecurring().then((x) => {
      setTransactions(x.content);
      setTotalElements(x.totalElements);
      setNumElements(x.numberOfElements);
      props.setSpinner(false);
    });
  };

  /* If there's a change in search parameters, do a new fetch on page one */
  useEffect(() => {
    if (!firstLoad && pageNum !== 0) {
      setPageNum(0);
    } else if (
      referenceId.length < 10 &&
      referenceId.length !== 8 &&
      referenceId.length !== 0
    ) {
      // don't run query if reference is not right length
    } else if (referenceId.length >= 10 && !referenceId.startsWith("TX_")) {
      // don't run query
    } else if (
      referenceId.length >= 10 &&
      referenceId.startsWith("TX_") &&
      isNaN(referenceId.split("TX_").pop())
    ) {
      // don't run query
    } else if (referenceId.length === 8 && isNaN(referenceId)) {
      // don't run query
    } else {
      retrieveInfo();
    }
  }, [
    status,
    intervalDate,
    recurringPaymentId,
    debounceReferenceId,
    plc,
    setupDates,
    debounceName,
    debouncePayment,
  ]);

  /* No fetch on page change, NOT an input change */
  useEffect(() => {
    if (transactions.length !== 0) {
      retrieveInfo();
    }
  }, [pageNum, pageSize]);

  useEffect(() => {
    // set it to false after component is loaded first time.
    setFirstLoad(false);
  }, []);

  const getRows = () => {
    // if transaction undefined (error in retrieval)
    if (!transactions) {
      return (
        <tr>
          <td>
            <div id="no-results-found">Error in Search</div>
          </td>
        </tr>
      );
    }
    // if return some number of valid rows, display them normally
    else if (transactions.length > 0) {
      return transactions.map((transaction, i) => (
        <RecurringRow
          loading={props.loading}
          key={i}
          index={i}
          referenceId={transaction.transactionId}
          transaction={transaction}
          retrieveInfo={(e) => retrieveInfo()}
        />
      ));
    }
    // if the page is on initial load, load FAKE data and blur
    else if (props.loading && transactions.length === 0) {
      return Array.from(Array(10), (_, i) => (
        <tr key={i}>
          <td>
            <div id="no-results-found">Loading...</div>
          </td>
        </tr>
      ));
    }
    // otherwise, no results were found
    else {
      return (
        <tr>
          <td>
            <div id="no-results-found">No results found</div>
          </td>
        </tr>
      );
    }
  };

  return (
    <div className="content recurring-payment">
      <table>
        <tbody className="recurring-pay">
          <Search
            onRecurringPaymentId={(e) => setRecurringPaymentId(e)}
            recurringPaymentId={recurringPaymentId}
            setupDates={setupDates}
            setSetupDates={(e) => setSetupDates({ start: e.start, end: e.end })}
            onIntervalDate={(e) => setIntervalDate(e)}
            intervalDate={intervalDate}
            plc={plc}
            setPlc={(e) => setPlc(e)}
            onReferenceId={(e) => setReferenceId(e)}
            referenceId={referenceId}
            onPayment={(e) => setPayment(e)}
            payment={payment}
            onStatus={(e) => setStatus(e)}
            status={status}
            pageSize={pageSize}
            totalElements={totalElements}
          />
          <RecurringSort sort={sort} setSort={(e) => setSort(e)} />
          {getRows()}
        </tbody>
      </table>
      <Page
        pageSize={pageSize}
        onPageSize={(e) => {
          let firstData = pageNum * pageSize.value + 1;
          let newPageNum = Math.floor(firstData / e.value);
          setPageNum(newPageNum);
          setPageSize(e);
        }}
        pageNum={pageNum}
        onPageChange={(e) => setPageNum(e)}
        numElements={numElements}
        totalElements={totalElements}
        loading={props.loading}
        table="recurring"
      />
    </div>
  );
};

export default RecurringTable;
