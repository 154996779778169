// framework imports - 1st party
import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
// 3rd party imports
import Modal from "react-modal";

// app imports
import * as Auth from "../../authService";
import * as notify from "../../notify";
import "../../stylesheets/Login.css";
import "../../stylesheets/styles.css";
import "../../stylesheets/Header.css";
import "../../stylesheets/Modal.css";
import questionLogo from "../../icons/question.png";

// PasswordChange modal and logic
const ChangePassword = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [curpassword, setCurPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [validPassword, setValidPassword] = useState(true);

  // password validator regex
  const isPasswordStandard = () => {
    let rePassword8 =
      /^(?=.*\d)(?=.*[@#$%!^*()<>?/\\.,~_\-+=`])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    let rePassword12 =
      /^(?=.*\d)(?=.*[@#$%!^*()<>?/\\.,~_\-+=`])(?=.*[a-zA-Z]).{12,}$/;

    return (
      (newpassword.length >= 8 &&
        newpassword.length < 12 &&
        rePassword8.test(String(newpassword))) ||
      (newpassword.length >= 12 && rePassword12.test(String(newpassword)))
    );
  };

  const handleCloseModal = () => {
    props.setShowModal(false);
    notify.cancel("Change Password Cancelled");
  };

  if (showModal !== props.showModal) {
    setShowModal(props.showModal);
  }
  // determine if submission works
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      curpassword &&
      newpassword &&
      confirmpassword &&
      newpassword === confirmpassword &&
      curpassword !== newpassword &&
      isPasswordStandard()
    ) {
      setValidPassword(true);
      Auth.change(curpassword, newpassword, confirmpassword)
        .then((res) => {
          if (res.status === 400) {
            // add input error message
            setValidPassword(false);
            notify.error("New Password is not valid");
          } else if (res.status === 401) {
            notify.error("Current Password Not Match");
          } else {
            notify.success("Change Password Success");
            props.setShowModal(false);
          }
        })
        .catch((err) => {
          notify.error("Change Password False");
        });
    } else if (!curpassword && !newpassword && !confirmpassword) {
      notify.error(
        "Please enter a Current Password, New Password and Confirm New Password"
      );
    } else if (!curpassword) {
      notify.error("Please enter a Current Password");
    } else if (!newpassword) {
      notify.error("Please enter a New Password");
    } else if (!confirmpassword) {
      notify.error("Please enter a Confirm New Password");
    } else if (curpassword === newpassword) {
      notify.error("New Password must not be the same as Current Password");
    } else if (!isPasswordStandard()) {
      notify.error("New Password is not valid");
    }
  };
  return (
    <Modal
      isOpen={props.showModal}
      shouldCloseOnOverlayClick={true} // that's it!
      onRequestClose={() => {
        props.setShowModal(false);
      }}
      className="Modal change-password"
      overlayClassName="Overlay"
      ariaHideApp={false}
    >
      <h3>Change Password</h3>
      <form>
        <div className="report-form resend">
          <label htmlFor="cur-password">Current Password</label>
          <input
            id="cur-password"
            className={"proview-input"}
            autoComplete="off"
            type="password"
            placeholder="Current Password"
            value={curpassword}
            onChange={(e) => setCurPassword(e.target.value)}
          />
        </div>
        <div className="report-form resend">
          <label htmlFor="new-password">New Password</label>
          <img
            data-tip
            data-for="password-tip"
            className={"img-question"}
            alt="Govpay Logo"
            src={questionLogo}
            id="change-password-question-icon"
          />
          <input
            className={validPassword ? "proview-input" : "proview-input error"}
            id="new-password"
            type="password"
            autoComplete="off"
            placeholder="New Password"
            value={newpassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="report-form resend">
          <label htmlFor="confirm-password">Confirm New Password</label>
          <input
            className={"proview-input"}
            id="confirm-password"
            type="password"
            autoComplete="off"
            placeholder="Confirm New Password"
            value={confirmpassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div className="input-validate-password">
            {newpassword !== confirmpassword ? (
              <div className="input-error change">
                New Password and Confirm New Password Not Match.
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <button
            className="proview-button cancel left"
            onClick={handleCloseModal}
            type="button"
          >
            Cancel
          </button>
          <button
            className="proview-button right"
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            Change Password
          </button>
        </div>
      </form>
      <ReactTooltip id="password-tip" place="right" effect="solid">
        The password must meet the following criterias:
        <br />
        <p className="tab">
          - length at least 8 characters <br />
          - must contains one digit from 0-9 <br />
          - must contains one lowercase character <br />
          - must contains one uppercase character <br />- must contains one
          special symbols in the list '@#$%'
        </p>
      </ReactTooltip>
    </Modal>
  );
};

export default ChangePassword;
