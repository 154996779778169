// framework imports - 1st party
import React from "react";

// spinner with potential custom class
// for formatting on different pages and mobile
const Spinner = (props) => {
  const mainClass = props.customClass ? props.customClass : "sk-fading-circle";
  if (props.loading) {
    return (
      <div className={mainClass}>
        <div className="sk-circle1 sk-circle" />
        <div className="sk-circle2 sk-circle" />
        <div className="sk-circle3 sk-circle" />
        <div className="sk-circle4 sk-circle" />
        <div className="sk-circle5 sk-circle" />
        <div className="sk-circle6 sk-circle" />
        <div className="sk-circle7 sk-circle" />
        <div className="sk-circle8 sk-circle" />
        <div className="sk-circle9 sk-circle" />
        <div className="sk-circle10 sk-circle" />
        <div className="sk-circle11 sk-circle" />
        <div className="sk-circle12 sk-circle" />
      </div>
    );
  } else {
    return null;
  }
};
export default Spinner;
