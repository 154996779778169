// framework imports - 1st party
import React from "react";

// lib imports - 3rd party
import { components } from "react-select";
import AsyncSelect from "react-select/async";

// app imports
import * as api from "../../api";
import "../../stylesheets/PlcSelect.css";

// PLC select dropdown that makes an API call on search
const PlcSelect = (props) => {
  const filterPlcs = (inputValue, numResults) => {
    return api.getPLCs(inputValue, props.fraud).then((x) =>
      x
        .map((p) => ({
          value: props.fraud ? p.plc.plcId : p.plcId,
          name: props.fraud ? p.plc.name : p.name,
          Plc: props.fraud ? p.plc.plcId : p.plcId,
          accountType: props.fraud ? p.plc.accountType : null,
          agencyApiFraudPlcId: props.fraud ? p.id : null,
          fieldId0: props.fraud && p.field0 ? p.field0.fieldId : null,
          fieldId0Name: props.fraud && p.field0 ? p.field0.name : null,
          fieldId1: props.fraud && p.field1 ? p.field1.fieldId : null,
          fieldId1Name: props.fraud && p.field1 ? p.field1.name : null,
        }))
        .slice(0, numResults)
    );
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterPlcs(inputValue, props.numResults));
      }, 100);
    });

  return (
    <AsyncSelect
      isMulti={props.isMulti}
      isClearable={true}
      cacheOptions
      defaultOptions
      closeOnSelect={!props.isMulti}
      closeMenuOnSelect={!props.isMulti}
      onSelectResetsInput={!props.isMulti}
      id="plc-select"
      getOptionLabel={(option) => option.Plc + " - " + option.name}
      loadOptions={promiseOptions}
      className={props.class}
      classNamePrefix="select"
      placeholder="PLC #"
      components={{ MultiValue }}
      onChange={(e) => props.setPlc(e)}
      isSearchable={!props.fraud}
      value={props.plc}
    />
  );
};
const MultiValue = (props) => (
  <components.MultiValue {...props}>{props.data.Plc}</components.MultiValue>
);

export default PlcSelect;
