// framework imports - 1st party
import React from "react";

// app imports
import "../../stylesheets/Sort.css";

const ExclusionsSort = (props) => {
  return (
    <tr id="sort">
      <td className="text-left sorting" />
      <td className="text-left sorting">Case Number</td>
      <td className="text-left sorting">First Name</td>
      <td className="text-left sorting">Last Name</td>
    </tr>
  );
};

export default ExclusionsSort;
