// framework imports - 1st party
import React, { useState, useEffect } from "react";

// lib imports - 3rd party
import Select from "react-select";

// app imports
import "../../stylesheets/Page.css";
import { ReactComponent as PageLeft } from "../../icons/page-left.svg";
import { ReactComponent as CaretLeft } from "../../icons/caret-left.svg";
import { ReactComponent as PageRight } from "../../icons/page-right.svg";
import { ReactComponent as CaretRight } from "../../icons/caret-right.svg";

// Generic paging component
const Page = (props) => {
  const options = [
    { value: 10, label: "10 Results/Page" },
    { value: 20, label: "20 Results/Page" },
    { value: 50, label: "50 Results/Page" },
  ];
  const optionsQb = [
    { value: 5, label: "5 Results/Page" },
    { value: 10, label: "10 Results/Page" },
    { value: 15, label: "15 Results/Page" },
  ];
  /*const optionsQb = [{ value: 10, label: "10 Results/Page" }];*/
  const [firstPage, setFirstPage] = useState(false);
  const [lastPage, setLastPage] = useState(false);
  const [disable, setDisable] = useState(false);

  // if pagenum or total elements changes,
  // recalculate values to display in pages
  useEffect(() => {
    setFirstPage(props.pageNum === 0);
    setLastPage(
      props.totalElements - props.pageNum * props.pageSize.value <=
        props.pageSize.value
    );
  }, [props.pageNum, props.totalElements, props.pageSize]);

  // check if calculated values
  // go passed total amount of elements
  // (necessary while loading last page)
  const upperLimit = () => {
    const displayRange =
      props.pageNum * props.pageSize.value + props.numElements;
    if (displayRange > props.totalElements) {
      return props.totalElements;
    } else {
      return displayRange;
    }
  };

  // if last page row count === props.pageSize.value,
  // subtract 1 from total elements / pageSize
  // to get the correct last page
  const handleLastPage = () => {
    if (!lastPage && !props.loading) {
      const offSet = Number.isInteger(
        props.totalElements / props.pageSize.value
      );
      props.onPageChange(
        Math.floor(props.totalElements / props.pageSize.value) - +offSet
      );
    }
  };

  // generic paging componet, used on several pages
  // page buttons are responsive
  // and determine which page you are on
  // before allowing a click event to go through
  return (
    <div id="page">
      <Select
        id="set-page"
        options={props.qbModal ? optionsQb : options}
        className="proview-select"
        classNamePrefix="select"
        isClearable={false}
        isSearchable={false}
        onChange={(e) => props.onPageSize(e)}
        value={props.pageSize}
      />
      <div className="bottom display">
        Displaying{" "}
        {props.pageNum * props.pageSize.value +
          (props.totalElements ? 1 : 0) +
          " - " +
          upperLimit()}{" "}
        of {props.totalElements}
      </div>
      <div className="bottom page-text">
        <label
          htmlFor={
            /* for accessibility */
            props.table === "summary"
              ? "react-select-3-input"
              : "react-select-4-input"
          }
        >
          Show{" "}
        </label>
      </div>
      <div className="bottom nav">
        <button
          className="page-button"
          aria-label="select first page"
          onClick={() => {
            if (!firstPage && !props.loading) props.onPageChange(0);
          }}
        >
          <PageLeft key="first" />
        </button>
        <button
          disabled={disable}
          className="page-button"
          aria-label="select previous page"
          onClick={() => {
            setDisable(true);
            if (!firstPage && !props.loading)
              props.onPageChange(props.pageNum - 1);
            setTimeout(() => {
              setDisable(false);
            }, 3000);
          }}
        >
          <CaretLeft key="prev" />
        </button>
        <button
          disabled={disable}
          className="page-button"
          aria-label="select next page"
          onClick={() => {
            setDisable(true);
            if (!lastPage && !props.loading)
              props.onPageChange(props.pageNum + 1);
            setTimeout(() => {
              setDisable(false);
            }, 3000);
          }}
        >
          <CaretRight key="next" />
        </button>
        <button
          className="page-button"
          aria-label="select last page"
          onClick={(e) => handleLastPage()}
        >
          <PageRight key="last" />
        </button>
      </div>
    </div>
  );
};
export default Page;
