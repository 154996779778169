// framework imports - 1st party
import React, { useEffect, useState } from "react";

// lib imports - 3rd party
import { ToastContainer, Slide } from "react-toastify";

// app imports
import * as Auth from "../authService";
import * as api from "../api";
import GenerateAuth from "./Authorization/GenerateAuth";
import AuthTable from "./Authorization/AuthTable";
import Spinner from "./HelperComponents/Spinner";
import reportsAndStatements from "../icons/ic-reports-statements@3x.png";
import "../stylesheets/PageBody.css";

// Payment Authorization page
const PaymentAuthorization = (props) => {
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState({
    start: null,
    end: null,
  });
  const [paymentType, setPaymentType] = useState({
    value: "EQUALS",
    label: "Equals",
  });
  const [submitted, setSubmitted] = useState(false);
  const [refId, setRefId] = useState("");
  const [cardNum, setCardNum] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [authGreater, setAuthGreater] = useState("");
  const [authLess, setAuthLess] = useState("");
  const [authAmount, setAuthAmount] = useState("");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState({
    value: 10,
    label: "10 Results/Page",
  });
  const [pageNum, setPageNum] = useState(0);
  const [numElements, setNumElements] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  // if don't have transaction manifest privilege, redirect
  if (!Auth.canTransactionManifest()) {
    props.navigate("/summary");
  }

  // if small screensize (i.e. on mobile)
  // redirect to summary
  // because this page is not written
  // to be mobile responsive
  const handleResize = (e) => {
    if (window.innerWidth < 600) {
      props.navigate("/summary");
    }
  };
  // listener for resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // based on which dates are filled in,
  // determine what to have for API call
  const handleDateType = () => {
    if (dates.start === dates.end) {
      return { type: "ON", first: dates.start, second: null };
    } else if (dates.start && dates.end) {
      return { type: "BETWEEN", first: dates.start, second: dates.end };
    } else if (dates.start) {
      return { type: "AFTER", first: dates.start, second: null };
    } else if (dates.end) {
      return { type: "BEFORE", first: dates.end, second: null };
    } else {
      return { type: null, first: null, second: null };
    }
  };

  // on "Generate Report" click
  const handleSubmit = (e) => {
    setLoading(true);
    const computeDate = handleDateType();

    // construct payload
    const paymentPayload = {
      filterTransactionId: refId.startsWith("TX_") ? "" : refId,
      filterApdTransactionId: refId.startsWith("TX_") ? refId : "",
      filterCardLastFour: cardNum,
      filterAuthCode: authCode,
      filterFirstName: firstName,
      filterLastName: lastName,
      filterAuthAmountComparable: paymentType.value,
      filterAuthAmount1:
        paymentType.value === "BETWEEN" ? authGreater : authAmount,
      filterAuthAmount2: authLess,
      filterAuthDateComparable: computeDate.type,
      filterAuthDate1: computeDate.first,
      filterAuthDate2: computeDate.second,
    };

    // make request and set up results
    api.paymentAuth(paymentPayload).then((x) => {
      setPageNum(0);
      setRows(x.data ? x.data.content : []);
      setLoading(false);
      setTotalElements(x.data ? x.data.totalElements : 0);
      setNumElements(pageSize.value);
      setSubmitted(true);
    });
  };

  return (
    <main className="main-background">
      <div className="main-component">
        <div id="table-header">
          <div id="details-label">
            <img
              alt="Govpay Logo"
              src={reportsAndStatements}
              id="reports-and-statements-icon"
            />
            <div id="details-label-name">Payment Authorization</div>
          </div>
          <Spinner customClass={"sk-fading-circle summary"} loading={loading} />
        </div>
        <div>
          <GenerateAuth
            dates={dates}
            setDates={(e) => setDates({ start: e.start, end: e.end })}
            onPaymentType={(e) => setPaymentType(e)}
            paymentType={paymentType}
            refId={refId}
            setRefId={(e) => setRefId(e)}
            cardNum={cardNum}
            setCardNum={(e) => setCardNum(e)}
            firstName={firstName}
            setFirstName={(e) => setFirstName(e)}
            lastName={lastName}
            setLastName={(e) => setLastName(e)}
            authCode={authCode}
            setAuthCode={(e) => setAuthCode(e)}
            authGreater={authGreater}
            setAuthGreater={(e) => setAuthGreater(e)}
            authLess={authLess}
            setAuthLess={(e) => setAuthLess(e)}
            authAmount={authAmount}
            setAuthAmount={(e) => setAuthAmount(e)}
            submitted={(e) => handleSubmit(e)}
          />
        </div>
        {submitted ? (
          <AuthTable
            setSpinner={(e) => setLoading(e)}
            loading={loading}
            rows={rows}
            pageSize={pageSize}
            setPageSize={(e) => {
              setPageSize(e);
            }}
            pageNum={pageNum}
            setPageNum={(e) => setPageNum(e)}
            numElements={numElements}
            setNumElements={(e) => setNumElements(e)}
            totalElements={totalElements}
          />
        ) : null}
      </div>
      <ToastContainer transition={Slide} />
    </main>
  );
};

export default PaymentAuthorization;
