// framework imports - 1st party
import React, { useEffect } from "react";

// lib imports - 3rd party
import { ToastContainer, Slide } from "react-toastify";

// app imports
import GenerateRecon from "./Recon/GenerateRecon";
import reportsAndStatements from "../icons/ic-reports-statements@3x.png";
import "../stylesheets/PageBody.css";

// Recon/Payment Reports screen
const ReconPaymentReports = (props) => {
  // if small screensize (i.e. on mobile)
  // redirect to summary
  // because this page is not written
  // to be mobile responsive
  const handleResize = (e) => {
    if (window.innerWidth < 600) {
      props.navigate("/summary");
    }
  };
  // listener for resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main className="main-background">
      <div className="main-component">
        <div id="table-header">
          <div id="details-label">
            <img
              alt="Govpay Logo"
              src={reportsAndStatements}
              id="reports-and-statements-icon"
            />
            <div id="details-label-name">Recon/Payment Reports</div>
          </div>
        </div>
        <div>
          <GenerateRecon />
        </div>
      </div>
      <ToastContainer transition={Slide} />
    </main>
  );
};

export default ReconPaymentReports;
