// framework imports - 1st party
import React, { useState, useEffect } from "react";

// lib imports - 3rd party
import Modal from "react-modal";
import ESAPI from "node-esapi";

// app imports
import EditIcon from "./EditIcon";
import edit from "../../icons/edit.svg";
import * as notify from "../../notify";
import * as api from "../../api";
import * as Auth from "../../authService";
import Spinner from "../HelperComponents/Spinner";
import "../../stylesheets/spinner.css";

const PaymentInformation = (props) => {
  const [paymentInfo, setPaymentInfo] = useState({ charges: [] });
  const [paymentDisabled, setPaymentDisabled] = useState(true);
  const [paymentOverride, setPaymentOverride] = useState(true);
  const [invalidFee, setInvalidFee] = useState(false);
  const [invalidService, setInvalidService] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [serviceFeeOverride, setServiceFeeOverride] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");
  const [oldServiceFee, setOldServiceFee] = useState("");
  const [calcServiceFee, setCalcServiceFee] = useState("");

  //onclick, open reason modal
  const handleOpenModal = () => {
    console.log(
      ESAPI.encoder().encodeForHTML(
        " handleOpenModal Method.  Old Service Fee: " + oldServiceFee
      )
    );
    setShowModal(true);
  };

  const handleOpenReasonModal = () => {
    setShowModal(false);
    setShowReasonModal(true);
  };

  const handleCloseModalCancel = () => {
    paymentInfo.reason = "";
    setReason("");
    setShowModal(false);
    setServiceFeeOverride(false);
    setShowReasonModal(false);
    setLoading(false);
    retrieveInfo();
    setPaymentDisabled(true);
    setPaymentOverride(true);
    setInvalidFee(false);
    setInvalidService(false);
  };

  // Dynamically genereate each charge
  // since their can be multiple
  const generateCharges = (object, setObject, isDisabled) => {
    return object.charges.map((charge, i) => (
      <tr key={charge.chargeId}>
        <td>
          <label htmlFor={charge.chargeName}>{charge.chargeName}</label>
        </td>
        <td>
          {isDisabled ? (
            <strong>
              {isDisabled ? "$" + charge.chargeAmount : charge.chargeAmount}
            </strong>
          ) : (
            <input
              className={
                invalidFee
                  ? "change-information short error"
                  : "change-information short"
              }
              id={charge.chargeName}
              value={
                isDisabled
                  ? "$" + charge.chargeAmount
                  : charge.chargeAmount.match(/^\d+\.?\d{0,2}/)
              }
              onChange={(e) => {
                if (e.target.value === "" || !isNaN(e.target.value)) {
                  const customCopy = object;
                  customCopy.charges[i].chargeAmount = e.target.value;
                  setObject({ ...object, customCopy });
                }
              }}
              disabled={isDisabled}
              placeholder="0.00"
            />
          )}
        </td>
      </tr>
    ));
  };

  // Stores payload in state
  const retrieveInfo = () => {
    //Clearing the reason text for new transaction
    paymentInfo.reason = "";
    setReason("");
    api.getTransaction(props.referenceId, "paymentInfo").then((x) => {
      if (x) {
        const all = x;
        // modify changes in payload to be rounded to 2 decimal places
        all.charges = x.charges.map((x) => {
          x.chargeAmount = x.chargeAmount.toFixed(2);
          return x;
        });
        setPaymentInfo(all);
      }
    });
  };

  // set logic for
  // payment override and edit
  const paymentEdit = (e) => {
    e.preventDefault();
    if (props.paymentState.preCapture) {
      notify.error("Change not allowed: too close to payment release time.");
    } /*else if (!props.paymentState.captured) {
      notify.error("Change not allowed: Payment not captured.");
    } */ else if (!props.paymentState.litle) {
      notify.error("Change not allowed.");
    } else {
      if (!paymentDisabled) {
        //handleSubmit();
        if (serviceFeeOverride) {
          handleServiceFeeCalc();
        } else {
          handleServiceFeeCalcUpdate();
        }
      } else {
        setPaymentDisabled(!paymentDisabled);
        if (!paymentOverride) {
          setPaymentOverride(!paymentOverride);
        }
      }
    }
  };

  // disabled editing
  // if on small screen
  const handleResize = (e) => {
    if (window.innerWidth < 600) {
      setPaymentDisabled(true);
      setPaymentOverride(true);
    }
  };

  // listen for screen resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    retrieveInfo();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // on submit,
  // check for success,
  // and update payment info
  const handleSubmit = () => {
    paymentInfo.reason = reason;
    //If Service Fee was not touched, override the fee with calculated service fee from payment processor
    if (paymentInfo.gpsFee === oldServiceFee) {
      paymentInfo.gpsFee = calcServiceFee;
    }
    //If reason for updating payment amount is blank, cancel update and provide error message
    if (!reason) {
      setShowReasonModal(false);
      notify.error(
        "You must enter a reason when updating payment information.  Please try again."
      );
      handleCloseModalCancel();
    } else {
      setShowReasonModal(false);
      setLoading(true);
      setShowModal(false);
      api
        .transactionUpdate(props.referenceId, "paymentInfo", paymentInfo)
        .then((x) => {
          if (x.status !== 200) {
            if (x.status === 404) {
              setLoading(false);
              notify.error("Invalid input for Payment Information");
            } else {
              setLoading(false);
              notify.error(
                "Payment Information failed to update: " + x.data.message
              );
              retrieveInfo();
              setServiceFeeOverride(false);
            }
            setInvalidFee(true);
            if (setPaymentOverride) {
              setInvalidService(true);
            }
          } else {
            setLoading(false);
            notify.success("Payment Information updated.");
            setPaymentDisabled(true);
            setPaymentOverride(true);
            setInvalidFee(false);
            setInvalidService(false);
            retrieveInfo();
          }
        });
    }
  };

  //Handle Service Fee Calculation
  const handleServiceFeeCalc = () => {
    //Need to convert to decimal when user enters non-decimal value on service fee field for fee calculation call.
    paymentInfo.gpsFee = convertToDecimal(paymentInfo.gpsFee);
    setLoading(true);
    api
      .transactionServiceFee(
        props.referenceId,
        "transactionPlc",
        "calculateServiceFee",
        paymentInfo
      )
      .then((x) => {
        if (x.status !== 200) {
          if (x.status === 404) {
            setLoading(false);
            notify.error("Invalid input for Service Fee");
          } else {
            setLoading(false);
            notify.error("Service Fee failed to update: " + x.data.message);
          }
          setInvalidFee(true);
          if (setPaymentOverride) {
            setInvalidService(true);
          }
        } else {
          //Setting calcServiceFee to handle Service Fee override
          setCalcServiceFee(x.data.gpsFee);
          setPaymentInfo({
            ...paymentInfo,
            displayGpsFee: x.data.displayGpsFee,
          });

          setPaymentDisabled(true);
          setPaymentOverride(true);
          setInvalidFee(false);
          setInvalidService(false);

          handleOpenModal();
        }
      });
  };

  //Handle Service Fee Calculation
  const handleServiceFeeCalcUpdate = () => {
    //Need to convert to decimal when user enters non-decimal value on service fee field for fee calculation call.
    paymentInfo.gpsFee = convertToDecimal(paymentInfo.gpsFee);
    setLoading(true);
    api
      .transactionServiceFee(
        props.referenceId,
        "transactionPlc",
        "calculateServiceFee",
        paymentInfo
      )
      .then((x) => {
        if (x.status !== 200) {
          if (x.status === 404) {
            setLoading(false);
            notify.error("Invalid input for Service Fee");
          } else {
            setLoading(false);
            notify.error("Service Fee failed to update: " + x.data.message);
          }
          setInvalidFee(true);
          if (setPaymentOverride) {
            setInvalidService(true);
          }
        } else {
          //Setting calcServiceFee to handle Service Fee override
          setCalcServiceFee(x.data.gpsFee);

          setPaymentDisabled(true);
          setPaymentOverride(true);
          setInvalidFee(false);
          setInvalidService(false);

          setPaymentInfo({
            ...paymentInfo,
            displayGpsFee: x.data.displayGpsFee,
          });
          setPaymentInfo({ ...paymentInfo, gpsFee: x.data.gpsFee });

          setShowReasonModal(true);
        }
      });
  };

  // on cancel, reset editing
  // and refetch info
  const handleCancel = (e) => {
    notify.cancel("Payment Information update cancelled");
    retrieveInfo();
    setPaymentDisabled(true);
    setPaymentOverride(true);
    setInvalidFee(false);
    setInvalidService(false);
  };
  const handleOverride = (e) => {
    setPaymentOverride(false);
    setServiceFeeOverride(true);
    setOldServiceFee(paymentInfo.gpsFee);
  };
  // Function to handle non-decimal entries for service fee override
  function convertToDecimal(num) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < 3) {
      // Set the number to two decimal places
      value = value.toFixed(2);
    }
    // Return updated or original number.
    return value;
  }

  return (
    <table className="description-table">
      <caption>
        <div className="table-title">PAYMENT INFORMATION</div>
        <Modal
          isOpen={showReasonModal}
          shouldCloseOnOverlayClick={true} // that's it!
          onRequestClose={() => {
            props.setShowModal(false);
          }}
          className="Modal deny"
          overlayClassName="Overlay deny"
          ariaHideApp={false}
        >
          <h3>Reason</h3>
          <textarea
            className="proview-input details"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
          <div>
            <button
              className="proview-button cancel left"
              onClick={handleCloseModalCancel}
            >
              Cancel
            </button>
            <button className="proview-button right" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={showModal}
          shouldCloseOnOverlayClick={true} // that's it!
          onRequestClose={() => {
            props.setShowModal(false);
          }}
          className="Modal deny"
          overlayClassName="Overlay deny"
          ariaHideApp={false}
        >
          <h3>Service Fee Override</h3>
          <div>
            You have provided a service fee of ${paymentInfo.gpsFee} but the
            calculated fee for this amount is {paymentInfo.displayGpsFee}. Are
            you sure?
          </div>
          <div>
            <button
              className="proview-button cancel left"
              onClick={handleCloseModalCancel}
            >
              Cancel
            </button>
            <button
              className="proview-button right"
              onClick={handleOpenReasonModal}
            >
              OK
            </button>
          </div>
        </Modal>
        <div>
          <Spinner customClass={"sk-fading-circle summary"} loading={loading} />
        </div>
        {props.notEditable ? null : (
          <EditIcon
            name={"paymentEdit"}
            canEdit={
              Auth.canFinanceOverride() ||
              Auth.canAdmin() ||
              (Auth.canAmountModify() && props.status !== "PAID")
            }
            disabled={paymentDisabled}
            handleEdit={paymentEdit}
            handleCancel={handleCancel}
          />
        )}
      </caption>
      <tbody>
        <tr>
          <td>Approval #</td>

          <td>
            <strong>{paymentInfo.authCode}</strong>
          </td>
        </tr>
        {generateCharges(paymentInfo, setPaymentInfo, paymentDisabled)}

        <tr>
          <td>
            <label htmlFor="service-fee">Service Fee</label>
          </td>
          <td>
            {paymentOverride ? (
              <strong>
                {paymentOverride
                  ? "$" + paymentInfo.gpsFee
                  : paymentInfo.gpsFee}
              </strong>
            ) : (
              <input
                id="service-fee"
                className={
                  invalidService
                    ? "change-information short error"
                    : "change-information short"
                }
                value={
                  paymentOverride
                    ? "$" + paymentInfo.gpsFee
                    : paymentInfo.gpsFee
                }
                onChange={(e) =>
                  e.target.value === "" || !isNaN(e.target.value)
                    ? setPaymentInfo({ ...paymentInfo, gpsFee: e.target.value })
                    : null
                }
                disabled={paymentOverride}
              />
            )}
            <span>
              <button
                className={
                  Auth.canAdmin() ||
                  Auth.canFinanceOverride() ||
                  Auth.canServiceFeeOverride()
                    ? paymentDisabled || !paymentOverride
                      ? "hide"
                      : "edit-button override hoverable hide-mobile"
                    : "hide"
                }
                type="button"
                onClick={handleOverride}
              >
                <img className="edit-icon" alt="Edit Icon" src={edit} />
                <span className="edit-text">{"override"}</span>
              </button>
            </span>
          </td>
        </tr>
        {/* <tr>
            <td />
            <td>Please enter less fee amounts than the total.</td>
          </tr> */}
        <tr>
          <td />
        </tr>
        <tr>
          <td>Total Amount</td>
          <td>
            <strong>{paymentInfo.displayTotalAmountPlusGpsFee}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PaymentInformation;
