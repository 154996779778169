// framework imports - 1st party
import React, { useState } from "react";

// lib imports - 3rd party
import Modal from "react-modal";

// app imports
import * as api from "../../api";
import Spinner from "../HelperComponents/Spinner";
import "../../stylesheets/ExportCsv.css";

// file handling exporting to CSV
// including the modal and the API call
const ExportCsv = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(true);
  const [allPages, setAllPages] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fixPageSize] = useState({
    value: 5000,
  });

  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const summaryExport = () => {
    api
      .transactionSummary(
        props.debounceReferenceId,
        props.plc,
        props.dates.start,
        props.dates.end,
        props.payoutDates.start,
        props.payoutDates.end,
        props.status,
        props.syncStatus,
        props.debounceUuid,
        props.recurringPaymentId,
        props.totalElements > fixPageSize.value && currentPage === false
          ? fixPageSize
          : props.pageSize,
        props.customPage,
        props.sort,
        props.debounceName,
        props.debouncePayment,
        true,
        props.totalElements > fixPageSize.value && currentPage === false
          ? false
          : !currentPage
      )
      .then((res) => {
        setIsLoading(false);
        setShowModal(false);
      });
  };

  const manifestExport = () => {
    api
      .transactionManifest(
        props.debounceReferenceId,
        props.plc,
        props.bail,
        props.dates.start,
        props.dates.end,
        props.status,
        props.billingName,
        props.debounceUuid,
        props.totalElements > fixPageSize.value && currentPage === false
          ? fixPageSize
          : props.pageSize,
        props.customPage,
        props.sort,
        props.debouncePayment,
        true,
        props.totalElements > fixPageSize.value && currentPage === false
          ? false
          : !currentPage
      )
      .then((res) => {
        setIsLoading(false);
        setShowModal(false);
      });
  };

  // make transaction sum call
  // for submittion
  // setting EXPORT value to true
  const handleSubmit = () => {
    setIsLoading(true);
    if (props.page === "summary") {
      summaryExport();
    } else if (props.page === "manifest") {
      manifestExport();
    }
  };

  const handleAllSelect = () => {
    setAllPages(true);
    setCurrentPage(false);
  };

  const handlePageSelect = () => {
    setCurrentPage(true);
    setAllPages(false);
  };

  return (
    <div className="export-csv hide-mobile">
      <button className="download-link" onClick={handleOpenModal}>
        Download CSV
      </button>
      <Modal
        isOpen={showModal}
        shouldCloseOnOverlayClick={true} // that's it!
        onRequestClose={() => {
          setShowModal(false);
        }}
        className="Modal export"
        overlayClassName="Overlay export"
        ariaHideApp={false}
      >
        <h3>
          Download CSV
          <Spinner
            customClass={"sk-fading-circle export"}
            loading={isLoading}
          />
        </h3>
        <div>
          <input
            type="radio"
            id="current"
            name="export-view"
            value="current"
            checked={currentPage}
            onChange={handlePageSelect}
            disabled={isLoading}
          />
          <label htmlFor="current">
            {"Current View (" + props.numElements + " records)"}
          </label>
        </div>
        <div>
          <input
            type="radio"
            id="all"
            name="export-view"
            value="all"
            checked={allPages}
            onChange={handleAllSelect}
            disabled={isLoading}
          />
          <label htmlFor="all">
            {"Full View (" + props.totalElements + " Records)"}
          </label>
        </div>
        <div>
          <button
            className="proview-button cancel left"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="proview-button right"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Download
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ExportCsv;
