// framework imports - 1st party
import React from "react";

// lib imports - 3rd party
import Select from "react-select";

// app imports
import "../../stylesheets/GenerateReport.css";
import PlcSelect from "../HelperComponents/PlcSelect";
import ReactDates from "../HelperComponents/ReactDates";
import * as notify from "../../notify";

const GenerateHistory = (props) => {
  // fill in values for dropdown
  const groupTransactions = [
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
  ];
  const transactionType = [
    { value: "ALL", label: "All Transaction" },
    { value: "IVR", label: "Call Center Only" },
    { value: "WEB", label: "Web Only" },
    { value: "WS", label: "WebService Only" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.canGenerate) {
      notify.error("Please select Date Range");
    } else {
      props.submitted(e);
    }
  };
  return (
    <div className="generate-report">
      <form name="message" onSubmit={(e) => handleSubmit(e)}>
        <div className="report-form select">
          <label htmlFor="react-select-2-input">PLC ID</label>
          <PlcSelect
            id="plc-id"
            name="plc-id"
            class="plc-select history-form"
            setPlc={(e) => props.setPlc(e)}
            isMulti={true}
            numResults={500}
            stayOpen={false}
          />
        </div>

        <div className="report-form">
          <label htmlFor="date-range">Date</label>
          <ReactDates
            id="date-range"
            startId="start-search-page"
            endId="end-search-page"
            name="date-range"
            dates={props.dates}
            setDates={(e) => props.setDates(e)}
          />
        </div>

        <div className="report-form select">
          <label htmlFor="group-transactions">Group Transactions</label>
          <Select
            id="history-search-group"
            defaultValue={groupTransactions[0]}
            options={groupTransactions}
            className="proview-select"
            classNamePrefix="select"
            onChange={(e) => props.onGroupTransaction(e)}
            value={props.groupTransaction}
            name="group-transactions"
          />
        </div>
        <div className="report-form select">
          <label htmlFor="react-select-3-input">Transaction Type</label>
          <Select
            id="history-search-type"
            defaultValue={transactionType[0]}
            options={transactionType}
            className="proview-select bigger"
            classNamePrefix="select"
            onChange={(e) => props.onTransactionType(e)}
            value={props.transactionType}
            name="group-transactions"
          />
        </div>
        <button className="proview-button report" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default GenerateHistory;
