// framework imports - 1st party
import React, { useEffect, useState } from "react";

// lib imports - 3rd party
import Select from "react-select";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";

// app imports
import * as api from "../../api";
import * as notify from "../../notify";

import PlcSelect from "../HelperComponents/PlcSelect";
import "../../stylesheets/ReactDates.css";
import "../../stylesheets/GenerateReport.css";
import "../../stylesheets/ExclusionsMain.css";

const GenerateRecon = (props) => {
  const [plc, setPlc] = useState();
  const [date, setDate] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [paymentType, setPaymentType] = useState({
    value: "Payment",
    label: "Payment",
  });

  const [focused, setFocused] = useState(null);
  const paymentTypes = [
    { value: "Reconciliation", label: "Reconciliation" },
    { value: "Payment", label: "Payment" },
  ];

  const handleResendReport = (e) => {
    e.preventDefault();
    if (!submitDisabled) {
      notify.error("Please select all values");
    } else {
      const type = paymentType.value === "Payment" ? "statements" : "recons";
      // only allow reque on the weekend
      if (
        (moment(date).format("ddd") === "Sat" ||
          moment(date).format("ddd") === "Sun") &&
        type === "statements"
      ) {
        notify.error("Cannot reque on a weekend date");
      } else {
        api.resendReport(plc.value, date, type).then((x) => {
          if (x.status === 200 || x.status === 201) {
            notify.success(x.data);
          } else {
            notify.error("Failed to reque recon report");
          }
        });
      }
    }
  };

  const handleGenerateReport = (e) => {
    e.preventDefault();
    if (!submitDisabled) {
      notify.error("Please select all values");
    } else {
      const type = paymentType.value === "Payment" ? "statements" : "recons";
      api.generateReport(plc.value, date, type).then((x) => {
        if (x.status === 400) {
          notify.error(x.data.message);
        } else if (x.status === 200) {
          if (type === "statements") {
            var result = x.data.split("<newwindow>");
            for (var i = 0; i < result.length; i++) {
              let newWindow = window.open();
              newWindow.document.write(unEscapeHtml(result[i]));
            }
          } else {
            let newWindow = window.open();
            newWindow.document.write(unEscapeHtml(x.data));
          }
        } else {
          notify.error("Failed to get Payout Report");
        }
      });
    }
  };

  const unEscapeHtml = (x) => {
    let doc = new DOMParser().parseFromString(x, "text/html");
    return doc.documentElement.textContent;
  };

  useEffect(() => {
    setSubmitDisabled(plc && date);
  }, [date, plc]);

  return (
    <div className="generate-report">
      <form name="message" onSubmit={(e) => handleGenerateReport(e)}>
        <div className="report-form">
          <label htmlFor="date-range">Date</label>
          <SingleDatePicker
            date={date} // momentPropTypes.momentObj or null
            onDateChange={(date) => setDate(date)} // PropTypes.func.isRequired
            focused={focused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={(focusedInput) => setFocused(focusedInput.focused)} // PropTypes.func.isRequired,
            verticalSpacing={0}
            transitionDuration={0}
            hideKeyboardShortcutsPanel={true}
            id="date-range"
            isOutsideRange={() => false}
          />
        </div>
        <div className="float-left plc-form long">
          <label htmlFor="react-select-2-input">PLC ID</label>
          <PlcSelect
            id="plc-id"
            name="plc-id"
            class="plc-select recon-form"
            setPlc={(e) => setPlc(e)}
            isMulti={false}
            numResults={500}
            stayOpen={false}
          />
        </div>
        <div className="report-form select">
          <label htmlFor="group-transactions">Group Transactions</label>
          <Select
            id="history-search"
            options={paymentTypes}
            className="proview-select bigger"
            classNamePrefix="select"
            onChange={(e) => setPaymentType(e)}
            value={paymentType}
            name="group-transactions"
          />
        </div>
        <button
          className="proview-button report margins"
          // disabled={!canApprove}
          onClick={(e) => handleGenerateReport(e)}
          type="submit"
        >
          Generate Report
        </button>
        <button
          className="proview-button report margins"
          // disabled={!canApprove}
          onClick={(e) => handleResendReport(e)}
          type="submit"
        >
          Resend Report
        </button>
      </form>
    </div>
  );
};

export default GenerateRecon;
