import React, { useState, useEffect } from "react";

// 3rd party imports
import moment from "moment";

// app imports
import Note from "./Note";
import * as api from "../../api";

// if there are comments, render them
const generateComments = (comments) => {
  if (comments) {
    return comments.map((comment, i) => (
      <tr key={i}>
        <td>
          Priority <br />
          <b>{comment.priority}</b>
        </td>
        <td>
          Issue Type
          <br /> <b>{comment.issueType}</b>
        </td>
        <td>
          <b style={{ fontSize: "15px" }}>
            {comment.user.firstName + " " + comment.user.lastName}{" "}
          </b>
          (ID:
          {comment.user.userId})
          <div className="float-right hide-mobile">
            {moment(comment.commentDatetime).format("MM/DD/YYYY hh:mm A")}
          </div>
          <p>
            {comment.details}
            <br />
          </p>
          {comment.emailAddress ? (
            <div className="text-right">
              reply to <em>{comment.emailAddress}</em>
            </div>
          ) : null}
        </td>
      </tr>
    ));
  }
};

const TransactionNotes = (props) => {
  const [comments, setComments] = useState([]);

  // Retrieve comments and store them in state
  const retrieveInfo = () => {
    api.getTransaction(props.referenceId, "comments").then((x) => {
      setComments(x);
    });
  };

  // on startup, retrieve notes
  useEffect(() => {
    retrieveInfo();
  }, []);

  return (
    <div className="transaction-notes">
      <table className="transaction-notes-table">
        <caption>
          <div>TRANSACTION NOTES</div>
        </caption>
        <tbody>{generateComments(comments)}</tbody>
      </table>
      <div />
      <Note
        retrieveInfo={(e) => retrieveInfo()}
        referenceId={props.referenceId}
        plcId={props.plcId}
      />
    </div>
  );
};

export default TransactionNotes;
