import React, { useState } from "react";
import "../../stylesheets/Sort.css";

const MigrationSort = (props) => {
  const [topChecked, setTopChecked] = useState(false);

  if (topChecked !== props.topChecked) {
    setTopChecked(props.topChecked);
  }

  return (
    <tr id="sort">
      <td>PLC ID</td>
      <td>PLC NAME</td>
      <td>START DATE</td>
      <td>END DATE</td>
      <td></td>
    </tr>
  );
};

export default MigrationSort;
