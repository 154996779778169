// framework imports - 1st party
import React, { useState, useEffect } from "react";
import withRouter from "../HelperComponents/WithRouter";

// lib imports - 3rd party
import Modal from "react-modal";
import cloneDeep from "lodash/cloneDeep";

// app imports
import * as Auth from "../../authService";
import * as api from "../../api";
import * as notify from "../../notify";
import edit from "../../icons/edit.svg";
import PlcSelect from "../HelperComponents/PlcSelect";
import Spinner from "../HelperComponents/Spinner";

// map plc info
const map_results = (results) => {
  return results.map((transaction, index) =>
    transaction.label !== "Account Type" ? (
      <tr key={index}>
        <td>{transaction.label}</td>
        <td>
          <strong>
            {transaction.label === "Phone #"
              ? transaction.info.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
              : transaction.info}
          </strong>
        </td>
      </tr>
    ) : Auth.canAdmin() ? (
      <tr key={index}>
        <td>{transaction.label}</td>
        <td>
          <strong>{transaction.info}</strong>
        </td>
      </tr>
    ) : null
  );
};

const PlcInformation = (props) => {
  const [plc, setPlc] = useState(props.plc);
  const [showModal, setShowModal] = useState(false);
  const [showFeeModal, setShowFeeModal] = useState(false);
  const [showEcheckModal, setShowEcheckModal] = useState(false);

  const [customFields, setCustomFields] = useState([]);
  const [newCustomFields, setNewCustomFields] = useState({ fields: [] });
  const [paymentInfo, setPaymentInfo] = useState({ charges: [] });
  const [newPaymentInfo, setNewPaymentInfo] = useState({ charges: [] });
  const [credited, setCredited] = useState("");
  const [serviceFeeOverrideMessage, setServiceFeeOverrideMessage] =
    useState("");
  const [loading, setLoading] = useState(false);
  const [newPlc, setNewPlc] = useState({
    id: "",
    name: "",
    accountType: "",
    Plc: "",
  });
  const [ssTypes, setSsTypes] = useState({ fields: [] });

  const ss_types = (results) => {
    if (results !== "") {
      return results.filter((e) => e.type === "ss");
    }
    return { fields: [] };
  };

  function canUpdate(oldFields, newFields) {
    const old = ss_types(oldFields);
    const hasOldPlcSsn = old.length !== 0 ? true : false;
    const newf = ss_types(newFields);
    const hasNewPlcSsn = newf.length !== 0 ? true : false;
    const canUpdate = hasOldPlcSsn === hasNewPlcSsn;
    return canUpdate;
  }

  // keep track of correct plc
  if (plc !== props.plc) {
    setPlc(props.plc);
  }
  const plc_information = map_results(plc);

  const handleOpenModal = () => {
    api.getTransaction(props.referenceId, "customInfo").then((x) => {
      if (x) {
        setCustomFields(x.fields);
        const ss = ss_types(x.fields);
        setSsTypes(ss);
      }
    });
    api.getTransaction(props.referenceId, "paymentInfo").then((x) => {
      if (x) {
        const all = x;
        // modify changes in payload to be rounded to 2 decimal places
        all.charges = x.charges.map((x) => {
          x.chargeAmount = x.chargeAmount.toFixed(2);
          return x;
        });
        setPaymentInfo(all);
      }
    });

    setShowModal(true);
  };

  const proccessFess = () => {
    if (
      newPlc.Plc !== props.existingPlc.id ||
      (newPaymentInfo.charges[0] !== null &&
        paymentInfo.charges[0] !== null &&
        newPaymentInfo.charges[0].chargeAmount !==
          paymentInfo.charges[0].chargeAmount)
    ) {
      if (newPaymentInfo.charges[0].chargeAmount === "") {
        notify.error("Invalid input for Payment Amount");
      } else {
        handleServiceFeeCalcUpdate();
      }
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setShowFeeModal(false);
    setLoading(true);
    const plcUpdate = {
      plcId: newPlc.Plc,
      paymentInfo: newPaymentInfo,
      customInfo: newCustomFields,
      ssFields: ssTypes,
    };

    if (newPaymentInfo.gpsFee !== null) {
      plcUpdate.paymentInfo.gpsFee = newPaymentInfo.gpsFee;
    } else {
      plcUpdate.paymentInfo.gpsFee = paymentInfo.gpsFee;
    }
    api.updatePlc(props.referenceId, plcUpdate).then((x) => {
      if (x.status === 200 || x.status === 201) {
        const type = x.data.paymentType;
        if (type === "ECHECK") {
          setShowEcheckModal(true);
        } else {
          props.navigate(".", {
            // add to a notification to state
            // so on refresh Details knows to display a notification
            state: {
              type: "success",
              message: "Plc Changed",
              transactionId: props.location.state.transactionId,
              prevScreen: props.location.state.prevScreen,
              writeNote: props.location.state.writeNote,
            },
          });
          window.location.reload();
        }
      } else {
        if (x.status === 404 || x.status === 500) {
          setLoading(false);
          notify.error("Invalid input for Payment Information");
        } else {
          setLoading(false);
          notify.error(x.data.message);
        }
      }
    });
  };

  const handleCloseEcheckModal = () => {
    setShowEcheckModal(false);
    setShowFeeModal(false);
    window.location.reload();
  };

  const handleCancel = () => {
    setShowModal(false);
    setNewCustomFields({ fields: [] });
    setNewPaymentInfo({ charges: [] });
    notify.cancel("PLC change cancelled");
  };

  const handleCancelFeeModal = () => {
    setShowFeeModal(false);
    notify.cancel("PLC change cancelled");
  };

  const renderTransactionRows = (info) => {
    if (info !== null && info.length > 0) {
      return info.map((row, i) =>
        row.attribute !== "full" ? (
          <tr key={row.fieldValueId}>
            <td>
              {row.attribute === "value"
                ? row.name
                : row.name + (row.attribute ? " (" + row.attribute + ")" : "")}
            </td>
            <td>{row.value}</td>
          </tr>
        ) : null
      );
    } else {
      return (
        <tr>
          <td>None</td>
        </tr>
      );
    }
  };

  const renderCharges = (info) => {
    return info.charges.map((charge, i) => (
      <tr key={charge.chargeId}>
        <td>
          <label htmlFor={charge.chargeName}>{charge.chargeName}</label>
        </td>
        <td>{"$" + charge.chargeAmount}</td>
      </tr>
    ));
  };

  const renderNewTransactions = () => {
    var update = true;
    var hasNewFields = false;
    if (newCustomFields.fields.length > 0) {
      hasNewFields = true;
      update = canUpdate(customFields, newCustomFields.fields);
    }
    if (hasNewFields && update) {
      return newCustomFields.fields.map((row, i) =>
        row.type !== "ss" ? (
          <tr key={i}>
            <td>
              <label htmlFor={row.name + row.attribute} />
              {row.attribute === "value"
                ? row.name
                : row.name +
                  (row.attribute ? " (" + row.attribute + ")" : "")}{" "}
            </td>
            <td>
              <input
                id={row.name + row.attribute}
                className="change-information plc-change"
                value={newCustomFields.fields[i].value}
                onChange={(e) => {
                  const customCopy = cloneDeep(newCustomFields);
                  customCopy.fields[i].value = e.target.value;
                  setNewCustomFields(customCopy);
                }}
                style={{
                  border:
                    newCustomFields.fields[i].attribute === "middle" ||
                    newCustomFields.fields[i].value.length > 0 ||
                    !newCustomFields.fields[i].required
                      ? "1px solid #c9c9c9"
                      : "1px solid #cc0000",
                }}
              />
            </td>
          </tr>
        ) : row.type === "ss" ? (
          <tr key={i}>
            <td>
              <label htmlFor={row.name + row.attribute} />
              {row.attribute === "value"
                ? row.name
                : row.name +
                  (row.attribute ? " (" + row.attribute + ")" : "")}{" "}
            </td>
            <td>
              <input
                id={row.name + row.attribute}
                className="change-information plc-change"
                value={ssTypes[1].value}
                style={{
                  border: "1px solid #c9c9c9",
                  color: "#E5E5E8",
                }}
              />
            </td>
          </tr>
        ) : null
      );
    } else {
      return (
        <tr>
          <td>
            {update === false
              ? "Social Security is required on the new PLC."
              : "None"}
          </td>
        </tr>
      );
    }
  };

  const renderNewPayments = () => {
    if (newPaymentInfo.charges.length > 0) {
      return newPaymentInfo.charges.map((row, i) => (
        <tr key={i}>
          <td>
            <label htmlFor={row.chargeId} />
            {row.chargeName}
          </td>
          <td>
            <input
              id={row.chargeName}
              className="change-information plc-change short"
              value={newPaymentInfo.charges[i].chargeAmount}
              onChange={(e) => {
                if (e.target.value === "" || !isNaN(e.target.value)) {
                  const customCopy = cloneDeep(newPaymentInfo);
                  customCopy.charges[i].chargeAmount = e.target.value;
                  setNewPaymentInfo(customCopy);
                }
              }}
            />
          </td>
        </tr>
      ));
    }
  };

  const handleServiceFeeCalcUpdate = () => {
    api
      .transactionServiceFee(
        props.referenceId,
        newPlc.Plc,
        "calculateServiceFee",
        newPaymentInfo
      )
      .then((x) => {
        if (x.status !== 200) {
          if (x.status === 404) {
            notify.error("Invalid input for Service Fee");
          } else {
            notify.error("Service Fee failed to update: " + x.data.message);
          }
        } else {
          newPaymentInfo.gpsFee = x.data.gpsFee;
          newPaymentInfo.displayGpsFee = x.data.displayGpsFee;
          newPaymentInfo.displayTotalAmountPlusGpsFee =
            x.data.totalAmountPlusGpsFee;
          setCredited(
            (
              paymentInfo.totalAmountPlusGpsFee -
              newPaymentInfo.displayTotalAmountPlusGpsFee
            ).toFixed(2)
          );
          if (
            Number(newPaymentInfo.gpsFee) > Number(paymentInfo.gpsFee) &&
            newPaymentInfo.charges[0].chargeAmount >= paymentInfo.totalAmount
          ) {
            setServiceFeeOverrideMessage(
              "The new service fee should be $" +
                newPaymentInfo.displayGpsFee +
                ". Will keep the original fee of " +
                paymentInfo.displayGpsFee +
                " to keep transaction total at authorized amount of " +
                paymentInfo.displayTotalAmountPlusGpsFee +
                ". Click OK to proceed."
            );
          } else {
            setServiceFeeOverrideMessage(
              "The new service fee is " +
                newPaymentInfo.displayGpsFee +
                ", and the new total is " +
                newPaymentInfo.displayTotalAmountPlusGpsFee +
                ".The card holder will be credited " +
                (
                  paymentInfo.totalAmountPlusGpsFee -
                  newPaymentInfo.displayTotalAmountPlusGpsFee
                ).toFixed(2) +
                ". Click OK to proceed."
            );
          }
          setShowFeeModal(true);
        }
      });
  };

  // return true if every required customInfo field is filled in
  // hard code middle name not required
  function requiredFilledIn(currentValue) {
    if (currentValue.attribute === "middle" || currentValue.type === "ss") {
      return true;
    } else if (currentValue.required) {
      return currentValue.value;
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (newPlc) {
      if (newPlc.Plc) {
        api.plcTransaction(newPlc.Plc, "customInfo").then((x) => {
          if (x) {
            const y = x.fields;
            y.map((y) => (y.value = ""));
            x.fields = x.fields.filter((f) => f.type !== "information");
            setNewCustomFields(x);
          } else {
            setNewCustomFields({ fields: [] });
          }
        });

        api.plcTransaction(newPlc.Plc, "paymentInfo").then((x) => {
          if (x) {
            x.charges[0].chargeAmount = paymentInfo.totalAmount;
            x.charges[0].chargeAmount = x.charges[0].chargeAmount.toFixed(2);
            setNewPaymentInfo(x);
          }
        });
      }
    }
  }, [newPlc]);

  return (
    <table className="description-table">
      <caption>
        <div>PLC INFORMATION</div>

        {(Auth.canFinanceOverride() ||
          Auth.canAdmin() ||
          Auth.canPlcInformationChange()) &&
        !props.notEditable ? (
          <button
            className="edit-button hoverable hide-mobile"
            type="button"
            onClick={handleOpenModal}
          >
            <img className="edit-icon" alt="Edit Icon" src={edit} />
            <span className="edit-text">{"Change PLC"}</span>
          </button>
        ) : null}
        <div>
          <Modal
            isOpen={showEcheckModal}
            shouldCloseOnOverlayClick={true} // that's it!
            onRequestClose={() => {
              props.setShowModal(false);
            }}
            className="Modal deny"
            overlayClassName="Overlay deny"
            ariaHideApp={false}
          >
            <h3>Echeck Transaction</h3>
            <div>
              {" "}
              The PLC change was successful but we could not credit the card
              holder with the amount of ${credited}. Message:-1- Error in
              crediting this transaction. Please email prodsupport@govpaynet.com
              for additional assistance.{" "}
            </div>
            <div>
              <button
                className="proview-button right"
                onClick={handleCloseEcheckModal}
              >
                OK
              </button>
            </div>
          </Modal>
          <Modal
            isOpen={showFeeModal}
            shouldCloseOnOverlayClick={true} // that's it!
            onRequestClose={() => {
              props.setShowModal(false);
            }}
            className="Modal deny"
            overlayClassName="Overlay deny"
            ariaHideApp={false}
          >
            <h3>Service Fee Override</h3>
            <div>{serviceFeeOverrideMessage}</div>
            <div>
              <button
                className="proview-button cancel left"
                onClick={handleCancelFeeModal}
              >
                Cancel
              </button>
              <button className="proview-button right" onClick={handleSubmit}>
                OK
              </button>
            </div>
          </Modal>
          <Modal
            isOpen={showModal}
            shouldCloseOnOverlayClick={true} // that's it!
            onRequestClose={() => {
              setShowModal(false);
            }}
            className="Modal plc"
            overlayClassName="Overlay"
            ariaHideApp={false}
          >
            <table className="plc-table">
              <caption>
                <div>Existing PLC Info</div>
              </caption>
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>{props.existingPlc.id}</td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>{props.existingPlc.name}</td>
                </tr>
                <tr>
                  <td>Account Type</td>
                  <td>{props.existingPlc.accountType}</td>
                </tr>
              </tbody>
            </table>
            <table className="plc-table">
              <caption>
                <div>New PLC Info</div>
              </caption>
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>
                    <PlcSelect
                      id="plc-id"
                      name="plc-id"
                      class="plc-select plc-change"
                      setPlc={(e) => setNewPlc(e)}
                      isMulti={false}
                      numResults={10}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>{newPlc ? newPlc.name : ""}</td>
                </tr>
                <tr>
                  <td>Account Type</td>
                  <td>{newPlc ? newPlc.accountType : ""}</td>
                </tr>
              </tbody>
            </table>
            <hr />
            <table className="plc-table">
              <caption>
                <div>Existing Transaction Info</div>
              </caption>
              <tbody>{renderTransactionRows(customFields)}</tbody>
            </table>
            <table className="plc-table">
              <caption>
                <div>New Transaction Info</div>
              </caption>
              <tbody>{renderNewTransactions()}</tbody>
            </table>
            <hr />
            <table className="plc-table">
              <caption>
                <div>Existing Payment Info</div>
              </caption>
              <tbody>
                {renderCharges(paymentInfo)}
                <tr>
                  <td>Service Fee</td>
                  <td>
                    {"$" + (paymentInfo.gpsFee ? paymentInfo.gpsFee : "")}
                  </td>
                </tr>
                <tr>
                  <td>Total Amount</td>
                  <td>
                    {paymentInfo.displayTotalAmountPlusGpsFee
                      ? paymentInfo.displayTotalAmountPlusGpsFee
                      : ""}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="plc-table">
              <caption>
                <div>New Payment Info</div>
              </caption>
              <tbody>
                {renderNewPayments()}
                <tr>
                  <td>
                    <label htmlFor="payment-amount" />
                    Service Fee
                  </td>
                  <td>
                    {"$" + (paymentInfo.gpsFee ? paymentInfo.gpsFee : "")}
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <Spinner
                customClass={"sk-fading-circle summary"}
                loading={loading}
              />
            </div>
            <div className="margin-top">
              <button
                className="proview-button cancel left"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="proview-button right"
                onClick={proccessFess}
                disabled={
                  newPlc
                    ? !newPlc.name ||
                      !newCustomFields.fields.every(requiredFilledIn)
                    : true
                }
              >
                Change PLC
              </button>
            </div>
          </Modal>
        </div>
      </caption>
      <tbody>{plc_information}</tbody>
    </table>
  );
};

export default withRouter(PlcInformation);
