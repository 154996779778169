// framework imports - 1st party
import React, { useState, useEffect } from "react";

// app imports
import ExclusionsSelect from "./ExclusionsSelect";
import ExclusionsAdd from "./ExclusionsAdd";
import ExclusionsTable from "./ExclusionsTable";
import * as api from "../../api";
import "../../stylesheets/ExclusionsMain.css";

const ExclusionsMain = (props) => {
  const [plc, setPlc] = useState();
  const [rows, setRows] = useState();

  // get exclusion rows
  const retrieveInfo = () => {
    if (plc) {
      props.setLoading(true);
      api.fraudEntries(plc.fieldId0).then((x) => {
        setRows(x.data);
        props.setLoading(false);
      });
    }
  };

  useEffect(() => {
    // if PLC is selected, then
    retrieveInfo();
  }, [plc]);

  return (
    <div className="exclusions-main">
      <ExclusionsSelect setPlc={(e) => setPlc(e)} />
      <div className="exclusions-manage">
        <ExclusionsAdd plc={plc} retrieveInfo={(e) => retrieveInfo()} />
        <ExclusionsTable
          plc={plc}
          loading={props.loading}
          retrieveInfo={(e) => retrieveInfo()}
          rows={rows}
        />
      </div>
    </div>
  );
};

export default ExclusionsMain;
