// framework imports - 1st party
import React, { useState, useEffect } from "react";

// lib imports - 3rd party
import Modal from "react-modal";

// app imports
import { ReactComponent as Close } from "../../icons/delete.svg";
import * as api from "../../api";

const UserProfile = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [userInfo, setUserInfo] = useState(false);

  if (showModal !== props.showModal) {
    setShowModal(props.showModal);
  }

  const handleCloseModal = () => {
    props.setShowModal(false);
  };

  useEffect(() => {
    api.getUserProfile(props.userId).then((x) => {
      setUserInfo(x);
    });
  }, [props.userId]);

  return (
    <Modal
      isOpen={props.showModal}
      shouldCloseOnOverlayClick={true} // that's it!
      onRequestClose={() => {
        props.setShowModal(false);
      }}
      className="Modal user-info"
      overlayClassName="Overlay"
      ariaHideApp={false}
    >
      <h3>User Info</h3>
      <div className="report-form resend">
        <label>Username/Email</label>
        <input className="proview-input" disabled value={userInfo.email} />
      </div>
      <div className="report-form resend">
        <label>First Name</label>
        <input className="proview-input" disabled value={userInfo.firstName} />
      </div>
      <div className="report-form resend">
        <label>Last Name</label>
        <input className="proview-input" disabled value={userInfo.lastName} />
      </div>
      <div className="report-form resend">
        <label>Phone Number</label>
        <input
          className="proview-input"
          disabled
          value={userInfo.phoneNumber}
        />
      </div>
      <div className="close-button" onClick={handleCloseModal}>
        <Close className="row-icon" />
      </div>
    </Modal>
  );
};

export default UserProfile;
