// framework imports - 1st party
import React, { useState } from "react";

// lib imports - 3rd party

// app imports
import { ReactComponent as SortIcon } from "../../icons/sort.svg";
import "../../stylesheets/Sort.css";
import * as Auth from "../../authService";

const Sort = (props) => {
  const [topChecked, setTopChecked] = useState(false);

  if (topChecked !== props.topChecked) {
    setTopChecked(props.topChecked);
  }

  return (
    <tr id="sort">
      <td>
        <input
          type="checkbox"
          aria-label="top-checkbox"
          onClick={props.onTopChecked}
          checked={topChecked}
          readOnly
        />
      </td>

      <td
        className="text-right sorting"
        onClick={() =>
          props.setSort({
            sortId: "transactionId",
            asc:
              props.sort.sortId === "transactionId" ? !props.sort.asc : false,
          })
        }
      >
        <label htmlFor="ref">
          Ref # <SortIcon />
        </label>
      </td>
      <td
        className="text-right sorting"
        onClick={() =>
          props.setSort({
            sortId: "plcId",
            asc: props.sort.sortId === "plcId" ? !props.sort.asc : false,
          })
        }
      >
        <label htmlFor="react-select-2-input">
          PLC <SortIcon />
        </label>
      </td>
      <td
        className="text-right sorting"
        onClick={() =>
          props.setSort({
            sortId: "created_at",
            asc: props.sort.sortId === "created_at" ? !props.sort.asc : false,
          })
        }
      >
        <label htmlFor="search-dates">
          Date <SortIcon />
        </label>
      </td>
      <td>
        <label htmlFor="Name">Name </label>
      </td>
      <td
        className="text-right sorting"
        onClick={() =>
          props.setSort({
            sortId: "paid_datetime",
            asc:
              props.sort.sortId === "paid_datetime" ? !props.sort.asc : false,
          })
        }
      >
        <label htmlFor="search-payout-dates">
          Payout Date <SortIcon />
        </label>
      </td>
      <td className="">
        <label htmlFor="select-status">Status</label>
      </td>
      {Auth.hasQuickbooksPlc() === "true" ||
      Auth.hasQuickbooksPlc() === "null" ? (
        <td className="text-center">
          <label htmlFor="select-syncStatus">Sync?</label>
        </td>
      ) : null}
      <td className="text-right">
        <label htmlFor="Payment">Payment</label>
      </td>
    </tr>
  );
};

export default Sort;
