// framework imports - 1st party
import React from "react";

// app imports
import "../../stylesheets/Sort.css";

// "Sort" header screen for table
const GenerateSort = () => {
  return (
    <tr id="sort">
      <td className="text-center sorting">Reference #</td>
      <td className="text-center sorting">Auth Date (EST)</td>
      <td className="text-center sorting">Cardholder Name</td>
      <td className="text-center sorting">City, State</td>
      <td className="text-center sorting">Details</td>
    </tr>
  );
};

export default GenerateSort;
