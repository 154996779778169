// framework imports - 1st party
import React, { useEffect, useState } from "react";

// lib imports - 3rd party
import Modal from "react-modal";

import * as api from "../../api";
import * as notify from "../../notify";
import withRouter from "../HelperComponents/WithRouter";

const Reason = (props) => {
  const [reason, setReason] = useState("");
  const [submitFailed, setSubmitFailed] = useState(false);

  // on Deny, call api to deny, then redirect to transaction summary
  const handleChange = () => {
    var changeText;
    var apiText;
    if (props.changeType === "Unapprove") {
      changeText = " undone";
      apiText = "unapprove";
    } else if (props.changeType === "Undeny") {
      changeText = " rejection cancelled";
      apiText = "undeny";
    } else if (props.changeType === "deny") {
      changeText = " rejected";
      apiText = props.changeType;
    } else {
      changeText = " changed";
      apiText = props.changeType;
    }

    api.denyTransactions(props.referenceId, apiText, reason).then((x) => {
      if (x.status === 200 || x.status === 201) {
        props.navigate(".", {
          // add to a notification to state
          // so on refresh Details knows to display a notification
          state: {
            type: "success",
            message: "Transaction " + props.referenceId + changeText,
            transactionId: props.location.state.transactionId,
            prevScreen: props.location.state.prevScreen,
            writeNote: props.location.state.writeNote,
            recurringPaymentId: props.location.state.recurringPaymentId,
          },
        });
        window.location.reload();
      } else {
        if (x.response.data.message.includes("paying out")) {
          notify.error(x.response.data.message);
        } else {
          notify.error("Status change failed");
        }
      }
    });
  };

  // on submit, handle the deny then close the modal
  const handleSubmit = () => {
    if (!reason) {
      notify.error("Please enter a reason");
      setSubmitFailed(true);
    } else {
      setSubmitFailed(false);
      handleChange();
      props.setShowModal(false);
      props.setShowDropdown(false);
    }
  };

  // on cancel, close deny modal
  const handleCloseModal = () => {
    setReason("");
    props.setShowModal(false);
    props.setShowDropdown(false);
  };

  const undoButtonDisplay = () => {
    if (props.changeType === "Unapprove") {
      return "Undo Approval";
    } else if (props.changeType === "Undeny") {
      return "Rejection Cancellation";
    } else if (props.changeType === "deny") {
      return "Reject";
    } else {
      return "Submit";
    }
  };

  return (
    <Modal
      isOpen={props.showModal}
      shouldCloseOnOverlayClick={true} // that's it!
      onRequestClose={() => {
        props.setShowModal(false);
        props.setShowDropdown(false);
      }}
      className="Modal deny denyreason"
      overlayClassName="Overlay deny"
      ariaHideApp={false}
    >
      <h3>
        Reason<span style={{ color: "red" }}>*</span>
      </h3>
      <textarea
        className="proview-input details"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        style={{
          border:
            submitFailed && !reason ? "1px solid #cc0000" : "1px solid #c9c9c9",
        }}
      />
      <h4 style={{ fontWeight: "bold", textAlign: "justify" }}>
        Are you sure? Rejecting refunds the full amount to payer and cannot be
        undone. Click cancel to return to the Transaction details and submit the
        Service Form to change the amount.{" "}
      </h4>
      <div>
        <button
          className="proview-button cancel left"
          onClick={handleCloseModal}
        >
          Cancel
        </button>
        <button className="proview-button right" onClick={handleSubmit}>
          {undoButtonDisplay()}
        </button>
      </div>
    </Modal>
  );
};

export default withRouter(Reason);
