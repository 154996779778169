// framework imports - 1st party
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// lib imports - 3rd party
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";

// app imports
import EditIcon from "./EditIcon";
import * as notify from "../../notify";
import * as api from "../../api";
import * as Auth from "../../authService";
import SsnInput from "./ssn-input";
import "../../stylesheets/ReactDates.css";
import ChangeApprovedDateBeforeConfirm from "../HelperComponents/ChangeApprovedDateBeforeConfirm";
import ChangeApprovedDateAfterConfirm from "../HelperComponents/ChangeApprovedDateAfterConfirm";
import ChangePaidDateBeforeConfirm from "../HelperComponents/ChangePaidDateBeforeConfirm";

const TransactionInformation = (props) => {
  const [
    showModalChangeApprovedDateBeforeConfirm,
    setShowModalChangeApprovedDateBeforeConfirm,
  ] = useState(false);
  const [
    showModalChangeApprovedDateAfterConfirm,
    setShowModalChangeApprovedDateAfterConfirm,
  ] = useState(false);
  const [
    showModalChangePaidDateBeforeConfirm,
    setShowChangePaidDateBeforeConfirm,
  ] = useState(false);
  const [dateDisabled, setDateDisabled] = useState(true);
  const [customFields, setCustomFields] = useState([]);
  const [commonFields, setCommonFields] = useState({ endTime: null });
  const [payoutDate, setPayoutDate] = useState("");
  const [approvedDate, setApprovedDate] = useState("");
  const [payoutableDate, setPayoutableDate] = useState(null);
  const [approvalDate, setApprovalDate] = useState(null);
  const [updateSSN, setUpdateSSN] = useState("");
  const [updatePayoutDate, setUpdatePayoutDate] = useState(false);
  const [focused, setFocused] = useState(null);
  const [focusedApproved, setFocusedApproved] = useState(null);
  const [editPayoutDate, setEditPayoutDate] = useState(false);
  const [recurringPaymentInfo, setRecurringPaymentInfo] = useState({});
  const [updateApprovedDate, setUpdateApprovedDate] = useState(false);
  const [editApprovedDate, setEditApprovedDate] = useState(false);
  // since disabled inputs don't word wrap natively
  // and are styled weirdly in IE11,
  // if disabled, inputs will render as normal text,
  // NOT as disabled inputs
  // hardcode out FULL attributes
  const createRows = (object, setObject, isDisabled, setUpdateSSN) => {
    if (object !== null && object.length > 0) {
      return object.map((row, i) =>
        row.attribute !== "full" && row.type !== "ss" ? (
          <tr key={row.fieldValueId}>
            <td>
              <label
                htmlFor={row.attribute === "value" ? row.name : row.attribute}
              >
                {row.attribute === "value"
                  ? row.name
                  : row.name +
                    (row.attribute ? " (" + row.attribute + ")" : "")}
              </label>
            </td>
            <td>
              {isDisabled ? (
                <strong>{row.value}</strong>
              ) : (
                <input
                  id={row.attribute === "value" ? row.name : row.attribute}
                  className={"change-information"}
                  value={row.value}
                  onChange={(e) => {
                    const customCopy = object.slice();
                    customCopy[i].value = e.target.value;
                    setObject(customCopy);
                  }}
                  style={{
                    width:
                      object[i].value === null
                        ? 10
                        : object[i].value.length * 10 + "px",
                  }} // grow input for more text
                />
              )}
            </td>
          </tr>
        ) : row.attribute !== "full" && row.type === "ss" ? (
          <tr key={row.fieldValueId}>
            <td>
              <label
                htmlFor={row.attribute === "value" ? row.name : row.attribute}
              >
                {row.attribute === "value"
                  ? row.name
                  : row.name +
                    (row.attribute ? " (" + row.attribute + ")" : "")}
              </label>
            </td>
            <td>
              {isDisabled ? (
                <strong>
                  {row.value.length === 4 ? "XXXX-XX-" + row.value : row.value}
                </strong>
              ) : (
                <SsnInput
                  ss={
                    row.value.length === 4 ? "XXXX-XX-" + row.value : row.value
                  }
                  onUpdate={setUpdateSSN}
                  index={i}
                  customCopy={object.slice()}
                  setObject={setObject}
                />
              )}
            </td>
          </tr>
        ) : null
      );
    } else {
      return null;
    }
  };

  const checkAcceptTimeAfterCreate = () => {
    if (commonFields.acceptTime.isAfter(commonFields.endTime)) {
      return true;
    } else {
      return false;
    }
  };
  const checkAcceptTimeBeforePaid = () => {
    if (commonFields.acceptTime.isBefore(commonFields.paidTime)) {
      return true;
    }
    return false;
  };
  const checkPaidTimeWithAcceptAndCreate = () => {
    if (
      commonFields.paidTime.isAfter(commonFields.endTime) &&
      commonFields.paidTime.isAfter(commonFields.acceptTime)
    ) {
      return true;
    }
    return false;
  };
  const handleShowChangeApprovedDateBeforeConfirmModal = () => {
    setShowModalChangeApprovedDateBeforeConfirm(true);
  };
  const handleShowChangeApprovedDateAfterConfirmModal = () => {
    setShowModalChangeApprovedDateAfterConfirm(true);
  };
  const handleShowChangePaidDateBeforeConfirmModal = () => {
    setShowChangePaidDateBeforeConfirm(true);
  };

  useEffect(() => {
    if (editApprovedDate) {
      if (!checkAcceptTimeAfterCreate()) {
        handleShowChangeApprovedDateBeforeConfirmModal();
      } else if (!checkAcceptTimeBeforePaid()) {
        handleShowChangeApprovedDateAfterConfirmModal();
      }
    }
    setEditApprovedDate(false);
  }, [editApprovedDate]);

  useEffect(() => {
    if (editPayoutDate) {
      if (!checkPaidTimeWithAcceptAndCreate()) {
        handleShowChangePaidDateBeforeConfirmModal();
      }
    }
    setEditPayoutDate(false);
  }, [editPayoutDate]);

  // on submit, make an api call
  // to submit the custom field changes,
  // AND the date change
  const handleSubmit = () => {
    const fieldSubmit = {
      fields: customFields,
      updateSSN: updateSSN,
    };
    api
      .transactionUpdate(props.referenceId, "customInfo", fieldSubmit)
      .then((x) => {
        if (x.status === 200) {
          setCustomFields(x.data.fields);
          api
            .transactionUpdate(props.referenceId, "commonInfo", commonFields)
            .then((x) => {
              if (x.status === 200) {
                const dateFields = x.data;
                dateFields.endTime = dateFields.endTime
                  ? new Date(dateFields.endTime)
                  : null;
                setCommonFields(dateFields);
                setUpdatePayoutDate(false);
                setEditPayoutDate(false);
                setUpdateApprovedDate(false);
                setEditApprovedDate(false);
                // retrieveInfo();//Adding retrieve info after transaction is updated.
                // notify.success("Transaction Information updated");
              } else {
                notify.error("Transaction Information failed to update");
                retrieveInfo();
              }
            });
          retrieveInfo(); //Adding retrieve info after transaction is updated.
          notify.success("Transaction Information updated");
        } else {
          notify.error(x.data.message);
          retrieveInfo();
        }
      });
  };

  // retrive info on startup
  const retrieveInfo = () => {
    setUpdatePayoutDate(false);
    setUpdateApprovedDate(false);
    api.getTransaction(props.referenceId, "customInfo").then((x) => {
      if (x) {
        setCustomFields(x.fields);
      }
    });
    api.getTransaction(props.referenceId, "commonInfo").then((x) => {
      if (x) {
        const dateFields = x;
        const recurringInfo = dateFields.recurringPayment;
        dateFields.endTime = dateFields.endTime
          ? new Date(dateFields.endTime)
          : null;
        setCommonFields(dateFields);
        setRecurringPaymentInfo(recurringInfo);
        setApprovedDate(x.acceptTime);
        determinePayout(x);
      }
    });
  };

  // disabled inputs
  // on small screens
  const handleResize = (e) => {
    if (window.innerWidth < 600) {
      setDateDisabled(true);
    }
  };

  // listen for window resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    retrieveInfo();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // handle date editing
  const dateEdit = (e) => {
    setUpdatePayoutDate(true);
    setUpdateApprovedDate(true);
    e.preventDefault();
    if (!dateDisabled) {
      handleSubmit();
    }
    setDateDisabled(!dateDisabled);
  };

  const handleCancel = (e) => {
    retrieveInfo();
    setDateDisabled(!dateDisabled);
    notify.cancel("Transaction Information update canceled");
  };

  const handlePayoutReport = (e) => {
    e.preventDefault();
    api.generateReport(props.plcId, payoutDate, "statements").then((x) => {
      if (x.status === 400) {
        notify.error(x.data.message);
      } else if (x.status === 200) {
        var result = x.data.split("<newwindow>");
        for (var i = 0; i < result.length; i++) {
          var newWindow = window.open();
          newWindow.document.write(unEscapeHtml(result[i]));
        }
      } else {
        notify.error("Failed to get Payout Report");
      }
    });
  };

  const unEscapeHtml = (x) => {
    let doc = new DOMParser().parseFromString(x, "text/html");
    return doc.documentElement.textContent;
  };

  //  Depending on Status,
  // get payout date from different field
  const determinePayout = (x) => {
    if (x.confirmationStatus === "APPROVED") {
      setPayoutDate(x.estimatedPaidDate);
    } else if (x.confirmationStatus === "PAID") {
      setPayoutDate(x.paidTime);
    } else {
      setPayoutDate(null);
    }
  };

  // render date differently
  // depending on if paid out
  const renderPayoutDate = () => {
    if (payoutDate !== null) {
      if (
        (commonFields.confirmationStatus === "APPROVED" ||
          commonFields.confirmationStatus === "PAID") &&
        updatePayoutDate &&
        Auth.canFinanceOverride()
      ) {
        //Set new paidTime date from datePicker and update commonInfo
        if (payoutableDate) {
          commonFields.paidTime = payoutableDate;
        }
        return (
          <SingleDatePicker
            className="date-range-picker"
            date={payoutableDate} // momentPropTypes.momentObj or null
            onDateChange={(date) => {
              setPayoutableDate(date);
              setEditPayoutDate(true);
            }} // PropTypes.func.isRequired
            focused={focused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={(focusedInput) => setFocused(focusedInput.focused)} // PropTypes.func.isRequired,
            verticalSpacing={0}
            transitionDuration={0}
            small={true}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel={true}
            id="date-range-payout"
            isOutsideRange={(day) => day.isAfter(moment().subtract(1, "days"))}
          />
        );
      } else if (commonFields.confirmationStatus === "APPROVED") {
        return (
          <Link
            to="/summary"
            style={{ color: "#FF0000" }}
            onClick={(e) => handlePayoutReport(e)}
          >
            {moment(payoutDate).format("MM/DD/YYYY")}
          </Link>
        );
      } else {
        return (
          <Link to="/summary" onClick={(e) => handlePayoutReport(e)}>
            {moment(payoutDate).format("MM/DD/YYYY hh:mm A")}
          </Link>
        );
      }
    } else {
      return "-";
    }
  };

  const renderApprovedDate = () => {
    if (approvedDate !== null) {
      if (
        (commonFields.confirmationStatus === "APPROVED" ||
          commonFields.confirmationStatus === "PAID") &&
        updateApprovedDate &&
        Auth.canFinanceOverride()
      ) {
        //Set new paidTime date from datePicker and update commonInfo
        commonFields.acceptTime = approvalDate ? approvalDate : approvedDate;
        return (
          <SingleDatePicker
            className="date-range-picker"
            date={approvalDate} // momentPropTypes.momentObj or null
            onDateChange={(date) => {
              setApprovalDate(date);
              setEditApprovedDate(true);
            }} // PropTypes.func.isRequired
            focused={focusedApproved} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={(focusedInput) =>
              setFocusedApproved(focusedInput.focused)
            } // PropTypes.func.isRequired,
            verticalSpacing={0}
            transitionDuration={0}
            small={true}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel={true}
            id="date-range-approved"
            isOutsideRange={(day) => day.isAfter(moment().subtract(1, "days"))}
          />
        );
      } else {
        return (
          <strong>{moment(approvedDate).format("MM/DD/YYYY hh:mm A")}</strong>
        );
      }
    } else {
      return "-";
    }
  };

  //render recurring payment information for the transaction
  const renderRecurringPaymentInfo = () => {
    if (commonFields.recurringPayment) {
      return (
        <tbody>
          <tr>
            <td>Recurring Payment</td>
            <td>
              <strong>Yes</strong>
            </td>
          </tr>
          <tr>
            <td>Initial Recurring</td>
            <td>
              <strong>
                {commonFields.initialRecurring === true ? "Yes" : "No"}
              </strong>
            </td>
          </tr>
        </tbody>
      );
    }
  };
  const lowercase = (s) => {
    if (typeof s !== "string") return "";
    return s.toLowerCase();
  };
  //render AWS transaction
  const renderAWSTransaction = () => {
    if (lowercase(commonFields.application) === "allpaid") {
      return (
        <tbody>
          <tr>
            <td>UUID</td>
            <td>
              <strong>{commonFields.uuid}</strong>
            </td>
          </tr>
        </tbody>
      );
    }
  };

  //render APD Recurring Payment ID
  const renderAPDRecurring = () => {
    if (
      recurringPaymentInfo !== null &&
      recurringPaymentInfo.apdRecurringPaymentId !== "" &&
      recurringPaymentInfo.apdRecurringPaymentId !== null
    ) {
      return (
        <tbody>
          <tr>
            <td>APD Recurring Payment #</td>
            <td>
              <strong>{recurringPaymentInfo.apdRecurringPaymentId}</strong>
            </td>
          </tr>
        </tbody>
      );
    }
  };

  //render APD transaction
  const renderAPDTransaction = () => {
    if (
      commonFields.apdTransactionId !== "" &&
      commonFields.apdTransactionId !== null
    ) {
      return (
        <tbody>
          <tr>
            <td>APD Reference #</td>
            <td>
              <strong>{commonFields.apdTransactionId}</strong>
            </td>
          </tr>
        </tbody>
      );
    }
  };
  //render initial recurring
  const renderIntialRecurringZeroAuthInfo = () => {
    if (
      commonFields.recurringPayment &&
      commonFields.initialRecurring &&
      commonFields.authAmount === "$0.00"
    ) {
      return (
        <tbody>
          <tr>
            <td>$0 Auth</td>
            <td>
              <strong>Will not be paid out</strong>
            </td>
          </tr>
        </tbody>
      );
    }
  };
  const renderQbStatus = () => {
    if (commonFields.syncStatus === "QBSYNCSUCCESS") {
      return (
        <tbody>
          <tr>
            <td>QuickBooks Sync</td>
            <td>
              <strong>Success</strong>
            </td>
          </tr>
        </tbody>
      );
    }
    if (commonFields.syncStatus === "QBSYNCFAIL") {
      return (
        <tbody>
          <tr>
            <td>QuickBooks Sync</td>
            <td>
              <strong>Failure</strong>
            </td>
          </tr>
        </tbody>
      );
    }
  };

  // render payout date based on path
  // transaction is on
  const renderStatus = () => {
    if (commonFields.confirmationStatus === "DENIED") {
      return (
        <tbody>
          <tr>
            <td>REJECTED</td>
            <td>
              <strong>
                {commonFields.deniedTime == null
                  ? "-"
                  : moment(commonFields.deniedTime).format(
                      "MM/DD/YYYY hh:mm A"
                    )}
              </strong>
            </td>
          </tr>
        </tbody>
      );
    } else {
      return (
        <tbody>
          <tr>
            <td>Approved</td>
            <td>
              <strong>{renderApprovedDate()}</strong>
            </td>
          </tr>
          <tr>
            <td>
              {commonFields.confirmationStatus === "APPROVED"
                ? "Payout (Estimated)"
                : "Payout"}
            </td>
            <td>
              <strong> {renderPayoutDate()}</strong>
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const filterTransactionSummaryByCartId = (prevScreen) => {
    let transPartOfRPFilter = {};
    transPartOfRPFilter["criteria"] = {};
    transPartOfRPFilter["criteria"].referenceId = props.cartId;
    transPartOfRPFilter["pagination"] = {};
    transPartOfRPFilter["pagination"].pageSize = {
      //page size
      value: 10,
      label: "10 Results/Page",
    };
    transPartOfRPFilter["pagination"].pageNum = 0;
    transPartOfRPFilter["pagination"].sort = {
      sortId: "created_at",
      asc: false,
    };

    if (prevScreen === "/summary") {
      sessionStorage.setItem(
        "transSummaryFilter",
        JSON.stringify(transPartOfRPFilter)
      );
    }
    if (prevScreen === "/manifest") {
      sessionStorage.setItem(
        "transManifestFilter",
        JSON.stringify(transPartOfRPFilter)
      );
    }
  };

  return (
    <table className="description-table">
      <caption>
        <div className="table-title">TRANSACTION INFORMATION</div>
        {props.notEditable ? null : (
          <EditIcon
            name={"transactionEdit"}
            canEdit={
              Auth.canAmountModify() ||
              Auth.canAdmin() ||
              Auth.canFinanceOverride() ||
              Auth.canModify()
            }
            disabled={dateDisabled}
            handleEdit={dateEdit}
            handleCancel={handleCancel}
          />
        )}
      </caption>
      <tbody>
        {createRows(customFields, setCustomFields, dateDisabled, setUpdateSSN)}
        <tr>
          <td>Reference #</td>
          <td>
            <strong>{props.referenceId}</strong>
          </td>
        </tr>
        {props.cartId ? (
          <tr>
            <td>CartId</td>
            <td>
              <strong
                onClick={() =>
                  filterTransactionSummaryByCartId(props.prevScreen)
                }
              >
                <Link to={props.prevScreen}>{props.cartId}</Link>
              </strong>
            </td>
          </tr>
        ) : null}
        <tr>
          <td>
            <label htmlFor="endDate">Date</label>
          </td>
          <td>
            <strong>
              {commonFields.endTime && commonFields.endTime.getTime() > 86400000
                ? moment(commonFields.endTime).format("MM/DD/YYYY hh:mm A")
                : "-"}
            </strong>
          </td>
        </tr>
      </tbody>
      <ChangeApprovedDateBeforeConfirm
        showChangeApprovedDateBeforeConfirmModal={
          showModalChangeApprovedDateBeforeConfirm
        }
        setShowChangeApprovedDateBeforeConfirmModal={(e) =>
          setShowModalChangeApprovedDateBeforeConfirm(e)
        }
        setApprovalDate={(e) => setApprovalDate(e)}
      />
      <ChangeApprovedDateAfterConfirm
        showChangeApprovedDateAfterConfirmModal={
          showModalChangeApprovedDateAfterConfirm
        }
        setShowChangeApprovedDateAfterConfirmModal={(e) =>
          setShowModalChangeApprovedDateAfterConfirm(e)
        }
        setApprovalDate={(e) => setApprovalDate(e)}
      />
      <ChangePaidDateBeforeConfirm
        showChangePaidDateBeforeConfirmModal={
          showModalChangePaidDateBeforeConfirm
        }
        setShowChangePaidDateBeforeConfirmModal={(e) =>
          setShowChangePaidDateBeforeConfirm(e)
        }
        setPayoutableDate={(e) => setPayoutableDate(e)}
      />

      {renderStatus()}
      {renderRecurringPaymentInfo()}
      {renderIntialRecurringZeroAuthInfo()}
      {renderAPDRecurring()}
      {renderAPDTransaction()}
      {renderAWSTransaction()}
      {renderQbStatus()}
    </table>
  );
};

export default TransactionInformation;
