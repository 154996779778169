// framework imports - 1st party
import React from "react";

// app imports
import "../../stylesheets/ExclusionsMain.css";
import PlcSelect from "../HelperComponents/PlcSelect";

const ExclusionsSelect = (props) => {
  return (
    <div className="exclusions-select">
      <div className="float-left plc-form">
        <label htmlFor="react-select-2-input">
          <strong>PLC's with Exclusions</strong>
        </label>
        <PlcSelect
          id="plc-id"
          name="plc-id"
          class="plc-select exclusions-search"
          setPlc={(e) => props.setPlc(e)}
          isMulti={false}
          numResults={99999999}
          stayOpen={true}
          fraud={true}
        />
      </div>
    </div>
  );
};

export default ExclusionsSelect;
