// framework imports - 1st party
import React, { useState, useEffect } from "react";

// 3rd party imports
import Select from "react-select";
import Modal from "react-modal";

// app imports
import * as api from "../../api";
import * as notify from "../../notify";
import QbSyncResendConfirm from "../HelperComponents/QbSyncResendConfirm";

// Resend modal and logic
// used on Details and Summary page
const Resend = (props) => {
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState({
    value: "eup",
    label: "End User Confirmation",
  });
  const [destination, setDestination] = useState("");
  const [attention, setAttention] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [validEmailOrFax, setValidEmailOrFax] = useState(true);

  // default types in dropdown
  const [documentType, setDocumentType] = useState([
    { value: "eup", label: "End User Confirmation" },
  ]);

  // determines if able to submit
  useEffect(() => {
    setCanSubmit(isPostback() || isQbSync() ? true : destination);
  }, [type, destination]);

  useEffect(() => {
    api.getResendDropdown(props.transactionId).then((x) => {
      if (x && (x.status === 200 || x.status === 201)) {
        setDocumentType(x.data);
      }
    });
  }, [props.transactionId]);

  // email validator regex
  const validateEmail = (emailOrFax) => {
    var reMail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var reFax = /^\+?[0-9]+$/;
    var result = true;

    var destArray = emailOrFax.split(",");
    for (var i = 0; i < destArray.length; i++) {
      if (
        destArray[i] !== "" &&
        !reMail.test(String(destArray[i]).toLowerCase()) &&
        !reFax.test(String(destArray[i]).toLowerCase())
      ) {
        result = false;
      }
    }
    return result;
  };

  // determine if submission works
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isPostback() && !validateEmail(destination) && !isQbSync()) {
      setValidEmailOrFax(false);
      notify.error("Please enter a valid email address or fax number");
    } else {
      setValidEmailOrFax(true);
      api
        .sendNotification(
          props.transactionId,
          type.value,
          destination,
          attention
        )
        .then((x) => {
          if (x.status === 200 || x.status === 201) {
            if (
              x.data.htmlFormPostbackLink !== null ||
              x.data.postbackResponseMess !== null ||
              x.data.qbSyncResponseMessage !== null
            ) {
              if (x.data.htmlFormPostbackLink !== null) {
                window.open(
                  x.data.htmlFormPostbackLink,
                  "Resend Html Form Postback",
                  ""
                );
              } else if (x.data.postbackResponseMess !== null) {
                if (x.data.postbackResponseMess === "Postback success!") {
                  notify.success(x.data.postbackResponseMess);
                } else {
                  notify.error(x.data.postbackResponseMess);
                }
              } else if (x.data.qbSyncResponseMessage !== null) {
                notify.error(x.data.qbSyncResponseMessage);
              }
            } else {
              notify.success(
                "Transaction was resent for reference #" + props.transactionId
              );
            }
            setShowModalConfirm(false);
            props.setShowModal(false);
          } else {
            notify.error("Failed to resend note");
          }
        });
    }
  };
  //Check if is Postback
  const isPostback = () => {
    return type.value === "postback";
  };

  const isQbSync = () => {
    return type.value === "qb_sync";
  };
  const handleCloseModal = () => {
    props.setShowModal(false);
  };

  if (showModal !== props.showModal) {
    setShowModal(props.showModal);
  }

  const changeType = (e) => {
    setDestination(e.notifyDest);
    setAttention(e.notifyAttn);
    setType(e);
  };

  return (
    <Modal
      isOpen={props.showModal}
      shouldCloseOnOverlayClick={true} // that's it!
      onRequestClose={() => {
        props.setShowModal(false);
      }}
      className="Modal resend"
      overlayClassName="Overlay"
      ariaHideApp={false}
    >
      <h3>Resend Details</h3>
      <form>
        <div className="report-form resend">
          <label htmlFor="auth-amount">Document Type</label>

          <Select
            id="history-search"
            defaultValue={documentType[0]}
            options={documentType}
            className="proview-select fill resend"
            classNamePrefix="select"
            onChange={(e) => changeType(e)}
            value={type}
            name="group-transactions"
          />
        </div>

        <div className="report-form resend">
          <label htmlFor="auth-amount">Destination</label>
          <input
            className={
              validEmailOrFax ? "proview-input" : "proview-input error"
            }
            id="auth-amount"
            placeholder="Destination"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
          />
        </div>
        <div className="report-form resend">
          <label htmlFor="auth-amount">Attention</label>
          <input
            className="proview-input"
            id="auth-amount"
            placeholder="Attention"
            value={attention || ""}
            onChange={(e) => setAttention(e.target.value)}
          />
        </div>
        <div>
          <button
            className="proview-button cancel left"
            onClick={handleCloseModal}
            type="button"
          >
            Cancel
          </button>
          <button
            className="proview-button right"
            type="button"
            onClick={
              isQbSync() ? () => setShowModalConfirm(true) : handleSubmit
            }
            disabled={!canSubmit}
          >
            Resend
          </button>
        </div>
      </form>
      <QbSyncResendConfirm
        showModalConfirm={showModalConfirm}
        setShowModalConfirm={setShowModalConfirm}
        submitResend={handleSubmit}
      />
    </Modal>
  );
};

export default Resend;
