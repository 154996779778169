// framework imports - 1st party
import React, { useEffect, useState } from "react";

// lib imports - 3rd party
import { CSSTransition, TransitionGroup } from "react-transition-group";
import * as notify from "../notify";

// app imports
import TransactionInformation from "./Details/TransactionInformation";
import Reason from "./Details/Reason";
import BillingInformation from "./Details/BillingInformation";
import PlcInformation from "./Details/PlcInformation";
import PaymentInformation from "./Details/PaymentInformation";
import TransactionNotes from "./Details/TransactionNotes";
import Resend from "./HelperComponents/Resend";
import Deny from "./Details/Deny";
import * as Auth from "../authService";
import * as api from "../api";
import "../stylesheets/toastNotification.css";
import "../stylesheets/TransactionDetails.css";
import backButton from "../icons/care-left-title.svg";
import print from "../icons/print.svg";
import send from "../icons/send@3x.png";
import { Link } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import withRouter from "./HelperComponents/WithRouter";

const TransactionDetails = (props) => {
  //Fields to make the call to update Notify table
  let type = "";
  let destination = "";
  let attention = "";

  const [modality, setModality] = useState("");
  const [agentId, setAgentId] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [csn, setCsn] = useState("");
  const [plcId, setPlcId] = useState("");
  const [plc, setPlc] = useState([]);
  const [existingPlc, setExistingPlc] = useState([]);
  const [paymentState, setPaymentState] = useState({
    preCapture: false,
    litle: false,
    captured: false,
  });
  const [status, setStatus] = useState("INCOMPLETE");
  const [syncStatus, setSyncStatus] = useState("");

  const [denyInfo, setDenyInfo] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showUndoModal, setShowUndoModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [changeType, setChangeType] = useState("Unapprove");
  const [receipt, setReceipt] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [cartId, setCartId] = useState("");
  const [transactionId, setTransactionId] = useState(props.params.id);
  const [recurringId, setRecurringId] = useState(
    props.location.state?.recurringPaymentId
  );
  const [prevScreen, setPrevScreen] = useState(
    props.location.state?.prevScreen
  );
  const [writeNote, setWriteNote] = useState(props.location.state?.writeNote);

  useEffect(() => {
    notify.check(props.location.state);
    props.navigate(".", {
      state: {
        type: "",
        message: "",
        transactionId: transactionId,
        recurringPaymentId: recurringId,
        prevScreen: prevScreen,
        writeNote: writeNote,
      },
    });
  }, [props.navigate]);

  // Print page if click on print icon
  const handlePrint = () => {
    //open new window to print data
    var printWindow = window.open(
      "",
      "Payment Confirmation",
      "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes"
    );
    if (!printWindow) {
      alert(
        "Please disable your pop-up blocker in your browser and try again."
      );
    } else {
      printWindow.document.body.innerHTML = receipt;
    }
  };

  // Get Notify Info for Notification request
  const getNotifyInfo = () => {
    api.getResendDropdown(transactionId).then((x) => {
      if (x.status === 200 || x.status === 201) {
        //Setting the values from Notify Info to update Notify table

        if (x.data.length === 3) {
          type = x.data[2].value;
          destination = x.data[2].notifyDest;
          attention = x.data[2].notifyAttn;
        }

        if (type !== null && type === "wir") {
          handleWireNotify();
        } else {
          window.location.reload();
        }
      }
    });
  };

  const handleWireNotify = () => {
    api
      .sendNotification(transactionId, type, destination, attention)
      .then((x) => {
        if (x.status === 200 || x.status === 201) {
          window.location.reload();
        } else {
          notify.error("Notification Failed for reference #" + transactionId);
          window.location.reload();
        }
      });
  };

  // When clicking approve,
  // call api to approve,
  // then redirect to transaction summary screen
  const handleApprove = () => {
    api.approveTransactions([transactionId]).then((x) => {
      // if approve was successful
      if (x.status === 200 || x.status === 201) {
        props.navigate(".", {
          // add to a notification to state
          // so on refresh Details knows to display a notification
          state: {
            type: "success",
            message: "Approved transaction " + transactionId,
            transactionId: transactionId,
            prevScreen: prevScreen,
            writeNote: writeNote,
            recurringPaymentId: recurringId,
          },
        });
        getNotifyInfo();
      } else {
        notify.error("Approval failed");
      }
    });
  };

  const canBailChange = () => {
    return (
      denyInfo.segmentType === "CB" &&
      (denyInfo.isRemoteBail || denyInfo.allowsRemoteBail)
    );
  };

  const showDenyOptions = () => {
    if (Object.keys(denyInfo).length > 0) {
      return (
        (Auth.canFinanceOverride() &&
          denyInfo.confirmationStatus !== "ECHECKPENDING" &&
          denyInfo.confirmationStatus !== "INCOMPLETE") ||
        (Auth.canAdmin() &&
          (denyInfo.confirmationStatus === "READY" ||
            denyInfo.confirmationStatus === "PAID" ||
            canBailChange())) ||
        (Auth.canTransactionDeny() && (status === "READY" || canBailChange()))
      );
    }
  };

  //checks if admin or finance override user can see ip address
  const showAdminUserIpAddress = () => {
    return Auth.canFinanceOverride() || Auth.canAdmin();
  };

  //checks if user have privileges to resend notes
  const showIfUserHasPrivilege = () => {
    return (
      (Auth.canFinanceOverride() ||
        Auth.canAdmin() ||
        Auth.canAgencyEdit() ||
        Auth.canAgencyAdmin() ||
        Auth.canPlcResend()) &&
      status !== "INCOMPLETE"
    );
  };

  // handle opening Resend/Note modal
  const handleResendModal = () => {
    setShowModal(true);
  };

  // onclick, open deny reason modal
  const handleOpenUndoModal = (type) => {
    setShowUndoModal(true);
    setChangeType(type);
  };

  // On initial load
  useEffect(() => {
    // if clicked on "Note" button on summary,
    // redirect to bottom of details screen
    if (writeNote) {
      window.scrollTo(0, document.body.scrollHeight);
      // otherwise, redirect to top of details screen
    } else {
      window.scrollTo(0, 0);
    }
    // main transaction
    api.getTransaction(transactionId).then((x) => {
      // if results found
      if (x) {
        // set header values
        setModality(x.modality.charAt(0).toUpperCase() + x.modality.slice(1));
        setAgentId(x.agentId);

        setIpAddress(x.ipAddress);
        setCsn(x.csn ? x.csn : "N/A");
        // set PLC Information
        setPlc([
          { label: "PLC", info: x.plc.plcId },
          { label: "Name", info: x.plc.name },
          { label: "Address", info: x.plc.address1 },
          { label: "City", info: x.plc.city },
          { label: "State", info: x.plc.st },
          { label: "Zip Code	", info: x.plc.zip },
          { label: "Phone #", info: x.plc.publicPhoneNumber },
          { label: "Account Type", info: x.plc.accountType },
        ]);
        setExistingPlc({
          id: x.plc.plcId,
          name: x.plc.name,
          accountType: x.plc.accountType,
        });
        setPlcId(x.plc.plcId);
        // Get info for dynamic deny options
        setDenyInfo({
          paymentStatus: x.paymentStatus,
          confirmationStatus: x.confirmationStatus,
          segmentType: x.plc.segmentType,
          isRemoteBail: x.remoteBail,
          allowsRemoteBail: x.plc.allowsRemoteBail ? true : false,
          authorized: x.authorized,
          isAutoRecon: x.plc.isAutoRecon,
          complete: x.complete,
        });
        setStatus(x.confirmationStatus);
        setSyncStatus(x.syncStatus);
        setPaymentState({
          preCapture: x.preCapture,
          litle: x.litle,
          captured: x.captured,
        });
        setPaymentType(
          x.paymentAuthInfoList.length > 0
            ? x.paymentAuthInfoList[0].paymentType
            : []
        );
        if (
          x.paymentAuthInfoList.length > 0 &&
          x.paymentAuthInfoList[0].paymentMethod
        ) {
          setPaymentMethod(x.paymentAuthInfoList[0].paymentMethod);
        }
        setCartId(x.cartId);
      }
    });

    //load receipt to print
    api.getTransactionReceipt(transactionId).then((x) => {
      setReceipt(x.data);
    });
  }, []);

  return (
    <TransitionGroup appear={true} enter={false} exit={false}>
      <CSSTransition className="example" timeout={500}>
        <main className="main-background transaction-details">
          <div className="main-component">
            <div id="table-header">
              <div id="details-label">
                <button
                  className="back-button"
                  onClick={() => {
                    props.navigate(prevScreen, {
                      state: {},
                    });
                  }}
                >
                  <img
                    alt="Back Button"
                    src={backButton}
                    id="trans-details-icon"
                    className="hoverable"
                  />
                </button>

                <div id="details-label-name">Transaction Details</div>
              </div>
              <div className="approve-deny-buttons">
                {showDenyOptions() ? (
                  <Deny
                    denyInfo={denyInfo}
                    referenceId={transactionId}
                    paymentMethod={paymentMethod}
                  />
                ) : null}

                {Auth.canFinanceOverride() &&
                status === "PAID" &&
                !paymentMethod.includes("EMV") ? (
                  <button
                    className="proview-button margins"
                    onClick={(e) => handleOpenUndoModal("cancelNoRefund")}
                  >
                    Cancel No Refund
                  </button>
                ) : null}

                {Auth.canFinanceOverride() &&
                !paymentMethod.includes("EMV") &&
                ((paymentType === "ECHECK" && status === "ECHECKPENDING") ||
                  (paymentType === "CREDIT_CARD" &&
                    (status === "READY" || status === "APPROVED"))) ? (
                  <button
                    className="proview-button margins"
                    onClick={(e) => handleOpenUndoModal("forceCapture")}
                  >
                    Force Capture
                  </button>
                ) : null}

                {!paymentState.preCapture &&
                (Auth.canAdmin() ||
                  Auth.canFinanceOverride() ||
                  Auth.canAmountModify() ||
                  Auth.canModify()) &&
                denyInfo.confirmationStatus === "READY" ? (
                  <button
                    className="proview-button margins right"
                    onClick={handleApprove}
                  >
                    Approve
                  </button>
                ) : null}
                {denyInfo.confirmationStatus === "DENIED" &&
                denyInfo.complete &&
                (Auth.canFinanceOverride() ||
                  (Auth.canAgencyAdmin() && paymentMethod.includes("EMV"))) ? (
                  <button
                    className="proview-button margins right"
                    onClick={(e) => handleOpenUndoModal("Undeny")}
                  >
                    Rejection Cancellation
                  </button>
                ) : null}
                {!paymentState.preCapture &&
                Auth.canModify() &&
                denyInfo.confirmationStatus === "APPROVED" &&
                denyInfo.complete ? (
                  <button
                    className="proview-button margins right"
                    onClick={(e) => handleOpenUndoModal("Unapprove")}
                  >
                    Undo Approval
                  </button>
                ) : null}
              </div>
            </div>
            <div className="content details">
              <div className="small-header details">
                <ul>
                  <li>
                    <div>
                      Modality <strong>{modality}</strong>
                    </div>
                  </li>
                  {agentId ? (
                    <li>
                      <div>
                        Agent ID <strong>{agentId}</strong>
                      </div>
                    </li>
                  ) : null}
                  {showAdminUserIpAddress() ? (
                    <li>
                      <div>
                        IP Address <strong>{ipAddress}</strong>
                      </div>
                    </li>
                  ) : null}
                  <li>
                    <div>
                      CSN <strong>{csn}</strong>
                    </div>
                  </li>
                </ul>
                <div className="details-icons">
                  {showIfUserHasPrivilege() ? (
                    <button onClick={handleResendModal}>
                      <img
                        alt="Send Icon"
                        className="send-icon hoverable"
                        src={send}
                      />
                    </button>
                  ) : null}
                  {receipt != null && receipt !== "" ? (
                    <button onClick={handlePrint}>
                      <img
                        alt="Print Icon"
                        className="print-icon hoverable"
                        src={print}
                      />
                    </button>
                  ) : null}
                </div>
              </div>
              <hr />
              <div>
                <TransactionInformation
                  referenceId={transactionId}
                  plcId={plcId}
                  cartId={cartId}
                  prevScreen={prevScreen}
                  recurringPaymentId={recurringId}
                />
                <BillingInformation
                  referenceId={transactionId}
                  status={status}
                  syncStatus={syncStatus}
                />
              </div>
              {recurringId ? (
                <div style={{ padding: "15px 8px" }}>
                  <Link
                    to={{
                      pathname: "/recurring/" + recurringId,
                      state: {
                        recurringPaymentId: recurringId,
                        transactionId: transactionId,
                        prevScreen: "/transaction/" + transactionId,
                        fromTransactionDetails: true,
                      },
                    }}
                  >
                    {" "}
                    Go to Recurring Payment Details
                  </Link>
                </div>
              ) : null}
              <hr />
              <PlcInformation
                referenceId={transactionId}
                plc={plc}
                existingPlc={existingPlc}
                status={status}
              />
              <PaymentInformation
                referenceId={transactionId}
                status={status}
                paymentState={paymentState}
              />
              <hr />
              <TransactionNotes referenceId={transactionId} plcId={plcId} />
            </div>
          </div>
        </main>
      </CSSTransition>
      <Resend
        showModal={showModal}
        transactionId={transactionId}
        recurringPaymentId={recurringId}
        setShowModal={(e) => setShowModal(e)}
      />
      <Reason
        changeType={changeType}
        showModal={showUndoModal}
        referenceId={transactionId}
        setShowModal={(e) => setShowUndoModal(e)}
        setShowDropdown={(e) => setShowDropdown(e)}
      />
      <ToastContainer transition={Slide} />
    </TransitionGroup>
  );
};

export default withRouter(TransactionDetails);
