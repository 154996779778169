// framework imports - 1st party
import React from "react";
import {
  Route,
  Routes as Switch,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import ReactGA from "react-ga";

import TransactionSummary from "./TransactionSummary";
import TransactionManifest from "./TransactionManifest";
import TransactionDetails from "./TransactionDetails";
import TransactionHistory from "./TransactionHistory";
import PaymentAuthorization from "./PaymentAuthorization";
import ReconPaymentReports from "./ReconPaymentReports";
import PaymentExclusion from "./PaymentExclusion";
import NotFound from "./NotFound";
import Login from "./Login";
import ChangePasswordPage from "./ChangePasswordPage";
import RecurringPayment from "./RecurringPayment";
import PlcMigrationDates from "./PlcMigrationDates";
import RecurringPaymentDetails from "./RecurringPaymentDetails";
import TransactionRP from "./TransactionRP";
import SessionTimeout from "./SessionTimeout";
import * as Auth from "../authService";

// Main Application, header and footer are rendererd separately
// to make component more reactive
// all the files used here are in components folder
// for readability, each pages children components are in their respective subfolders
// along with generic components in their own HelperComponents folder
const App = () => {
  ReactGA.initialize("UA-137048100-1"); // Here we should use our GA id
  ReactGA.pageview(window.location.pathname + window.location.search);
  const moment = require("moment-timezone");
  moment.tz.setDefault("America/New_York");

  return (
    <Router>
      <div>
        <Header />
        <Switch>
          <Route
            exact
            path="/changepasswordpage"
            element={<ChangePasswordPage />}
          />
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/summary" element={<TransactionSummary />} />
          <Route
            exact
            path="/reports/history"
            element={<TransactionHistory />}
          />
          <Route
            exact
            path="/reports/authorization"
            element={<PaymentAuthorization />}
          />
          <Route
            exact
            path="/reports/reconpayment"
            element={<ReconPaymentReports />}
          />
          <Route exact path="/manifest" element={<TransactionManifest />} />
          <Route exact path="/payment" element={<PaymentExclusion />} />
          <Route exact path="/recurring" element={<RecurringPayment />} />
          <Route
            exact
            path="/recurring/:id"
            element={<RecurringPaymentDetails />}
          />
          <Route
            exact
            path="/recurring/:id/transaction"
            element={<TransactionRP />}
          />
          <Route
            exact
            path="/recurring/:recurring_id/transaction/:id"
            element={<TransactionDetails />}
          />
          <Route exact path="/migrationdates" element={<PlcMigrationDates />} />
          <Route
            exact
            path="/transaction/:id"
            element={<TransactionDetails />}
          />
          <Route path="*" element={<NotFound />} />
        </Switch>
        <SessionTimeout />
        <Footer />
      </div>
    </Router>
  );
};
export default App;
