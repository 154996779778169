import React from "react";
import "../../stylesheets/Row.css";
import withRouter from "../HelperComponents/WithRouter";
import moment from "moment";

const GenerateRow = (props) => {
  // given payload, determine format of JSON
  const getBillingType = (e) => {
    switch (e.paymentType) {
      case "CREDIT_CARD":
        return "creditCardInfo";
      case "PAYNEARME":
        return "payNearMeInfo";
      case "GREENDOT":
        return "greenDotInfo";
      case "ECHECK":
        return "echeckInfo";
      default:
        return "ERROR";
    }
  };
  const paymentType = getBillingType(props.row);

  // on row click, redirect to transaction details
  const loadTransaction = () => {
    if (!props.loading) {
      props.navigate("/transaction/" + props.row.transactionId, {
        state: {
          transactionId: props.row.transactionId,
          prevScreen: "/reports/authorization",
          writeNote: false,
        },
      });
    }
  };

  return (
    <tr className="row" onClick={loadTransaction}>
      <td className="text-center">
        <button className="transaction-id-button">
          {props.row.transactionId}
        </button>
      </td>
      <td className="text-center">
        {moment(props.row.authDatetime).format("M/DD/YY")}
      </td>
      <td className="text-center">
        {props.row[paymentType].firstName +
          " " +
          props.row[paymentType].lastName}
      </td>
      <td className="text-center">
        {props.row[paymentType].address.city +
          ", " +
          props.row[paymentType].address.state}
      </td>
      <td className="text-center">{props.row.textDetails}</td>
    </tr>
  );
};

export default withRouter(GenerateRow);
