// framework imports - 1st party
import React, { useState, useEffect } from "react";

// lib imports - 3rd party

// app imports
import Page from "../HelperComponents/Page";
import ExportCsv from "../HelperComponents/ExportCsv";
import Search from "./SummarySearch";
import Sort from "./SummarySort";
import Row from "./SummaryRow";
import * as api from "../../api";
import useDebounce from "../../useDebounce";
import moment from "moment";
import "../../stylesheets/Table.css";
import * as Auth from "../../authService";
import withRouter from "../HelperComponents/WithRouter";

// Table component for Transaction Summaries
const Table = (props) => {
  // retrieve summary filter from sessionStorage
  let transSummaryFilterObj = JSON.parse(
    sessionStorage.getItem("transSummaryFilter")
  );
  if (transSummaryFilterObj == null) {
    transSummaryFilterObj = {};
    transSummaryFilterObj["criteria"] = {};
    transSummaryFilterObj["pagination"] = {};
  }
  let criteria = transSummaryFilterObj["criteria"];
  let pagination = transSummaryFilterObj["pagination"];

  const [firstLoad, setFirstLoad] = useState(true); // first time for component loaded
  const [status, setStatus] = useState(criteria.status ? criteria.status : ""); // status field
  const [syncStatus, setSyncStatus] = useState(
    criteria.syncStatus ? criteria.syncStatus : ""
  ); // syncStatus field
  const [referenceId, setReferenceId] = useState(
    criteria.referenceId ? criteria.referenceId : ""
  ); // Ref field
  const [name, setName] = useState(criteria.name ? criteria.name : ""); // name field
  const [payment, setPayment] = useState(
    criteria.payment ? criteria.payment : ""
  ); // payment field
  const [plc, setPlc] = useState(criteria.plc ? criteria.plc : []); // plc dropdown values
  const [uuid, setUuid] = useState(criteria.uuid ? criteria.uuid : "");
  const [pageSize, setPageSize] = useState(
    pagination.pageSize
      ? pagination.pageSize
      : {
          //page size
          value: 10,
          label: "10 Results/Page",
        }
  );
  const [pageNum, setPageNum] = useState(
    pagination.pageNum ? pagination.pageNum : 0
  ); // page num
  const [numElements, setNumElements] = useState(0); // number of elements on page
  const [totalElements, setTotalElements] = useState(0); // total elements on query
  const [dates, setDates] = useState({
    //Date field
    start:
      criteria.referenceId && criteria.referenceId.startsWith("CART")
        ? ""
        : criteria.dates && criteria.dates.start
        ? moment(criteria.dates.start)
        : moment().subtract(1, "month"),
    end:
      criteria.referenceId && criteria.referenceId.startsWith("CART")
        ? ""
        : criteria.dates && criteria.dates.end
        ? moment(criteria.dates.end)
        : moment(),
  });
  const [payoutDates, setPayoutDates] = useState({
    //Payout Date field
    start:
      criteria.payoutDates && criteria.payoutDates.start
        ? moment(criteria.payoutDates.start)
        : null,
    end:
      criteria.payoutDates && criteria.payoutDates.end
        ? moment(criteria.payoutDates.end)
        : null,
  });
  const [transactions, setTransactions] = useState([]); // transaction info in rows
  const [sort, setSort] = useState(
    pagination.sort ? pagination.sort : { sortId: "created_at", asc: false }
  ); // current sort state
  const [infiniteScroll, setInfiniteScroll] = useState(1); // tracking infinite scroll
  const [canInfiniteScroll, setCanInfiniteScroll] = useState(true);
  // const [topChecked, setTopChecked] = useState(false); // whether top checkmark is checked
  const debounceName = useDebounce(name, 500); // debounce name input
  const debounceReferenceId = useDebounce(referenceId, 500); // debounce referenceID
  const debouncePayment = useDebounce(payment, 500); // debounce payment
  const debounceUuid = useDebounce(uuid, 500); // debounce uuid

  // Main query to retrieve transactions
  const getTransactions = (customPage = pageNum) => {
    // save summary filter to sessionStorage
    let transSummaryFilter = {};
    transSummaryFilter["criteria"] = {};

    if (referenceId.length === 8 && !isNaN(referenceId)) {
      transSummaryFilter["criteria"].referenceId = referenceId;
    } else if (
      referenceId.length >= 10 &&
      referenceId.startsWith("TX_") &&
      !isNaN(referenceId.split("TX_").pop())
    ) {
      transSummaryFilter["criteria"].referenceId = referenceId;
    } else if (referenceId.startsWith("CART")) {
      transSummaryFilter["criteria"].referenceId = referenceId;
    } else {
      transSummaryFilter["criteria"].referenceId = "";
    }

    transSummaryFilter["criteria"].plc = plc;
    transSummaryFilter["criteria"].dates = {
      start: dates.start ? dates.start.clone().toISOString() : null,
      end: dates.end ? dates.end.clone().toISOString() : null,
    };
    transSummaryFilter["criteria"].name = name;
    transSummaryFilter["criteria"].payoutDates = {
      start: payoutDates.start ? payoutDates.start.clone().toISOString() : null,
      end: payoutDates.end ? payoutDates.end.clone().toISOString() : null,
    };
    transSummaryFilter["criteria"].status = status;
    transSummaryFilter["criteria"].syncStatus = syncStatus;
    transSummaryFilter["criteria"].payment = payment;
    transSummaryFilter["criteria"].uuid =
      uuid.length < 5 || !uuid.startsWith("TX_") ? "" : uuid;

    transSummaryFilter["pagination"] = {};
    transSummaryFilter["pagination"].pageSize = pageSize;
    transSummaryFilter["pagination"].pageNum = pageNum;
    transSummaryFilter["pagination"].sort = sort;

    sessionStorage.setItem(
      "transSummaryFilter",
      JSON.stringify(transSummaryFilter)
    );
    return api.transactionSummary(
      debounceReferenceId,
      plc,
      dates.start,
      dates.end,
      payoutDates.start,
      payoutDates.end,
      status,
      syncStatus,
      debounceUuid,
      null,
      pageSize,
      customPage,
      sort,
      debounceName,
      debouncePayment
    );
  };

  // listen for scrolling for infinite scroll on Mobile
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [transactions, canInfiniteScroll]);

  // get and update the rows
  const retrieveInfo = () => {
    props.setSpinner(true);
    getTransactions().then((x) => {
      setTransactions(x.content);
      setTotalElements(x.totalElements);
      setNumElements(x.numberOfElements);
      props.setSpinner(false);
    });
  };

  /* If there's a change in search parameters, do a new fetch on page one */
  useEffect(() => {
    if (!firstLoad && pageNum !== 0) {
      setPageNum(0);
    } else if (
      (uuid.length < 5 || !uuid.startsWith("TX_")) &&
      uuid.length !== 0 &&
      !uuid.startsWith("CART")
    ) {
      // don't run query
    } else if (
      referenceId.length < 10 &&
      referenceId.length !== 8 &&
      referenceId.length !== 0 &&
      !referenceId.startsWith("CART")
    ) {
      // don't run query if reference is not right length
    } else if (
      referenceId.length >= 10 &&
      !referenceId.startsWith("TX_") &&
      !referenceId.startsWith("CART")
    ) {
      // don't run query
    } else if (
      referenceId.length >= 10 &&
      referenceId.startsWith("TX_") &&
      isNaN(referenceId.split("TX_").pop()) &&
      !referenceId.startsWith("CART")
    ) {
      // don't run query
    } else if (
      referenceId.length === 8 &&
      isNaN(referenceId) &&
      !referenceId.startsWith("CART")
    ) {
      // don't run query
    } else {
      retrieveInfo();
    }
  }, [
    debounceUuid,
    status,
    syncStatus,
    debounceReferenceId,
    plc,
    dates,
    payoutDates,
    sort,
    debounceName,
    debouncePayment,
  ]);

  /* No fetch on page change, NOT an input change */
  useEffect(() => {
    // only execute if there are already values
    // to prevent double loading on initial load
    if (transactions.length !== 0) {
      props.setSpinner(true);
      getTransactions().then((x) => {
        setTransactions(x.content);
        setTotalElements(x.totalElements);
        setNumElements(x.numberOfElements);
        props.setSpinner(false);
        props.handleRowsChecked([]);
        props.setTopChecked(false);
      });
    }
  }, [pageNum, pageSize]);

  useEffect(() => {
    // if hit approve button, refresh page
    if (props.approved) retrieveInfo();
  }, [props.approved]);

  useEffect(() => {
    // set it to false after component is loaded first time.
    setFirstLoad(false);
  }, []);

  // handle infinite scroll
  // for mobile, if reached the bottom of the screen, load 10 more rows
  const handleScroll = () => {
    // if mobile screen and at bottom of screen
    if (
      window.innerHeight + window.scrollY >=
        document.documentElement.offsetHeight &&
      window.innerWidth <= "600" &&
      canInfiniteScroll
    ) {
      setCanInfiniteScroll(false);
      getTransactions(infiniteScroll).then((x) => {
        setTransactions([...transactions, ...x.content]);
        setInfiniteScroll(infiniteScroll + 1);
        setCanInfiniteScroll(true);
      });
    }
  };

  // generate rows
  const getRows = () => {
    // if transaction undefined (error in retrieval)
    if (!transactions) {
      return (
        <tr>
          <td>
            <div id="no-results-found">Error in Search</div>
          </td>
        </tr>
      );
    }
    // if return some number of valid rows, display them normally
    else if (transactions.length > 0) {
      return transactions.map((transaction, i) => (
        <Row
          loading={props.loading}
          key={i}
          referenceId={transaction.transactionId}
          transaction={transaction}
          rowsChecked={props.rowsChecked}
          handleRowsChecked={(e) => {
            props.handleRowsChecked(e);
          }}
          handleTopChecked={handleTopChecked}
          retrieveInfo={(e) => retrieveInfo()}
        />
      ));
    }
    // if the page is on initial load, load fake data and blur
    else if (props.loading && transactions.length === 0) {
      return (
        <tr>
          <td>
            <div id="no-results-found">Loading...</div>
          </td>
        </tr>
      );
    }
    // otherwise, no results were found
    else {
      return (
        <tr>
          <td>
            <div id="no-results-found">No results found</div>
          </td>
        </tr>
      );
    }
  };

  // handling top checkmark checked
  const onTopChecked = () => {
    // if top is checked already clear them all
    if (props.topChecked) {
      props.handleRowsChecked([]);
      props.setTopChecked(false);
    }
    // if no rows checked, check all open rows
    else {
      const all_rows = transactions
        .filter((transaction) => transaction.confirmationStatus === "READY")
        .map((transaction) => transaction.transactionId);
      props.handleRowsChecked(all_rows);
      props.setTopChecked(true);
    }
  };

  // check top if any rows are checked
  const handleTopChecked = () => {
    if (props.rowsChecked.length > 0) {
      props.setTopChecked(true);
    } else {
      props.setTopChecked(false);
    }
  };

  return (
    <div className="content transaction-summary">
      <ExportCsv
        totalElements={totalElements}
        numElements={numElements}
        debounceReferenceId={debounceReferenceId}
        plc={plc}
        dates={dates}
        payoutDates={payoutDates}
        status={status}
        syncStatus={syncStatus}
        debounceUuid={debounceUuid}
        pageSize={pageSize}
        pageNum={pageNum}
        sort={sort}
        debounceName={debounceName}
        debouncePayment={debouncePayment}
        page="summary"
      />
      <table>
        <tbody
          className={
            Auth.hasQuickbooksPlc() === "true" ||
            Auth.hasQuickbooksPlc() === "null"
              ? "transaction-sum-sync"
              : "transaction-sum"
          }
        >
          <Search
            dates={dates}
            setDates={(e) => setDates({ start: e.start, end: e.end })}
            payoutDates={payoutDates}
            setPayoutDates={(e) =>
              setPayoutDates({ start: e.start, end: e.end })
            }
            plc={plc}
            setPlc={(e) => setPlc(e)}
            onReferenceId={(e) => setReferenceId(e)}
            referenceId={referenceId}
            onName={(e) => setName(e)}
            name={name}
            onPayment={(e) => setPayment(e)}
            payment={payment}
            onStatus={(e) => setStatus(e)}
            status={status}
            onSyncStatus={(e) => setSyncStatus(e)}
            syncStatus={syncStatus}
            onUuid={(e) => setUuid(e)}
            uuid={uuid}
            pageSize={pageSize}
            totalElements={totalElements}
          />

          <Sort
            sort={sort}
            setSort={(e) => setSort(e)}
            onTopChecked={onTopChecked}
            topChecked={props.topChecked}
            rowsChecked={props.rowsChecked}
            handleRowsChecked={(e) => {
              props.handleRowsChecked(e);
            }}
          />
          {getRows()}
        </tbody>
      </table>
      <Page
        summary={true}
        pageSize={pageSize}
        onPageSize={(e) => {
          let firstData = pageNum * pageSize.value + 1;
          let newPageNum = Math.floor(firstData / e.value);
          setPageNum(newPageNum);
          setPageSize(e);
        }}
        pageNum={pageNum}
        onPageChange={(e) => setPageNum(e)}
        numElements={numElements}
        totalElements={totalElements}
        loading={props.loading}
        table="summary"
      />
      <p className="text-note">
        {props.loading
          ? ""
          : "(*) Transactions marked with * are part of Recurring Payments"}
      </p>
      <p className="text-note">
        {props.loading
          ? ""
          : "(**) Transactions marked with ** are part of Shopping Cart"}
      </p>
    </div>
  );
};
export default withRouter(Table);
