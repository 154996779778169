import withRouter from "../HelperComponents/WithRouter";
import moment from "moment";
import React from "react";
import "../../stylesheets/Row.css";
import "../../stylesheets/GenerateReport.css";

const MigrationRow = (props) => {
  return (
    <tr className="row">
      <td>{props.plcMigration.plcId}</td>
      <td>{props.plcMigration.plcName}</td>
      <td>
        {props.plcMigration.start
          ? moment(props.plcMigration.start).add(5, "hours").format("M/DD/YYYY")
          : null}
      </td>
      <td>
        {props.plcMigration.end
          ? moment(props.plcMigration.end).add(5, "hours").format("M/DD/YYYY")
          : null}
      </td>
    </tr>
  );
};

export default withRouter(MigrationRow);
