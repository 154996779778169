// framework imports - 1st party
import React, { useEffect, useState } from "react";

// lib imports - 3rd party
import { ToastContainer, Slide } from "react-toastify";

// app imports
import * as Auth from "../authService";
import Spinner from "./HelperComponents/Spinner";
import ExclusionsMain from "./Exclusions/ExclusionsMain";
import paymentExclusionIcon from "../icons/rectangle@3x.png";
import "../stylesheets/PageBody.css";

// Payment Exclusion page
const PaymentExclusion = (props) => {
  const [loading, setLoading] = useState(false);

  // if don't have finance privileges, can't be on page
  if (!Auth.canFinanceOverride()) {
    props.navigate("/summary");
  }

  // if small screensize (i.e. on mobile)
  // redirect to summary
  // because this page is not written
  // to be mobile responsive
  const handleResize = (e) => {
    if (window.innerWidth < 600) {
      props.navigate("/summary");
    }
  };
  // listener for resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main className="main-background">
      <div className="main-component">
        <div id="table-header">
          <div id="details-label">
            <img
              alt="Govpay Logo"
              src={paymentExclusionIcon}
              id="payment-exclusion-icon"
            />
            <div id="details-label-name">Payment Exclusions</div>
          </div>
          <Spinner customClass={"sk-fading-circle summary"} loading={loading} />
        </div>
        <div>
          <ExclusionsMain loading={loading} setLoading={(e) => setLoading(e)} />
        </div>
      </div>
      <ToastContainer transition={Slide} />
    </main>
  );
};

export default PaymentExclusion;
