// framework imports - 1st party
import React, { useState } from "react";

// lib imports - 3rd party
import Modal from "react-modal";

const ChangeApprovedDateBeforeConfirm = (props) => {
  const [
    showChangeApprovedDateBeforeConfirmModal,
    setShowChangeApprovedDateBeforeConfirmModal,
  ] = useState(false);

  if (
    showChangeApprovedDateBeforeConfirmModal !==
    props.showChangeApprovedDateBeforeConfirmModal
  ) {
    setShowChangeApprovedDateBeforeConfirmModal(
      props.showChangeApprovedDateBeforeConfirmModal
    );
  }

  const handleChangeApprovedDateBeforeConfirmModal = () => {
    props.setShowChangeApprovedDateBeforeConfirmModal(false);
  };
  const handleCloseChangeApprovedDateBeforeConfirmAndCleanDateModal = () => {
    props.setApprovalDate("");
    props.setShowChangeApprovedDateBeforeConfirmModal(false);
  };

  return (
    <Modal
      isOpen={showChangeApprovedDateBeforeConfirmModal}
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => {
        props.setShowChangeApprovedDateBeforeConfirmModal(false);
      }}
      className="Modal cancel-confirm-approve"
      overlayClassName="Overlay"
      ariaHideApp={false}
    >
      <h3>
        Warning this will set the Approval date to before the transaction was
        created. Do you wish to continue?
      </h3>
      <div>
        <button
          className="proview-button cancel left"
          onClick={handleCloseChangeApprovedDateBeforeConfirmAndCleanDateModal}
        >
          No
        </button>
        <button
          className="proview-button right"
          onClick={handleChangeApprovedDateBeforeConfirmModal}
        >
          Yes
        </button>
      </div>
    </Modal>
  );
};

export default ChangeApprovedDateBeforeConfirm;
