import React, { useEffect, useState } from "react";
import Page from "../HelperComponents/Page";
import Search from "./MigrationSearch";
import MigrationSort from "./MigrationSort";
import * as api from "../../api";
import MigrationRow from "./MigrationRow";

const MigrationTable = (props) => {
  let migrationFilterObj = JSON.parse(
    sessionStorage.getItem("migrationFilter")
  );
  if (migrationFilterObj == null) {
    migrationFilterObj = {};
    migrationFilterObj["criteria"] = {};
    migrationFilterObj["pagination"] = {};
  }
  let pagination = migrationFilterObj["pagination"];

  const [id] = useState("");
  const [plc, setPlc] = useState("");
  const [start] = useState("");
  const [end] = useState("");
  const [createdAt] = useState("");
  const [updatedAt] = useState("");
  const [lastModifiedBy] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  const [name] = useState("");

  const [pageSize, setPageSize] = useState(
    pagination.pageSize
      ? pagination.pageSize
      : {
          //page size
          value: 10,
          label: "10 Results/Page",
        }
  );
  const [pageNum, setPageNum] = useState(
    pagination.pageNum ? pagination.pageNum : 0
  ); // page num
  const [numElements, setNumElements] = useState(0); // number of elements on page
  const [totalElements, setTotalElements] = useState(0); // total elements on query

  const [plcMigrations, setPlcMigrations] = useState([]); // plcMigration info in rows

  const getPlcMigration = (customPage = pageNum) => {
    let migrationFilter = {};
    migrationFilter["pagination"] = {};
    migrationFilter["pagination"].pageSize = pageSize;
    migrationFilter["pagination"].pageNum = pageNum;
    sessionStorage.setItem("migrationFilter", JSON.stringify(migrationFilter));

    return api.plcMigrationSummary(
      id,
      plc,
      start,
      end,
      createdAt,
      updatedAt,
      lastModifiedBy,
      name,
      pageSize,
      customPage
    );
  };

  // get and update the rows
  const retrieveInfo = () => {
    props.setSpinner(true);
    getPlcMigration().then((x) => {
      setPlcMigrations(x.content);
      setTotalElements(x.totalElements);
      setNumElements(x.numberOfElements);
      props.setSpinner(false);
    });
  };

  /* If there's a change in search parameters, do a new fetch on page one */
  useEffect(() => {
    if (!firstLoad && pageNum !== 0) {
      setPageNum(0);
    }
    retrieveInfo();
  }, [plc]);

  /* No fetch on page change, NOT an input change */
  useEffect(() => {
    if (plcMigrations.length !== 0) {
      retrieveInfo();
    }
  }, [pageNum, pageSize]);

  useEffect(() => {
    // set it to false after component is loaded first time.
    setFirstLoad(false);
  }, []);

  const getRows = () => {
    // if plcMigration undefined (error in retrieval)
    if (!plcMigrations) {
      return (
        <tr>
          <td>
            <div id="no-results-found">Error in Search</div>
          </td>
        </tr>
      );
    }
    // if return some number of valid rows, display them normally
    else if (plcMigrations.length > 0) {
      return plcMigrations.map((plcMigration, i) => (
        <MigrationRow
          loading={props.loading}
          key={i}
          index={i}
          plcMigration={plcMigration}
          retrieveInfo={(e) => retrieveInfo()}
        />
      ));
    }
    // if the page is on initial load, load FAKE data and blur
    else if (props.loading && plcMigrations.length === 0) {
      return Array.from(Array(10), (_, i) => (
        <tr key={i}>
          <td>
            <div id="no-results-found">Loading...</div>
          </td>
        </tr>
      ));
    }
    // otherwise, no results were found
    else {
      return (
        <tr>
          <td>
            <div id="no-results-found">No results found</div>
          </td>
        </tr>
      );
    }
  };

  return (
    <div className="content plc-migration">
      <table>
        <tbody className="plc-migration">
          <Search
            plc={plc}
            setPlc={(e) => setPlc(e)}
            pageSize={pageSize}
            totalElements={totalElements}
          />
          <MigrationSort />
          {getRows()}
        </tbody>
      </table>
      <Page
        pageSize={pageSize}
        onPageSize={(e) => {
          let firstData = pageNum * pageSize.value + 1;
          let newPageNum = Math.floor(firstData / e.value);
          setPageNum(newPageNum);
          setPageSize(e);
        }}
        pageNum={pageNum}
        onPageChange={(e) => setPageNum(e)}
        numElements={numElements}
        totalElements={totalElements}
        loading={props.loading}
        table="migration"
      />
    </div>
  );
};

export default MigrationTable;
