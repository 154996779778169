// framework imports - 1st party
import React, { useState } from "react";

// lib imports - 3rd party
import Modal from "react-modal";

// app imports
import deleteIcon from "../../icons/delete.svg";
import "../../stylesheets/Row.css";
import * as notify from "../../notify";
import * as api from "../../api";

const ExclusionsRow = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [reason, setReason] = useState("");

  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // determine if deletion successful,
  // if successful, reload rows
  const handleSubmit = () => {
    api.deleteEntry(props.row.id, reason).then((x) => {
      if (x.status === 200) {
        notify.success("Deleted exclusion " + props.row.id);
        setReason("");
        props.retrieveInfo();
      } else {
        notify.error("Failed to delete exclusion " + props.row.id);
      }
    });
    setShowModal(false);
  };

  return (
    <tr className="row" key={props.i}>
      <td className="text-left">
        <button className="exclusion-delete-button" onClick={handleOpenModal}>
          <img className="delete-icon" alt="Delete Icon" src={deleteIcon} />
          <span className="remove-text">Remove</span>
        </button>
      </td>
      <td className="text-left">{props.row.id}</td>
      <td className="text-left">{props.row.firstName}</td>
      <td className="text-left">{props.row.lastName}</td>
      <Modal
        isOpen={showModal}
        shouldCloseOnOverlayClick={true} // that's it!
        onRequestClose={() => {
          setShowModal(false);
        }}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
      >
        <h3>Reason</h3>
        <textarea
          className="proview-input details"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        <div>
          <button
            className="proview-button margins cancel left"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="proview-button margins right"
            onClick={handleSubmit}
            disabled={!reason}
          >
            Delete
          </button>
        </div>
      </Modal>
    </tr>
  );
};

export default ExclusionsRow;
