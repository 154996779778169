import React, { Component } from "react";

class SsnInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actualSsn: "",
      maskedSsn: props.ss,
      originalMaskedSsn: props.ss,
      customCopy: props.customCopy,
      index: props.index,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
  }

  onUpdate = () => {
    this.props.onUpdate(this.state.actualSsn);
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.customCopy.fields[this.state.index].value = this.state.maskedSsn;
    this.props.setObject(this.state.customCopy);
  };

  handleChange(event) {
    const prevValue = this.state.maskedSsn;
    const newValue = event.target.value;
    let actual = this.state.actualSsn;

    if (newValue.length > 11) {
      return;
    }

    // typing forward
    if (newValue.length > prevValue.length) {
      let newChar = newValue.split("").pop();
      actual = `${actual}${newChar}`;
    }
    // backspacing / deleting
    else {
      const charsRemovedCount = prevValue.length - newValue.length;
      actual = actual.substr(0, actual.length - charsRemovedCount);
    }

    this.setState({
      actualSsn: actual,
      maskedSsn: this.starredMask(actual),
    });

    this.props.change(`owner_${this.props.params.idx}_ssn`, actual);
  }

  handleBlur() {
    const re = /^[0-9\b]+$/;

    if (
      this.state.actualSsn.length < 9 ||
      re.test(this.state.actualSsn) === false
    ) {
      this.setState({
        actualSsn: "",
        maskedSsn: this.state.originalMaskedSsn,
      });
    } else {
      this.onUpdate();
    }
  }

  starredMask(ssn, showLast4 = true) {
    let valueHidden = ssn.replace(/[\d]/g, "X");

    if (valueHidden.length <= 3) {
      return valueHidden;
    }

    if (valueHidden.length <= 5) {
      return valueHidden.slice(0, 3) + "-" + valueHidden.slice(3, 5);
    }

    if (showLast4) {
      return (
        valueHidden.slice(0, 3) +
        "-" +
        valueHidden.slice(3, 5) +
        "-" +
        ssn.substr(5)
      );
    } else {
      return (
        valueHidden.slice(0, 3) +
        "-" +
        valueHidden.slice(3, 5) +
        "-" +
        valueHidden.slice(5, 9)
      );
    }
  }

  render() {
    return (
      <div>
        <div>
          <input
            ref={(input) => {
              this.ssnInput = input;
            }}
            name="ssn"
            type="tel"
            value={this.state.maskedSsn}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
          />

          <div>(### - ## - ####)</div>
        </div>
      </div>
    );
  }
}

export default SsnInput;
