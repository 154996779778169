// framework imports - 1st party
import React from "react";

// lib imports - 3rd party
import Select from "react-select";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

// app imports
import "../../stylesheets/ReactDates.css";
import "../../stylesheets/GenerateReport.css";
import "../../stylesheets/ExclusionsMain.css";
import ReactDates from "../HelperComponents/ReactDates";

const GenerateAuth = (props) => {
  const paymentType = [
    { value: "EQUALS", label: "Equals" },
    { value: "BETWEEN", label: "Between" },
    { value: "GREATER_THAN", label: "Greater than" },
    { value: "LESS_THAN", label: "Less than" },
  ];
  const auth_amount = () => {
    // dynamically determine
    // which inputs you need
    // for "Auth Amount"
    // based on the Auth Amount
    // selected, either
    // Greater than, Between, Less than, Equal
    switch (props.paymentType.label) {
      case "Between":
        return (
          <span>
            <div className="report-form select payment-auth">
              <label htmlFor="greater-than">Greater than</label>
              <input
                className="proview-input long"
                id="greater-than"
                placeholder="Greater than"
                value={props.authGreater}
                onChange={(e) => props.setAuthGreater(e.target.value)}
              />
            </div>
            <div className="report-form select payment-auth">
              <label htmlFor="less-than">Less than</label>
              <input
                className="proview-input long"
                id="less-than"
                placeholder="Less than"
                value={props.authLess}
                onChange={(e) => props.setAuthLess(e.target.value)}
              />
            </div>
          </span>
        );
      default:
        return (
          <div className="report-form select payment-auth">
            <label htmlFor="auth-amount">{props.paymentType.label}</label>
            <input
              className="proview-input long"
              id="auth-amount"
              placeholder={props.paymentType.label}
              value={props.authAmount}
              onChange={(e) => props.setAuthAmount(e.target.value)}
            />
          </div>
        );
    }
  };

  // on submit, call submit function in parent component
  // (PaymentAuthorization)
  const handleSubmit = (e) => {
    e.preventDefault();
    props.submitted(e);
  };

  return (
    <div className="generate-report">
      <form name="message" onSubmit={(e) => handleSubmit(e)}>
        <div className="report-form payment-auth">
          <label htmlFor="reference-num">Reference #</label>
          <input
            className="proview-input long"
            id="reference-num"
            maxLength="11"
            type="text"
            placeholder="Ref #"
            value={props.refId}
            onChange={(e) => props.setRefId(e.target.value)}
          />
        </div>
        <div className="report-form payment-auth">
          <label htmlFor="card-last-four">Card Last Four</label>
          <input
            className="proview-input long"
            id="card-last-four"
            maxLength="4"
            type="text"
            placeholder="Card Last Four"
            value={props.cardNum}
            onChange={(e) => props.setCardNum(e.target.value)}
          />
        </div>
        <div className="report-form payment-auth">
          <label htmlFor="auth-code">Auth Code</label>
          <input
            className="proview-input long"
            id="auth-code"
            maxLength="8"
            type="text"
            placeholder="Auth Code"
            value={props.authCode}
            onChange={(e) => props.setAuthCode(e.target.value)}
          />
        </div>
        <div className="report-form payment-auth">
          <label htmlFor="first-name">First Name</label>
          <input
            className="proview-input long"
            id="first-name"
            type="text"
            placeholder="First Name"
            value={props.firstName}
            onChange={(e) => props.setFirstName(e.target.value)}
          />
        </div>
        <div className="report-form payment-auth">
          <label htmlFor="last-name">Last Name</label>
          <input
            className="proview-input long"
            id="last-name"
            placeholder="Last Name"
            value={props.lastName}
            onChange={(e) => props.setLastName(e.target.value)}
          />
        </div>
        <div className="report-form payment-auth">
          <label htmlFor="date-range">Date Range</label>
          <ReactDates
            id="date-range"
            startId="start-search-page"
            endId="end-search-page"
            name="date-range"
            dates={props.dates}
            setDates={(e) => props.setDates(e)}
          />
        </div>
        <div className="report-form  select payment-auth">
          <label htmlFor="react-select-2-input">Auth Amount</label>
          <Select
            id="history-search"
            defaultValue={paymentType[0]}
            options={paymentType}
            className="proview-select bigger"
            classNamePrefix="select"
            onChange={(e) => props.onPaymentType(e)}
            value={props.paymentType}
            name="group-transactions"
          />
        </div>
        {auth_amount()}
        <button className={"proview-button report"} type="submit">
          Generate Report
        </button>
      </form>
    </div>
  );
};

export default GenerateAuth;
