// framework imports - 1st party
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import withRouter from "../HelperComponents/WithRouter";

// app imports
import { ReactComponent as Caret } from "../../icons/caret-down.green.svg";
import Reason from "./Reason";
import RejectTransactionConfirm from "../HelperComponents/RejectTransactionConfirm";
import * as Auth from "../../authService";
import * as api from "../../api";
import * as notify from "../../notify";

// deny dropdown and modal
const Deny = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [
    showRejectTransactionConfirmModal,
    setShowRejectTransactionConfirmModal,
  ] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [changeType, setChangeType] = useState("");

  // onclick, open deny reason modal
  const handleOpenModal = (type) => {
    setShowModal(true);
    setChangeType(type);
  };

  const handleRejectTransactionConfirmModal = () => {
    if (showDropdown) {
      setShowRejectTransactionConfirmModal(false);
    } else {
      setShowRejectTransactionConfirmModal(true);
    }
  };

  // handles closing out auth if you click outside of it
  const handleCloseDropdown = (e) => {
    setShowDropdown(false);
  };

  // listen for mousedown to close Deny
  useEffect(() => {
    document.addEventListener("mouseup", handleCloseDropdown);
    return () => {
      document.removeEventListener("mouseup", handleCloseDropdown);
    };
  }, []);

  // if selecting a remote bail option
  // toggle
  const handleBail = (boolean) => {
    const pathname = props.location.state.prevScreen;
    const response = {
      type: "success",
      message: "Successfully changed bail",
    };
    if (!props.denyInfo.authorized) {
      notify.error(
        "The authorization is not in a state where bail can be changed"
      );
    } else {
      api.toggleBail(props.referenceId, boolean).then((x) => {
        if (x.status === 200 || x.status === 201) {
          props.navigate(pathname, { state: response });
        } else {
          notify.error("Error updating bail status");
        }
      });
    }
  };

  // based on status of transaction
  // allow different types of denial
  const generateDeny = () => {
    const dropdowns = [];

    const status = props.denyInfo.confirmationStatus;

    /*
    Can always deny a transaction if financeOverride
    Can deny a ready transaction if finance
    Can deny if not AutoRecon if no privilege
    */
    if (
      Auth.canFinanceOverride() ||
      (Auth.canAdmin() && status === "READY") ||
      (Auth.canTransactionDeny() && status === "READY")
    ) {
      dropdowns.push(
        <Link
          key={1}
          to="/summary"
          onClick={(e) => {
            e.preventDefault();
            handleOpenModal("deny");
            setShowDropdown(false);
          }}
        >
          Reject Transaction
        </Link>
      );
    }

    /*

    Can deny without refund if "Completed"

    */
    if (
      Auth.canFinanceOverride() &&
      (status === "APPROVED" || status === "DENIED" || status === "PAID")
    ) {
      dropdowns.push(
        <Link
          key={2}
          to="/summary"
          onClick={(e) => {
            e.preventDefault();
            handleOpenModal("denyWithoutRefund");
            setShowDropdown(false);
          }}
        >
          Reject without Refund
        </Link>
      );
    }

    // can reissue/refund if Paid and Finance privilege
    if (Auth.canAdmin() || Auth.canFinanceOverride()) {
      if (status === "PAID") {
        dropdowns.push(
          <Link
            key={3}
            to="/summary"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal("reissueAgencyPayment");
              setShowDropdown(false);
            }}
          >
            Reissue Agency Payment
          </Link>
        );
        dropdowns.push(
          <Link
            key={4}
            to="/summary"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal("refundCardholder");
              setShowDropdown(false);
            }}
          >
            Refund Cardholder
          </Link>
        );
      }
    }

    if (props.denyInfo.segmentType === "CB") {
      if (props.denyInfo.isRemoteBail) {
        dropdowns.push(
          <Link
            key={5}
            to="/summary"
            onClick={(e) => {
              e.preventDefault();
              handleBail(false);
              setShowDropdown(false);
            }}
          >
            Change to Non-Remote Bail
          </Link>
        );
      } else if (props.denyInfo.allowsRemoteBail) {
        dropdowns.push(
          <Link
            key={6}
            to="/summary"
            onClick={(e) => {
              e.preventDefault();
              handleBail(true);
              setShowDropdown(false);
            }}
          >
            Change Remote Bail
          </Link>
        );
      }
    }
    return dropdowns;
  };
  return (
    <ul className="proview-button deny margins cancel left">
      <li className="dropdown">
        <button
          className={"proview-button reject "}
          onClick={(e) => {
            e.preventDefault();
            handleRejectTransactionConfirmModal();
          }}
          // tabIndex="0"
        >
          Reject <Caret id="Caret" />{" "}
        </button>

        <div
          className={
            showDropdown ? "dropdown-content show" : "dropdown-content none"
          }
        >
          {generateDeny()}
        </div>
      </li>
      <Reason
        changeType={changeType}
        showModal={showModal}
        referenceId={props.referenceId}
        setShowModal={(e) => setShowModal(e)}
        setShowDropdown={(e) => setShowDropdown(e)}
      />
      <RejectTransactionConfirm
        showRejectTransactionConfirmModal={showRejectTransactionConfirmModal}
        setShowRejectTransactionConfirmModal={(e) =>
          setShowRejectTransactionConfirmModal(e)
        }
        setShowDropdown={(e) => setShowDropdown(e)}
      />
    </ul>
  );
};

export default withRouter(Deny);
