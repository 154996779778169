// framework imports - 1st party
import React from "react";

// lib imports - 3rd party
import Select, { components } from "react-select";

// app imports
import ReactDates from "../HelperComponents/ReactDates";
import PlcSelect from "../HelperComponents/PlcSelect";
import * as Auth from "../../authService";
import { ReactComponent as Caret } from "../../icons/caret-down.svg";
import { ReactComponent as Delete } from "../../icons/delete.svg";

import "../../stylesheets/SummarySearch.css";
import "../../stylesheets/Header.css";

const Search = (props) => {
  const options = [
    { value: "INCOMPLETE", label: "Incomplete" },
    { value: "ECHECKPENDING", label: "Echeck Pending" },
    { value: "CASHPENDING", label: "Cash Pending" },
    { value: "READY", label: "Ready" },
    { value: "APPROVED", label: "Approved" },
    { value: "DENIED", label: "Rejected" },
    { value: "PAID", label: "Paid" },
  ];

  const optionsSync = [
    { value: "QBSYNCSUCCESS", label: "QB Sync Success" },
    { value: "QBSYNCFAIL", label: "QB Sync Fail" },
  ];

  return (
    <tr className="search-row">
      <td id="select-row" />
      <td>
        <input
          required="required"
          className="proview-input filter"
          maxLength="13"
          id="ref"
          type="text"
          placeholder="Ref #"
          value={props.referenceId}
          onChange={(e) => props.onReferenceId(e.target.value.trim())}
        />
      </td>
      <td id="plc" className="plc-cell">
        {!props.recurringPaymentId ? (
          <PlcSelect
            class="plc-select"
            setPlc={(e) => props.setPlc(e)}
            name="search-for-plc"
            isMulti={true}
            numResults={500}
            stayOpen={false}
            plc={props.plc}
            id="summary-plc-select"
          />
        ) : null}
      </td>
      <td id="searchDate" width={175}>
        <ReactDates
          dates={props.dates}
          name="search-dates"
          setDates={(e) => props.setDates(e)}
          startId="start-filter-page"
          endId="end-filter-page"
        />
      </td>
      <td id="name">
        <input
          required="required"
          className="proview-input filter"
          id="Name"
          type="text"
          placeholder="Search Last Name"
          value={props.name}
          onChange={(e) => props.onName(e.target.value)}
        />
      </td>
      <td id="payout-date" width={170}>
        <ReactDates
          dates={props.payoutDates}
          name="search-dates"
          setDates={(e) => props.setPayoutDates(e)}
          startId="start-filter-page"
          endId="end-filter-page"
        />
      </td>
      <td>
        <Select
          id="status"
          options={options}
          className="basic-filter-select"
          classNamePrefix="select"
          name="select-status"
          isClearable={true}
          isSearchable={false}
          onChange={(e) => props.onStatus(e)}
          value={props.status}
          components={{ DropdownIndicator }}
          clearRenderer={() => <Delete />}
        />
      </td>
      {Auth.hasQuickbooksPlc() === "true" ||
      Auth.hasQuickbooksPlc() === "null" ? (
        <td>
          <Select
            id="syncStatus"
            options={optionsSync}
            className="basic-filter-select"
            classNamePrefix="select"
            name="select-syncStatus"
            isClearable={true}
            isSearchable={false}
            onChange={(e) => props.onSyncStatus(e)}
            value={props.syncStatus}
            components={{ DropdownIndicator }}
            clearRenderer={() => <Delete />}
          />
        </td>
      ) : null}
      <td id="payment">
        <input
          required="required"
          className="proview-input filter"
          id="Payment"
          name="filter-payment"
          maxLength="8"
          type="text"
          placeholder="$0.00"
          value={props.payment}
          onChange={(e) =>
            e.target.value === "" || !isNaN(e.target.value)
              ? props.onPayment(e.target.value)
              : null
          }
        />
      </td>
    </tr>
  );
};

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Caret />
      </components.DropdownIndicator>
    )
  );
};
export default Search;
