// framework imports - 1st party
import React, { useState } from "react";

// 3rd party

// app imports
import HistorySort from "./HistorySort";
import Row from "./HistoryRow";
import moment from "moment";

const HistoryReport = (props) => {
  const [sort, setSort] = useState({ sortId: "created_at", asc: false });

  const getRows = () => {
    return props.summaries.map((summary, i) => (
      <Row key={i} index={i} summary={summary} dateRange={props.dateRange} />
    ));
  };

  function downloadCSV(csv, filename) {
    var csvFile;
    var downloadLink;

    // CSV file
    csvFile = new Blob([csv], { type: "text/csv" });

    // IE11 Solution
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(csvFile, filename);
    } else {
      // Download link
      downloadLink = document.createElement("a");

      // File name
      downloadLink.download = filename;

      // Create a link to the file
      downloadLink.href = window.URL.createObjectURL(csvFile);

      // Hide download link
      downloadLink.style.display = "none";

      // Add the link to DOM
      document.body.appendChild(downloadLink);

      // Click download link
      downloadLink.click();
    }
  }

  function exportTableToCSV(filename) {
    var csv = [];
    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length; j++) row.push(`"${cols[j].innerText}"`);

      csv.push(row.join(","));
    }

    // Download CSV file
    downloadCSV(csv.join("\n"), filename);
  }
  return props.submitted ? (
    <div className="content transaction-history">
      <div className="export-csv hide-mobile">
        <button
          download="somedata.csv"
          className="download-link history"
          onClick={(e) =>
            exportTableToCSV(
              "transaction-history" + moment().format("MMDDYYYYHHmm")
            )
          }
        >
          Download CSV
        </button>
      </div>
      <table id="transaction-history-table">
        <tbody>
          <HistorySort
            dateRange={props.dateRange}
            setSort={setSort}
            sort={sort}
          />
          {getRows()}
          <tr className="row history totals">
            <td className="text-center">
              <strong>Total</strong>
            </td>
            {props.dateRange === "daily" ? <td /> : <></>}
            {props.dateRange === "monthly" ? (
              <>
                <td /> <td />
              </>
            ) : (
              <></>
            )}
            <td className="text-right">
              <strong>{props.totals.TRANSACTIONS}</strong>
            </td>
            {props.dateRange === "monthly" || props.dateRange === "weekly" ? (
              <>
                <td />
              </>
            ) : (
              <></>
            )}
            <td className="text-right">
              <strong>{props.totals.PAYMENT_AMOUNT}</strong>
            </td>
            <td className="text-right">
              <strong>{props.totals.ADDITIONAL_FEE_1}</strong>
            </td>
            <td className="text-right">
              <strong>{props.totals.ADDITIONAL_FEE_2}</strong>
            </td>
            <td className="text-right">
              <strong>{props.totals.ADDITIONAL_FEE_3}</strong>
            </td>
            <td className="text-center" />
          </tr>
        </tbody>
      </table>
    </div>
  ) : null;
};

export default HistoryReport;
