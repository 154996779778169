import React from "react";
import "../../stylesheets/Row.css";

const HistoryRow = (props) => {
  const lowercase = (s) => {
    if (typeof s !== "string") return "";
    return s
      .split(" ")
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase() + " ");
  };

  const getAverage = () => {
    const endDate = new Date(props.summary.endDate);
    const startDate = new Date(props.summary.startDate);

    return Number(
      props.summary.paymentSummary.TRANSACTIONS /
        Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24))
    ).toFixed(2);
  };

  // change rows based on daily, weekly, or monthly
  const customDateRange = (e) => {
    switch (props.dateRange) {
      case "daily":
        return (
          <>
            <td className="text-left">{lowercase(props.summary.dayName)}</td>
            <td className="text-right">{props.summary.endDate}</td>
            <td className="text-right">
              {props.summary.paymentSummary.TRANSACTIONS}
            </td>
          </>
        );
      case "weekly":
        return (
          <>
            <td className="text-left">
              {props.summary.startDate + " - " + props.summary.endDate}
            </td>
            <td className="text-right">
              {props.summary.paymentSummary.TRANSACTIONS}
            </td>
            <td className="text-right">
              {props.summary.paymentSummary.AVERAGE_TRANSACTIONS}
            </td>
          </>
        );
      case "monthly":
        return (
          <>
            <td className="text-left">{lowercase(props.summary.monthRange)}</td>
            <td className="text-right">{props.summary.yearRange}</td>
            <td className="text-right">
              {props.summary.startDate + " - " + props.summary.endDate}
            </td>
            <td className="text-right">
              {props.summary.paymentSummary.TRANSACTIONS}
            </td>
            <td className="text-right">{getAverage()}</td>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <tr className="row history" key={props.index}>
      {customDateRange()}

      <td className="text-right">
        {props.summary.paymentSummary.PAYMENT_AMOUNT}
      </td>
      <td className="text-right">
        {props.summary.paymentSummary.ADDITIONAL_FEE_1}
      </td>
      <td className="text-right">
        {props.summary.paymentSummary.ADDITIONAL_FEE_2}
      </td>
      <td className="text-right">
        {props.summary.paymentSummary.ADDITIONAL_FEE_3}
      </td>
      <td className="text-center">
        {props.summary.paymentSummary.AVERAGE_PAYMENT}
      </td>
    </tr>
  );
};

export default HistoryRow;
