// framework imports - 1st party
import React, { useState } from "react";

// lib imports - 3rd party
import Modal from "react-modal";

const ChangeApprovedDateAfterConfirm = (props) => {
  const [
    showChangeApprovedDateAfterConfirmModal,
    setShowChangeApprovedDateAfterConfirmModal,
  ] = useState(false);

  if (
    showChangeApprovedDateAfterConfirmModal !==
    props.showChangeApprovedDateAfterConfirmModal
  ) {
    setShowChangeApprovedDateAfterConfirmModal(
      props.showChangeApprovedDateAfterConfirmModal
    );
  }

  const handleChangeApprovedDateAfterConfirmModal = () => {
    props.setShowChangeApprovedDateAfterConfirmModal(false);
  };

  const handleCloseChangeApprovedDateAfterConfirmModal = () => {
    props.setApprovalDate("");
    props.setShowChangeApprovedDateAfterConfirmModal(false);
  };

  return (
    <Modal
      isOpen={showChangeApprovedDateAfterConfirmModal}
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => {
        props.setShowChangeApprovedDateAfterConfirmModal(false);
      }}
      className="Modal cancel-confirm-approve"
      overlayClassName="Overlay"
      ariaHideApp={false}
    >
      <h3>
        Warning this will set the Approval date to after the transaction paid
        date. Do you wish to continue?
      </h3>
      <div>
        <button
          className="proview-button cancel left"
          onClick={handleCloseChangeApprovedDateAfterConfirmModal}
        >
          No
        </button>
        <button
          className="proview-button right"
          onClick={handleChangeApprovedDateAfterConfirmModal}
        >
          Yes
        </button>
      </div>
    </Modal>
  );
};

export default ChangeApprovedDateAfterConfirm;
