// framework imports - 1st party
import React, { useState } from "react";

// lib imports - 3rd party
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

// app imports
import "../../stylesheets/ReactDates.css";
import { ReactComponent as Clear } from "../../icons/delete.svg";

const ReactDates = (props) => {
  const [focusedInput, setFocusedInput] = useState(null);
  return (
    <span className="date-range-picker">
      <DateRangePicker
        startDate={props.dates.start} // momentPropTypes.momentObj or null,
        startDateId={props.startId} // PropTypes.string.isRequired,
        endDate={props.dates.end} // momentPropTypes.momentObj or null,
        endDateId={props.endId} // PropTypes.string.isRequired,
        onDatesChange={({ startDate, endDate }) => {
          props.setDates({ start: startDate, end: endDate });
        }} // PropTypes.func.isRequired,
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
        displayFormat="MM/DD/YY"
        minimumNights={0}
        isOutsideRange={() => false}
        showClearDates={true}
        customCloseIcon={<Clear />}
        startDatePlaceholderText={"Start Date"}
        endDatePlaceholderText={"End Date"}
        verticalSpacing={0}
        transitionDuration={0}
        hideKeyboardShortcutsPanel={true}
      />
    </span>
  );
};

export default ReactDates;
