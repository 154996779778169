// framework imports - 1st party
import React, { useState } from "react";
import withRouter from "../HelperComponents/WithRouter";

// lib imports - 3rd party
import moment from "moment";

// app imports
import { ReactComponent as Approve } from "../../icons/approve-off.svg";
import { ReactComponent as Send } from "../../icons/send-off.svg";
import { ReactComponent as Note } from "../../icons/note-off.svg";
import Resend from "../HelperComponents/Resend";
import * as Auth from "../../authService";
import * as api from "../../api";
import * as notify from "../../notify";
import "../../stylesheets/Row.css";
import "../../stylesheets/GenerateReport.css";

// a given row on the transactions summary page
const Row = (props) => {
  // if row is checked
  const [individualSelected, setIndividualSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);

  //Fields to make the call to update Notify table
  let type = "";
  let destination = "";
  let attention = "";

  // Get Notify Info for Notification request
  const getNotifyInfo = () => {
    api.getResendDropdown(props.transaction.transactionId).then((x) => {
      if (x.status === 200 || x.status === 201) {
        //Setting the values from Notify Info to update Notify table
        if (x.data.length === 3) {
          type = x.data[2].value;
          destination = x.data[2].notifyDest;
          attention = x.data[2].notifyAttn;
        }

        if (type !== null && type === "wir") {
          handleWireNotify();
        } else {
          props.retrieveInfo();
        }
      }
    });
  };

  const handleWireNotify = () => {
    api
      .sendNotification(
        props.transaction.transactionId,
        type,
        destination,
        attention
      )
      .then((x) => {
        if (x.status === 200 || x.status === 201) {
          props.retrieveInfo();
        } else {
          notify.error(
            "Notification Failed for reference #" +
              props.transaction.transactionId
          );
          props.retrieveInfo();
        }
      });
  };

  const lowercase = (s) => {
    if (typeof s !== "string") return "";
    return s
      .split(" ")
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase() + " ");
  };

  // determine if payout date exists, and if it's estimated or not based on status
  const renderPayout = (status) => {
    switch (status) {
      case "APPROVED":
        return moment(props.transaction.estimatedPaidDate).format("MM/DD/YYYY");
      case "PAID":
        return moment(props.transaction.paidTime).format("MM/DD/YYYY");
      default:
        return "-";
    }
  };

  // determines if transaction status is denied and changes wording to rejected as per business request
  const renderStatus = (status) => {
    if (props.transaction.confirmationStatus === "DENIED") {
      return "REJECTED";
    }
    if (props.transaction.confirmationStatus === "ECHECKPENDING") {
      return "ECHECK PENDING";
    }
    if (props.transaction.confirmationStatus === "CASHPENDING") {
      return "CASH PENDING";
    } else {
      return props.transaction.confirmationStatus;
    }
  };

  const renderSyncStatus = (syncStatus) => {
    if (syncStatus === "QBSYNCSUCCESS") {
      return "Y";
    }
    if (syncStatus === "QBSYNCFAIL") {
      return "N";
    } else {
      return "-";
    }
  };

  // on click, go to transaction details
  const loadTransaction = () => {
    if (!props.loading) {
      if (props.params.id) {
        props.navigate(
          "/recurring/" +
            props.params.id +
            "/transaction/" +
            props.transaction.transactionId,
          {
            state: {
              transactionId: props.transaction.transactionId,
              prevScreen: "/recurring/" + props.params.id + "/transaction",
              writeNote: false,
            },
          }
        );
      } else {
        props.navigate("/transaction/" + props.transaction.transactionId, {
          state: {
            transactionId: props.transaction.transactionId,
            recurringPaymentId: props.transaction.recurringPaymentId,
            prevScreen: "/summary",
            writeNote: false,
          },
        });
      }
    }
  };

  // when clicking the "note" icon, go to the bottom of the page
  const handleNote = () => {
    if (!props.loading) {
      if (props.params.id) {
        props.navigate(
          "/recurring/" +
            props.params.id +
            "/transaction/" +
            props.transaction.transactionId,
          {
            state: {
              transactionId: props.transaction.transactionId,
              prevScreen: "/recurring/" + props.params.id + "/transaction",
              writeNote: true,
            },
          }
        );
      } else {
        props.navigate("/transaction/" + props.transaction.transactionId, {
          state: {
            transactionId: props.transaction.transactionId,
            recurringPaymentId: props.transaction.recurringPaymentId,
            prevScreen: "/summary",
            writeNote: true,
          },
        });
      }
    }
  };

  const approveRow = () => {
    if (props.transaction.confirmationStatus === "READY") {
      api.approveTransactions([props.transaction.transactionId]).then((x) => {
        if (x.status === 200 || x.status === 201) {
          notify.success(
            "Approved transaction " + props.transaction.transactionId
          );
          getNotifyInfo();
          // props.retrieveInfo();
        } else {
          notify.error(
            "Failed to approve transaction " + props.transaction.transactionId
          );
        }
      });
    }
  };
  // Whether or not row is checked is determined by the state
  const isChecked = (e) => {
    if (props.rowsChecked) {
      return props.rowsChecked.includes(e);
    }
    return false;
  };

  // handle adding checkmark
  // since a checkmark must change the state
  // of the table component
  const addChecked = (e) => {
    if (!isChecked(e)) {
      const rowsChecked = props.rowsChecked;
      rowsChecked.push(e);
      props.handleRowsChecked(rowsChecked);
      return true;
    } else {
      return false;
    }
  };

  // handle removing checkmark
  // since a checkmark must change the state
  // of the table component
  const removeChecked = (e) => {
    if (isChecked(e)) {
      const rowsChecked = props.rowsChecked;
      rowsChecked.splice(rowsChecked.indexOf(e), 1);
      props.handleRowsChecked(rowsChecked);
      return true;
    } else {
      return false;
    }
  };

  const handleChecked = (e) => {
    setIndividualSelected(!individualSelected);
    e.target.checked
      ? addChecked(props.referenceId)
      : removeChecked(props.referenceId);
    props.handleTopChecked();
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleRowClick = () => {
    if (window.innerWidth < 600) {
      loadTransaction();
    }
  };

  //checks if user have privileges to resend notes
  const showIfUserHasPrivilege = () => {
    return (
      Auth.canFinanceOverride() ||
      Auth.canAdmin() ||
      Auth.canAgencyEdit() ||
      Auth.canAgencyAdmin()
    );
  };

  const { paymentType, paymentAuthInfoList, displayDefendantName } =
    props.transaction;
  let displayName = "";
  if (paymentAuthInfoList.length > 0 && displayDefendantName === "") {
    const { payNearMeInfo, creditCardInfo, echeckInfo, greenDotInfo } =
      paymentAuthInfoList[0];
    if (paymentType === "CREDIT_CARD" && creditCardInfo != null) {
      displayName = creditCardInfo.lastName + ", " + creditCardInfo.firstName;
    } else if (paymentType === "PAYNEARME" && payNearMeInfo != null) {
      displayName = payNearMeInfo.lastName + ", " + payNearMeInfo.firstName;
    } else if (paymentType === "GREENDOT" && greenDotInfo != null) {
      displayName = greenDotInfo.lastName + ", " + greenDotInfo.firstName;
    } else if (paymentType === "ECHECK" && echeckInfo != null) {
      displayName = echeckInfo.lastName + ", " + echeckInfo.firstName;
    }
  } else {
    displayName = displayDefendantName;
  }

  return (
    <tr className="row" onClick={handleRowClick} name={props.referenceId}>
      <td className="hide-mobile">
        <input
          aria-label="summary-checkbox"
          ref={(e) =>
            e
              ? (e.indeterminate =
                  props.transaction.confirmationStatus === "DENIED" ||
                  props.transaction.confirmationStatus === "PENDING" ||
                  props.transaction.confirmationStatus === "INCOMPLETE")
              : null
          }
          type="checkbox"
          checked={
            props.transaction.confirmationStatus === "APPROVED" ||
            props.transaction.confirmationStatus === "PAID" ||
            isChecked(props.referenceId)
          }
          disabled={
            props.transaction.confirmationStatus !== "READY" ? true : false
          }
          onClick={handleChecked}
          readOnly
        />
      </td>
      <td className="transaction-id-row mobile-left" onClick={loadTransaction}>
        <button className="transaction-id-button">
          {props.transaction.cartId
            ? props.transaction.transactionId + "**"
            : props.transaction.recurringPaymentId &&
              !props.isRedirectFromRecurring
            ? props.transaction.transactionId + "*"
            : props.transaction.transactionId}
        </button>
      </td>
      <td className="text-right hide-mobile" onClick={loadTransaction}>
        {props.transaction.plcId}
      </td>
      <td className="text-right" onClick={loadTransaction}>
        {props.transaction.startTime
          ? moment(props.transaction.startTime).format("M/DD/YYYY hh:mm A")
          : null}
      </td>
      <td className="name-row mobile-left" onClick={loadTransaction}>
        {displayName.replace("null", "")}
      </td>
      <td
        className={
          "text-right hide-mobile " + props.transaction.confirmationStatus
        }
        onClick={loadTransaction}
      >
        {renderPayout(props.transaction.confirmationStatus)}
      </td>
      <td className="hide-mobile" onClick={loadTransaction}>
        {lowercase(renderStatus(props.transaction.confirmationStatus))}
      </td>
      {Auth.hasQuickbooksPlc() === "true" ||
      Auth.hasQuickbooksPlc() === "null" ? (
        <td className="text-center hide-mobile" onClick={loadTransaction}>
          {renderSyncStatus(props.transaction.syncStatus)}
        </td>
      ) : null}
      <td className="payment-row text-right" onClick={loadTransaction}>
        {props.transaction.displayTotalAmount
          ? props.transaction.displayTotalAmount
          : "$0.00"}
      </td>
      <td className="hide-mobile">
        {Auth.canAdmin() ||
        Auth.canFinanceOverride() ||
        Auth.canAmountModify() ||
        Auth.canModify() ? (
          <div className="row-button" onClick={approveRow}>
            <Approve className="row-icon" />
            <div className="row-description">Approve</div>
          </div>
        ) : null}
        {showIfUserHasPrivilege() ? (
          <div className="row-button" onClick={handleOpenModal}>
            <Send className="row-icon" />
            <div className="row-description">Send</div>
          </div>
        ) : null}
        <div className="row-button" onClick={handleNote}>
          <Note className="row-icon" />
          <div className="row-description">Write Note</div>
        </div>
      </td>
      <Resend
        showModal={showModal}
        transactionId={props.transaction.transactionId}
        setShowModal={(e) => setShowModal(e)}
      />
    </tr>
  );
};

export default withRouter(Row);
