// framework imports - 1st party
import React, { useEffect, useState } from "react";

// lib imports - 3rd party
import { ToastContainer, Slide } from "react-toastify";

// app imports
import ManifestTable from "./Manifest/ManifestTable";
import Spinner from "./HelperComponents/Spinner";
import * as Auth from "../authService";

import mannifestLogo from "../icons/ic-transaction-mannifest@3x.png";
import "../stylesheets/PageBody.css";
import withRouter from "./HelperComponents/WithRouter";

const TransactionManifest = (props) => {
  const [loading, setLoading] = useState(false);

  // if don't have transaction manifest privilege, redirect
  if (!Auth.canTransactionManifest()) {
    props.navigate("/summary");
  }

  // if small screensize (i.e. on mobile)
  // redirect to summary
  // because this page is not written
  // to be mobile responsive
  const handleResize = (e) => {
    if (window.innerWidth < 600) {
      props.navigate("/summary");
    }
  };
  // listener for resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main className="main-background">
      <div className="main-component">
        <div id="table-header">
          <div id="details-label">
            <img
              alt="Govpay Logo"
              src={mannifestLogo}
              id="trans-manifest-icon"
            />
            <div id="details-label-name">Transaction Manifest</div>
          </div>
          <Spinner customClass={"sk-fading-circle summary"} loading={loading} />
        </div>
        <ManifestTable setSpinner={(e) => setLoading(e)} loading={loading} />
      </div>
      <ToastContainer transition={Slide} />
    </main>
  );
};

export default withRouter(TransactionManifest);
