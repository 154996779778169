// framework imports - 1st party
import React from "react";
// lib imports - 3rd party
import Select, { components } from "react-select";
// app imports
import ReactDates from "../HelperComponents/ReactDates";
import PlcSelect from "../HelperComponents/PlcSelect";
import { ReactComponent as Caret } from "../../icons/caret-down.svg";
import { ReactComponent as Delete } from "../../icons/delete.svg";

import "../../stylesheets/SummarySearch.css";
import "../../stylesheets/Header.css";

const Search = (props) => {
  const options = [
    { value: "CANCELLED", label: "Cancelled" },
    { value: "ACTIVE", label: "Active" },
  ];

  return (
    <tr className="search-row">
      <td>
        <input
          required="required"
          className="proview-input filter"
          maxLength="12"
          id="ref"
          type="text"
          placeholder="RP ID"
          value={props.recurringPaymentId}
          onChange={(e) =>
            e.target.value === "" || !isNaN(e.target.value)
              ? props.onRecurringPaymentId(e.target.value.trim())
              : null
          }
        />
      </td>
      <td id="plc" className="plc-cell">
        <PlcSelect
          class="plc-select"
          setPlc={(e) => props.setPlc(e)}
          name="search-for-plc"
          isMulti={true}
          numResults={500}
          stayOpen={false}
          plc={props.plc}
          id="summary-plc-select"
        />
      </td>
      <td>
        <input
          required="required"
          className="proview-input filter"
          maxLength="12"
          id="ref"
          type="text"
          placeholder="Ref #"
          value={props.referenceId}
          onChange={(e) => props.onReferenceId(e.target.value.trim())}
        />
      </td>
      <td>
        <ReactDates
          dates={props.setupDates}
          name="search-dates"
          setDates={(e) => props.setSetupDates(e)}
          startId="start-filter-page"
          endId="end-filter-page"
        />
      </td>
      <td>
        <input
          required="required"
          className="proview-input filter"
          maxLength="12"
          id="ref"
          type="text"
          placeholder="Interval Date"
          value={props.intervalDate}
          onChange={(e) =>
            e.target.value === "" || !isNaN(e.target.value)
              ? props.onIntervalDate(e.target.value.trim())
              : null
          }
        />
      </td>
      <td id="payment">
        <input
          required="required"
          className="proview-input filter"
          id="Payment"
          name="filter-payment"
          maxLength="8"
          type="text"
          placeholder="$0.00"
          value={props.payment}
          onChange={(e) =>
            e.target.value === "" || !isNaN(e.target.value)
              ? props.onPayment(e.target.value)
              : null
          }
        />
      </td>
      <td>
        <Select
          id="status"
          options={options}
          className="basic-filter-select"
          classNamePrefix="select"
          name="select-status"
          isClearable={true}
          isSearchable={false}
          onChange={(e) => props.onStatus(e)}
          value={props.status}
          components={{ DropdownIndicator }}
          clearRenderer={() => <Delete />}
        />
      </td>
    </tr>
  );
};

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Caret />
      </components.DropdownIndicator>
    )
  );
};

export default Search;
