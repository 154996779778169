import { toast } from "react-toastify";

// manages all the snackbars on the site

// success message
// added custom option to allow for long messages,
// like submitted a note with an email
const success = (message, long = false) =>
  toast.success(message, {
    position: "top-center",
    autoClose: long ? 6000 : 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    className: "success-notification",
  });

// cancel message
const cancel = (message) =>
  toast.info(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    className: "cancel-notification",
  });

// error message
const error = (message) =>
  toast.error(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    className: "error-notification",
  });

// checking if the screen needs to load
// a success/error message from a previous Transaction Details screen
const check = (notification) => {
  if (notification !== null) {
    var type = notification.type;
    var message = notification.message;
    // deny = props.location.state.detailsSuccess
    switch (type) {
      case "success":
        success(message);
        break;
      case "error":
        error(message);
        break;
      case "cancel":
        cancel(message);
        break;
      default:
        break;
    }
  }
};
export { success, cancel, error, check };
