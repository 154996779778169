// framework imports - 1st party
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import withRouter from "./HelperComponents/WithRouter";
import Pdf from "../Proview 2.0 User Guide.pdf";

// lib imports - 3rd party
import Modal from "react-modal";
import ReactGA from "react-ga";

// app imports
import PasswordChange from "./HelperComponents/ChangePassword";
import * as Auth from "../authService";
import { ReactComponent as Logo } from "../icons/logo.svg";
import { ReactComponent as Caret } from "../icons/caret-down.svg";
import Note from "./Details/Note";
import "../stylesheets/Header.css";
import "../stylesheets/Modal.css";

// header for loaded pages
// keyboard accessible
const Header = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalPasswordChange, setShowModalPasswordChange] = useState(false);
  const [path, setPath] = useState();

  // open Contact modal
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleOpenModalPasswordChange = () => {
    setShowModalPasswordChange(true);
  };

  // close Contact modal
  const handleCloseModal = (e) => {
    setShowModal(false);
  };

  // logout
  const handleLogout = (e) => {
    Auth.logout();
  };
  // allow click event on dropdown
  // to make it accessible
  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // handles closing out of dropdown if you click outside of it
  const handleCloseDropdown = (e) => {
    setShowDropdown(false);
  };

  const isActive = (value) => {
    return window.location.pathname.includes(value)
      ? "active hide-mobile"
      : "hide-mobile";
  };

  function changePasswordPage() {
    return window.location.pathname === "/changepasswordpage";
  }

  // Google Analytics
  if (path !== props.location.pathname) {
    ReactGA.pageview(props.location.pathname);
    setPath(props.location.pathname);
  }

  // on click, close out the dropdown
  useEffect(() => {
    document.addEventListener("mousedown", handleCloseDropdown);
    return () => {
      document.removeEventListener("mousedown", handleCloseDropdown);
    };
  }, []);

  // if someone tries to go to main page,
  // redirect to summary
  // if not logged in, will be redirected
  // to login page
  if (window.location.pathname === "/") {
    props.navigate("/summary");
  }

  // Only load if logged in
  if (Auth.loggedIn()) {
    return (
      <header id="header-background-color">
        <div className="header">
          <div className="small-header top">
            <ul>
              <li>
                <Link
                  id="toPlcMigrationDates"
                  to="/migrationdates"
                  className={isActive("/migrationdates")}
                >
                  PLC MIGRATION DATES
                </Link>
              </li>
              <li>
                <a className="hide-mobile" href="mailto:pon@allpaid.com">
                  ORDERS
                </a>
              </li>
              <li>
                <Link className="hide-mobile" to={Pdf} target="_blank">
                  DOWNLOAD USER GUIDE
                </Link>
              </li>
              {Auth.canFinanceOverride() ? (
                <li>
                  <a
                    className="hide-mobile"
                    href="https://secure.interfax.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FAX QUEUE (INTERFAX.NET)
                  </a>
                </li>
              ) : null}
              <li>
                <Link
                  className="hide-mobile"
                  to="/summary"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenModal();
                  }}
                >
                  CONTACT
                </Link>
              </li>

              <ul className="menu menu-logout">
                <li className="dropdown">
                  <div>{Auth.getProfile().sub}</div>
                  <div className="dropdown-content dropdown-content-logout">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      className="hide-mobile"
                      href={void 0}
                      onClick={handleOpenModalPasswordChange}
                    >
                      Change Password
                    </a>

                    <Link
                      className="hide-mobile"
                      id="logoutButton"
                      to="/summary"
                      onClick={handleLogout}
                    >
                      Logout
                    </Link>
                  </div>
                </li>
              </ul>
            </ul>
          </div>
          <div
            className={
              Auth.canFinanceOverride()
                ? "main-header finance-header"
                : "main-header"
            }
          >
            <div
              className={changePasswordPage() ? "menu hidden" : "main-header"}
            >
              <ul className="menu">
                <li
                  id="logo"
                  className="hoverable"
                  onClick={() => props.navigate("/summary")}
                >
                  <Logo id="top-logo" />
                </li>
              </ul>
              <ul className="menu">
                <li>
                  <Link
                    id="toTransactionSummary"
                    to="/summary"
                    className={isActive("/summary")}
                  >
                    TRANSACTIONS
                  </Link>
                </li>
              </ul>
              <ul
                className={
                  Auth.canTransactionManifest() ? "menu" : "menu hidden"
                }
              >
                <li>
                  <Link className={isActive("/manifest")} to="/manifest">
                    TRANSACTIONS MANIFEST
                  </Link>
                </li>
              </ul>
              <ul className="menu">
                <li className="dropdown">
                  <Link
                    to="/summary"
                    className={"dropbtn " + isActive("/reports")}
                    onClick={(e) => {
                      e.preventDefault();
                      handleDropdown();
                    }}
                  >
                    REPORTS & STATEMENTS <Caret id="Caret" />{" "}
                  </Link>

                  <div
                    className={
                      showDropdown
                        ? "dropdown-content show"
                        : "dropdown-content"
                    }
                  >
                    <Link
                      to="/reports/history"
                      className="hide-mobile"
                      onClick={(e) => setShowDropdown(false)}
                    >
                      Transaction History
                    </Link>
                    <Link
                      to="/reports/authorization"
                      className={
                        Auth.canTransactionManifest()
                          ? "hide-mobile"
                          : "hide-mobile hidden"
                      }
                      onClick={(e) => setShowDropdown(false)}
                    >
                      Payment Authorization
                    </Link>
                    <Link
                      to="/reports/reconpayment"
                      className="hide-mobile"
                      onClick={(e) => setShowDropdown(false)}
                    >
                      Recon/Payment Reports
                    </Link>
                  </div>
                </li>
              </ul>
              <ul
                className={Auth.canFinanceOverride() ? "menu" : "menu hidden"}
              >
                <li>
                  <Link
                    id="toPaymentExclusions"
                    to="/payment"
                    className={isActive("/payment")}
                  >
                    PAYMENT EXCLUSIONS
                  </Link>
                </li>
              </ul>
              <ul className="menu">
                <li>
                  <Link
                    id="toRecurringPayment"
                    to="/recurring"
                    className={isActive("/recurring")}
                  >
                    RECURRING PAYMENT
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Modal
          isOpen={showModal}
          shouldCloseOnOverlayClick={true} // that's it!
          onRequestClose={() => {
            setShowModal(false);
          }}
          className="Modal contact"
          overlayClassName="Overlay"
          ariaHideApp={false}
        >
          <Note handleCloseModal={(e) => handleCloseModal(e)} />
        </Modal>
        <PasswordChange
          showModal={showModalPasswordChange}
          setShowModal={(e) => setShowModalPasswordChange(e)}
        />
      </header>
    );
  } else {
    return null;
  }
};

export default withRouter(Header);
