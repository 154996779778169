// framework imports - 1st party
import React, { useEffect, useState } from "react";

// app imports
import backButton from "../icons/care-left-title.svg";
import TransactionInformation from "./Details/TransactionInformation";
import BillingInformation from "./Details/BillingInformation";
import PlcInformation from "./Details/PlcInformation";
import PaymentInformation from "./Details/PaymentInformation";
import * as Auth from "../authService";
import * as api from "../api";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Slide, ToastContainer } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import Confirm from "./HelperComponents/Confirm";
import * as notify from "../notify";
import withRouter from "./HelperComponents/WithRouter";

const RecurringPaymentDetails = (props) => {
  if (!props.location.state) {
    props.navigate("/recurring", { replace: true });
    return null;
  }

  window.scrollTo(0, 0);

  const [recurringPaymentInfo, setRecurringPaymentInfo] = useState({});
  const [plcId, setPlcId] = useState("");
  const [plc, setPlc] = useState([]);
  const [existingPlc, setExistingPlc] = useState([]);
  const [showModalCancelConfirm, setShowModalCancelConfirm] = useState(false);
  const [submit, onSubmit] = useState(false);

  const handleOpenModalCancelConfirm = () => {
    setShowModalCancelConfirm(true);
  };

  const handleBackScreen = () => {
    if (props.location.state.fromTransactionDetails) {
      props.navigate(props.location.state.prevScreen, {
        state: {
          transactionId: props.location.state.transactionId,
          recurringPaymentId: props.location.state.recurringPaymentId,
          prevScreen: "/summary",
        },
      });
    } else {
      props.navigate(props.location.state.prevScreen, { state: {} });
    }
  };

  useEffect(() => {
    notify.check(props.location.state);
    props.location.state.type = "";
    props.location.state.message = "";
    if (props.location.state.fromTransactionDetails) {
      props.navigate(".", {
        state: {
          type: "",
          message: "",
          transactionId: props.location.state.transactionId,
          recurringPaymentId: props.location.state.recurringPaymentId,
          prevScreen: props.location.state.prevScreen,
          fromTransactionDetails: props.location.state.fromTransactionDetails,
        },
      });
    } else {
      props.navigate(".", {
        state: {
          type: "",
          message: "",
          transactionId: props.location.state.transactionId,
          recurringPaymentId: props.location.state.recurringPaymentId,
          prevScreen: props.location.state.prevScreen,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (submit) {
      api
        .cancelRecurringPayment(recurringPaymentInfo.recurringPaymentId)
        .then((x) => {
          setShowModalCancelConfirm(false);
          if (x.status === 200 || x.status === 201) {
            if (props.location.state.fromTransactionDetails) {
              props.navigate(".", {
                state: {
                  type: "success",
                  message:
                    "Cancelled RP " + recurringPaymentInfo.recurringPaymentId,
                  transactionId: props.location.state.transactionId,
                  recurringPaymentId: props.location.state.recurringPaymentId,
                  prevScreen: props.location.state.prevScreen,
                  fromTransactionDetails:
                    props.location.state.fromTransactionDetails,
                },
              });
            } else {
              props.navigate(".", {
                state: {
                  type: "success",
                  message:
                    "Cancelled RP " + recurringPaymentInfo.recurringPaymentId,
                  transactionId: props.location.state.transactionId,
                  recurringPaymentId: props.location.state.recurringPaymentId,
                  prevScreen: props.location.state.prevScreen,
                },
              });
            }
            window.location.reload();
          } else {
            notify.error(
              "Failed to cancel RP " + recurringPaymentInfo.recurringPaymentId
            );
          }
        });
    }
    onSubmit(false);
  }, [submit]);

  useEffect(() => {
    api.getRecurringPayment(props.params.id).then((x) => {
      if (x) {
        setRecurringPaymentInfo(x);
      }
    });

    api.getPlcByTransactionId(props.location.state.transactionId).then((x) => {
      if (x) {
        // set PLC Information
        setPlc([
          { label: "PLC", info: x.plcId },
          { label: "Name", info: x.name },
          { label: "Address", info: x.address1 },
          { label: "City", info: x.city },
          { label: "State", info: x.st },
          { label: "Zip Code	", info: x.zip },
          { label: "Phone #", info: x.publicPhoneNumber },
          { label: "Account Type", info: x.accountType },
        ]);
        setExistingPlc({
          id: x.plcId,
          name: x.name,
          accountType: x.accountType,
        });
        setPlcId(x.plcId);
      }
    });
  }, []);

  const renderIntervalDate = (intervalDate) => {
    if (intervalDate) {
      const arrDate = ["11", "12", "13"];
      if (arrDate.includes(intervalDate)) {
        return intervalDate + "th";
      }

      let lastCharacter = intervalDate.slice(-1);
      switch (lastCharacter) {
        case "1":
          return intervalDate + "st";
        case "2":
          return intervalDate + "nd";
        case "3":
          return intervalDate + "rd";
        default:
          return intervalDate + "th";
      }
    }
  };

  return (
    <TransitionGroup appear={true} enter={false} exit={false}>
      <CSSTransition className="example" timeout={500}>
        <main className="main-background recurring-details">
          <div className="main-component">
            <div id="table-header">
              <div id="details-label">
                <button className="back-button" onClick={handleBackScreen}>
                  <img
                    alt="Back Button"
                    src={backButton}
                    id="trans-details-icon"
                    className="hoverable"
                  />
                </button>

                <div id="details-label-name">Recurring Payment Details</div>
              </div>
              <div className="approve-deny-buttons">
                {Auth.canFinanceOverride() &&
                recurringPaymentInfo.status === "ACTIVE" &&
                recurringPaymentInfo.recurringPaymentId ? (
                  <button
                    onClick={handleOpenModalCancelConfirm}
                    className="proview-button margins right"
                  >
                    Cancel
                  </button>
                ) : null}
              </div>
            </div>
            <div className="content details">
              <div>
                <table className="description-table rp-info-table">
                  <caption>
                    <div className="table-title">
                      RECURRING PAYMENT INFORMATION
                    </div>
                  </caption>
                  <tbody>
                    <tr>
                      <td>RP ID #</td>
                      <td>
                        <strong>
                          <Link
                            to={
                              "/recurring/" +
                              props.location.state.recurringPaymentId +
                              "/transaction"
                            }
                          >
                            {recurringPaymentInfo.recurringPaymentId}
                          </Link>
                        </strong>
                      </td>
                      <td>Created</td>
                      <td>
                        <strong>
                          {recurringPaymentInfo.createdAt
                            ? moment(recurringPaymentInfo.createdAt).format(
                                "M/DD/YYYY hh:mm A"
                              )
                            : "-"}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Begin After</td>
                      <td>
                        <strong>
                          {recurringPaymentInfo.beginAfter
                            ? moment(recurringPaymentInfo.beginAfter).format(
                                "M/DD/YYYY hh:mm A"
                              )
                            : "-"}
                        </strong>
                      </td>
                      <td>Status</td>
                      <td>
                        <strong>{recurringPaymentInfo.status}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Scheduled Pay (Each Month)</td>
                      <td>
                        <strong>
                          {renderIntervalDate(
                            recurringPaymentInfo.intervalDetail
                          )}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr />

                <TransactionInformation
                  referenceId={props.location.state.transactionId}
                  plcId={plcId}
                  notEditable={true}
                />
                <BillingInformation
                  referenceId={props.location.state.transactionId}
                  notEditable={true}
                />
              </div>

              <hr />

              <PlcInformation
                referenceId={props.location.state.transactionId}
                plc={plc}
                existingPlc={existingPlc}
                notEditable={true}
              />
              <PaymentInformation
                referenceId={props.location.state.transactionId}
                notEditable={true}
              />
            </div>
          </div>
          <Confirm
            showModal={showModalCancelConfirm}
            setShowModal={(e) => setShowModalCancelConfirm(e)}
            onSubmit={(e) => onSubmit(e)}
          />
        </main>
      </CSSTransition>
      <ToastContainer transition={Slide} />
    </TransitionGroup>
  );
};

export default withRouter(RecurringPaymentDetails);
