import React, { useEffect, useState } from "react";
import mannifestLogo from "../icons/ic-transaction-mannifest@3x.png";
import Spinner from "./HelperComponents/Spinner";
import { Slide, ToastContainer } from "react-toastify";
import RecurringTable from "./Recurring/RecurringTable";

const RecurringPayment = (props) => {
  const [loading, setLoading] = useState(false);

  // if small screensize (i.e. on mobile)
  // redirect to summary
  // because this page is not written
  // to be mobile responsive
  const handleResize = (e) => {
    if (window.innerWidth < 600) {
      props.navigate("/summary");
    }
  };
  // listener for resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main className="main-background">
      <div className="main-component">
        <div id="table-header">
          <div id="details-label">
            <img
              alt="Govpay Logo"
              src={mannifestLogo}
              id="trans-manifest-icon"
            />
            <div id="details-label-name">Recurring Payment</div>
          </div>
          <Spinner customClass={"sk-fading-circle summary"} loading={loading} />
        </div>
        <RecurringTable setSpinner={(e) => setLoading(e)} loading={loading} />
      </div>
      <ToastContainer transition={Slide} />
    </main>
  );
};

export default RecurringPayment;
