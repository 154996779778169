// framework imports - 1st party
import React, { useState } from "react";

// lib imports - 3rd party

// app imports
import { ReactComponent as SortIcon } from "../../icons/sort.svg";
import "../../stylesheets/Sort.css";

const ManifestSort = (props) => {
  const [topChecked, setTopChecked] = useState(false);

  if (topChecked !== props.topChecked) {
    setTopChecked(props.topChecked);
  }

  return (
    <tr id="sort">
      <td
        className="text-right sorting"
        onClick={() =>
          props.setSort({
            sortId: "transactionId",
            asc:
              props.sort.sortId === "transactionId" ? !props.sort.asc : false,
          })
        }
      >
        <label htmlFor="ref">
          Ref # <SortIcon />
        </label>
      </td>
      <td
        className="text-right sorting"
        onClick={() =>
          props.setSort({
            sortId: "plcId",
            asc: props.sort.sortId === "plcId" ? !props.sort.asc : false,
          })
        }
      >
        <label htmlFor="react-select-2-input">
          PLC <SortIcon />
        </label>
      </td>
      <td className="text-left">PLC Type</td>
      <td>Total Amount</td>
      <td
        className="text-right sorting"
        onClick={() =>
          props.setSort({
            sortId: "paid_datetime",
            asc:
              props.sort.sortId === "paid_datetime" ? !props.sort.asc : false,
          })
        }
      >
        Transaction Date <SortIcon />
      </td>
      <td>
        <label htmlFor="react-select-3-input">Status</label>
      </td>
      <td>
        <label htmlFor="Payment">Payment Details</label>
      </td>
    </tr>
  );
};

export default ManifestSort;
