// 1st party
import React from "react";

// app imports
import ExclusionsSort from "./ExclusionsSort";
import ExclusionsRow from "./ExclusionsRow";

const ExclusionsTable = (props) => {
  const getRows = () => {
    // if on initial page load,
    // prompt user to select plc
    if (props.rows === undefined) {
      return (
        <tr>
          <td>
            <div className="no-results-found-exclusions">Select a PLC</div>
          </td>
        </tr>
      );
      // if loading
    } else if (props.loading) {
      return (
        <tr>
          <td>
            <div className="no-results-found-exclusions">Loading...</div>
          </td>
        </tr>
      );
      // if after selected a PLC there were no results found
    } else if (props.rows.length === 0) {
      return (
        <tr>
          <td>
            <div className="no-results-found-exclusions">No results found</div>
          </td>
        </tr>
      );
    } else {
      return props.rows.map((row, i) => (
        <ExclusionsRow
          retrieveInfo={(e) => props.retrieveInfo()}
          key={i}
          row={row}
        />
      ));
    }
  };

  return (
    <div className="exclusions-table">
      <strong>Manage exclusions for</strong>
      <div className="table-scroll">
        <table>
          <thead>
            <ExclusionsSort />
          </thead>
          <tbody>{getRows()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ExclusionsTable;
