// framework imports - 1st party
import React, { useState } from "react";

// lib imports - 3rd party
import Modal from "react-modal";

// app imports
import * as api from "../../api";
import * as notify from "../../notify";

const ExclusionsAdd = (props) => {
  const [fieldId0, setFieldId0] = useState("");
  const [fieldId1, setFieldId1] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [reason, setReason] = useState("");

  // reason modal
  const handleOpenModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  // close reason modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // handle submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const parameters = {
      agencyApiFraudPlcId: props.plc.agencyApiFraudPlcId,
      fieldId0: props.plc.fieldId0,
      fieldId0Value: fieldId0,
      fieldId1: props.plc.fieldId0,
      fieldId1Value: fieldId1,
      lastName: lastName,
      firstName: firstName,
      reason: reason,
    };
    api.addEntry(parameters).then((x) => {
      if (x.status === 200) {
        notify.success("Entry submitted");
        setFieldId0("");
        setFieldId1("");
        setFirstName("");
        setLastName("");
        setReason("");
        handleCloseModal();
        props.retrieveInfo();
      } else {
        notify.error("Failed to add entry");
      }
    });
  };

  // render inputs based on PLC (may need more than one FieldID Input)
  const renderInputs = () => {
    if (!props.plc) {
      return (
        <div className="exclusion-form float-left">
          <label htmlFor="exclusion-number">Select a PLC</label>
          <input
            id="exclusion-number"
            name="exclusion-number"
            className="proview-input long"
            placeholder="Select a PLC"
            disabled={props.plc !== null}
            value={fieldId0}
            onChange={(e) => setFieldId0(e.target.value)}
          />
        </div>
      );
    } else if (props.plc.fieldId1) {
      return (
        <>
          <div className="exclusion-form float-left">
            <label htmlFor="exclusion-number">{props.plc.fieldId0Name}</label>
            <input
              id="exclusion-number"
              name="exclusion-number"
              className="proview-input long"
              placeholder={props.plc.fieldId0Name}
              value={fieldId0}
              onChange={(e) => setFieldId0(e.target.value)}
            />
          </div>
          <div className="exclusion-form float-left">
            <label htmlFor="exclusion-number">{props.plc.fieldId1Name}</label>
            <input
              id="exclusion-number"
              name="exclusion-number"
              className="proview-input long"
              placeholder={props.plc.fieldId1Name}
              value={fieldId1}
              onChange={(e) => setFieldId1(e.target.value)}
            />
          </div>
        </>
      );
    } else {
      return (
        <div className="exclusion-form float-left">
          <label htmlFor="exclusion-number">{props.plc.fieldId0Name}</label>
          <input
            id="exclusion-number"
            name="exclusion-number"
            className="proview-input long"
            placeholder={props.plc.fieldId0Name}
            value={fieldId0}
            onChange={(e) => setFieldId0(e.target.value)}
          />
        </div>
      );
    }
  };
  return (
    <div className="exclusions-add">
      <div>
        <strong>Add new exclusions for</strong>
      </div>
      <form name="message" onSubmit={(e) => handleOpenModal(e)}>
        {renderInputs()}

        <div className="exclusion-form float-left">
          <label htmlFor="first-name">First Name</label>
          <input
            id="first-name"
            name="first-name"
            className="proview-input long"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="exclusion-form float-left">
          <label htmlFor="last-name">Last Name</label>
          <input
            id="last-name"
            name="last-name"
            className="proview-input long"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <button
          className={"proview-button report margins no-margin-left long"}
          disabled={!(fieldId0 && firstName && lastName)}
          // onClick={handleOpenModal}
          type="submit"
        >
          Add
        </button>
      </form>
      <Modal
        isOpen={showModal}
        shouldCloseOnOverlayClick={true} // that's it!
        onRequestClose={() => {
          setShowModal(false);
        }}
        className="Modal deny"
        overlayClassName="Overlay deny"
        ariaHideApp={false}
      >
        <h3>Reason</h3>
        <textarea
          className="proview-input details"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        <div>
          <button
            className="proview-button cancel left"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="proview-button right"
            onClick={handleSubmit}
            disabled={!reason}
          >
            Add
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ExclusionsAdd;
