import React, { useState } from "react";
import "../../stylesheets/Sort.css";

const RecurringSort = (props) => {
  const [topChecked, setTopChecked] = useState(false);

  if (topChecked !== props.topChecked) {
    setTopChecked(props.topChecked);
  }

  return (
    <tr id="sort">
      <td className="text-right">RP ID</td>
      <td className="text-right">PLC</td>
      <td className="text-right">Ref #</td>
      <td className="text-right"> Setup Date</td>
      <td>Interval Date</td>
      <td className="text-right">Payment</td>
      <td>Status</td>
      <td className="text-right">Last Payment Date</td>
      <td className="text-right">Next Payment Date</td>
      <td></td>
    </tr>
  );
};

export default RecurringSort;
