// framework imports - 1st party
import React from "react";
// lib imports - 3rd party
import Modal from "react-modal";

const RejectTransactionConfirm = (props) => {
  const handleShowDropDown = () => {
    props.setShowDropdown(true);
    props.setShowRejectTransactionConfirmModal(false);
  };

  const handleCancelDropDown = () => {
    props.setShowDropdown(false);
    props.setShowRejectTransactionConfirmModal(false);
  };

  return (
    <Modal
      isOpen={props.showRejectTransactionConfirmModal}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        props.setShowRejectTransactionConfirmModal(false);
      }}
      className="Modal"
      overlayClassName="Overlay"
      ariaHideApp={false}
    >
      <h3>
        Are you sure? To change the amount paid, please click cancel and submit
        the Service Request form in Transaction Details. Rejecting a transaction
        results in a refund to Payer and cannot be undone.
      </h3>
      <div>
        <button
          className="proview-button cancel left"
          onClick={(e) => handleCancelDropDown()}
        >
          Cancel
        </button>
        <button
          className="proview-button right"
          onClick={(e) => handleShowDropDown()}
        >
          Continue
        </button>
      </div>
    </Modal>
  );
};

export default RejectTransactionConfirm;
