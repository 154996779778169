// framework imports - 1st party
import React, { useState } from "react";

// lib imports - 3rd party
import Modal from "react-modal";

const Confirm = (props) => {
  const [showModal, setShowModal] = useState(false);

  if (showModal !== props.showModal) {
    setShowModal(props.showModal);
  }

  const handleCloseModal = () => {
    props.setShowModal(false);
  };

  return (
    <Modal
      isOpen={showModal}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        props.setShowModal(false);
      }}
      className="Modal cancel-confirm"
      overlayClassName="Overlay"
      ariaHideApp={false}
    >
      <h3>Are you sure you want to cancel this?</h3>
      <div>
        <button
          className="proview-button cancel left"
          onClick={handleCloseModal}
        >
          Cancel
        </button>
        <button
          className="proview-button right"
          onClick={() => props.onSubmit(true)}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};

export default Confirm;
